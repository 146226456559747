//import React from "react";
import React, { Component, createRef } from "react";
import { Image, Dropdown, Button, Icon, Menu, Sticky } from "semantic-ui-react";
//import { graphql, compose } from "react-apollo";
import { Link } from "react-router-dom";

//Utils
//import queries from "../utils/queries";
//Components
import Inicio from "./login/Inicio2";
import Vision from "./login/Vision";
import Mision from "./login/Mision";
import Valores from "./login/Valores";
import Equipo from "./login/Equipo";
import Contacto from "./login/Contacto";

import Wallet from "./login/Wallet";
import SmartContract from "./login/SmartContract";
import Paper from "./login/PaperBurns";
import BlockPrivada from "./login/BlockPrivada";
import Certificaciones from "./login/Certificaciones";
import Laboratorio from "./login/Laboratorio";
import Consultoria from "./login/Consultoria";
import Desarrollo from "./login/Desarollo";
import Procesamiento from "./login/Procesamiento";
import CashOut from "./login/CashOut";
import Roadmap from "./login/Roadmap2";

import LogoFirst from "../util/LogoFirst.svg";
import { layoutGenerator } from "react-break";

const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 992
});

const OnMobile = layout.is("mobile");
//const OnAtLeastTablet = layout.isAtLeast("tablet");
//const OnAtMostPhablet = layout.isAtMost("phablet");
const OnDesktop = layout.is("desktop");

//import { teal, orange } from "@material-ui/core/colors";
// import LostPassword from './login/LostPassword';

const styles = {
  grid: {
    height: "100%",
    width: "100%",
    margin: "0 auto"
  },
  gridRoadmap: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    //backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    padding: "1.5em 0em 2em 0em"
  },
  gridMiniSegment: {
    width: "60%",
    backgroundColor: "#f2f2fc"
  },
  gridRoadmapDiv: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    // backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    padding: "2em 2em 2em 2em"
  },
  gridRoadmap2: {
    height: "100%",
    width: "100%",
    margin: "0 auto"
  },
  gridEmbeding: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    padding: "5em"
  },

  gridEmbedingMobile: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    padding: "0.5em"
  },
  logo: {
    height: "17%",
    width: "17%",
    margin: "-1em -27em 0em 4em",
    float: "left ",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  },
  logoResponsive: {
    height: "50%",
    width: "50%",

    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc"
  },
  grid3: {
    height: "100%",
    width: "100%",
    margin: "0 auto"
  },
  box: {
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    textAlign: "center",
    marginBottom: "0em",
    padding: "1em"
  },
  box2: {
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    textAlign: "center",
    marginBottom: "0em",
    padding: "0em"
  },
  link: {
    backgroundColor: "grey"
  }
};

class Home5 extends React.Component {
  state = {
    showInicio: true,
    showVision: true,
    showMision: true,
    showValores: true,
    showEquipo: true,
    showContacto: false,
    showWallet: false,
    showSamrt: false,
    showPaper: false,
    showPrivada: false,
    showCertifica: false,
    showLaboratorio: false,
    showConsultoria: false,
    showDesarrollo: false,
    showProcesamiento: false,
    showCashOut: false,
    showRoadmap: false,
    argsSignup: {},
    errorSignup: [],
    argsSignin: {},
    errorSignin: [],
    active: true
  };

  //////////////////PAGINAS////////////////
  showInicio = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: true,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showMision = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: true,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };
  showVision = ev => {
    ev.preventDefault();
    this.setState({
      showVision: true,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showValores = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: true,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showEquipo = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: true,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showContacto = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: true,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  //////////////////PRODUCTOS///////////////

  showWallet = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: true,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showSamrt = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: true,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showPpaper = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: true,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showPrivada = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: true,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showCertifica = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: true,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };
  showLaboratorio = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: true,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showConsultoria = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: true,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showDesarrollo = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: true,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showProcesamiento = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: true,
      showCashOut: false,
      showRoadmap: false
    });
  };

  showCashOut = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: true,
      showRoadmap: false
    });
  };

  showRoadmap = ev => {
    ev.preventDefault();
    this.setState({
      showVision: false,
      showMision: false,
      showInicio: false,
      showValores: false,
      showEquipo: false,
      showContacto: false,
      showWallet: false,
      showSamrt: false,
      showPaper: false,
      showPrivada: false,
      showCertifica: false,
      showLaboratorio: false,
      showConsultoria: false,
      showDesarrollo: false,
      showProcesamiento: false,
      showCashOut: false,
      showRoadmap: true
    });
  };
  //////////////////FUNCIONES////////////////

  handleLogin = async (ev, args) => {
    console.log(args);
    const response = await this.props.login({
      variables: args
    });
    const { errors, success, token } = response.data.login;
    if (!success) {
      this.setState({ errorSignin: errors });
    } else {
      localStorage.setItem("token", token);
      this.props.history.push("/");
    }
  };
  handleRegister = async (ev, args) => {
    console.log(args);
    const response = await this.props.createUser({
      variables: args
    });

    const { errors, success } = response.data.createUser;
    if (!success) {
      this.setState({ errorSignup: errors });
    } else {
      this.props.history.push("/");
    }
  };
  handleChange = (ev, input) => {
    const argsSignup = this.state.argsSignup;
    argsSignup[input.name] = input.value;
    this.setState({ argsSignup });
  };
  handleChangeSignIn = (ev, input) => {
    const argsSignin = this.state.argsSignin;
    argsSignin[input.name] = input.value;
    this.setState({ argsSignin });
  };

  render() {
    //showLostPassword
    const {
      showVision,
      showMision,
      showInicio,
      showValores,
      showEquipo,
      showContacto,
      showWallet,
      showSamrt,
      showPaper,
      showPrivada,
      showCertifica,
      showLaboratorio,
      showConsultoria,
      showDesarrollo,
      showProcesamiento,
      showCashOut,
      showRoadmap,

      argsSignup,
      errorSignup,
      argsSignin,
      errorSignin
    } = this.state;

    ///import imagen from "../util/Portada7.svg";

    return (
      <div style={styles.grid}>
       

        <div centered style={styles.grid}>
          <>
            {showEquipo && (
              <Equipo styles={styles} handleClick={this.showContacto} />
            )}
            {showInicio && (
              <Inicio styles={styles} handleClick={this.showContacto} />
            )}

            {showVision && (
              <Vision styles={styles} handleClick={this.showContacto} />
            )}

            {showMision && (
              <Mision styles={styles} handleClick={this.showContacto} />
            )}

            {showValores && (
              <Valores styles={styles} handleClick={this.showContacto} />
            )}

            {showContacto && (
              <Contacto styles={styles} handleClick={this.showContacto} />
            )}
            {showRoadmap && <Roadmap styles={styles} />}

            {showWallet && (
              <Wallet styles={styles} handleClick={this.showContacto} />
            )}
            {showSamrt && (
              <SmartContract styles={styles} handleClick={this.showContacto} />
            )}
            {showPaper && (
              <Paper styles={styles} handleClick={this.showContacto} />
            )}
            {showPrivada && (
              <BlockPrivada styles={styles} handleClick={this.showContacto} />
            )}
            {showCertifica && (
              <Certificaciones
                styles={styles}
                handleClick={this.showContacto}
              />
            )}
            {showLaboratorio && (
              <Laboratorio styles={styles} handleClick={this.showContacto} />
            )}
            {showConsultoria && (
              <Consultoria styles={styles} handleClick={this.showContacto} />
            )}
            {showDesarrollo && (
              <Desarrollo styles={styles} handleClick={this.showContacto} />
            )}
            {showProcesamiento && (
              <Procesamiento styles={styles} handleClick={this.showContacto} />
            )}
            {showCashOut && (
              <CashOut styles={styles} handleClick={this.showContacto} />
            )}
          </>
        </div>
      </div>
    );
  }
}

export default Home5;
/*
Wallet 
SmartContract 
Paper 
BlockPrivada 
Certificaciones 
Laboratorio 
Consultoria 
Desarrollo 
Procesamiento 
CashOut 
*/
