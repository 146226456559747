import React, { useContext }from 'react';
import { Button, Form } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from '../../util/hooks';
import { AuthContext } from '../../context/auth';
import { FETCH_PSICO_QUERY } from '../../util/graphql';





function PostTrain(props) {
  
  const { user } = useContext(AuthContext);
  const { values, onChange, onSubmit} = useForm(createPsicoPostCallback, {
   
    descripcion : '' ,
    in_descripcion: '',
    explicacion: '',
    in_explicacion: '',
    rasgos: '',
    in_rasgos: '',
    pasion: '',
    in_pasion: '',
    fijacion: '',
    in_fijacion: '',
    autoimagen: '',
    in_autoimagen: '',
    temor: '',
    in_temor: '',
    deseo: '',
    in_deseo: '',
    distorsionDeseo: '',
    in_distorsionDeseo: '',
    manipular: '',
    in_manipular: '',
    justificacion: '',
    in_justificacion: '',
    agredir: '',
    in_agredir: '',
    defensa: '',
    in_defensa: '',
    subtipoConservacion: '',
    in_subtipoConservacion: '',
    subtipoSocial: '',
    in_subtipoSocial: '',
    subtipoSexual: '',
    in_subtipoSexual: ''
  });

  

  const [createPsicoPost, { error }] = useMutation(CREATE_TRAIN_MUTATION, {
    variables: values, 
    update(proxy, result) {
      const data = proxy.readQuery({
        query: FETCH_PSICO_QUERY
      });
      data.getPsicoPosts = [result.data.createPsicoPost, ...data.getPsicoPosts];
      proxy.writeQuery({ query: FETCH_PSICO_QUERY, data });
      values.descripcion= '';
      values.in_descripcion= '';
      values.explicacion= '';
      values.in_explicacion= '';
      values.rasgos= '';
      values.in_rasgos= '';
      values.pasion= '';
      values.in_pasion= '';
      values.fijacion= '';
      values.in_fijacion= '';
      values.autoimagen= '';
      values.in_autoimagen= '';
      values.temor= '';
      values.in_temor= '';
      values.deseo= '';
      values.in_deseo= '';
      values.distorsionDeseo= '';
      values.in_distorsionDeseo= '';
      values.manipular= '';
      values.in_manipular= '';
      values.justificacion= '';
      values.in_justificacion= '';
      values.agredir= '';
      values.in_agredir= '';
      values.defensa= '';
      values.in_defensa= '';
      values.subtipoConservacion= '';
      values.in_subtipoConservacion= '';
      values.subtipoSocial= '';
      values.in_subtipoSocial= '';
      values.subtipoSexual= '';
      values.in_subtipoSexual= '';
    }
  });
 
  //const codif = this.codificar();

  function createPsicoPostCallback() {
    createPsicoPost();
  }


  return (
    <>
      {user &&(
      <Form name='form' onSubmit={onSubmit}>
        <h2>Completa la informacion...</h2>
        <Form.Field>
          <Form.TextArea name='descripcion' placeholder='descripcion...' 
            onChange={onChange}
            value= {values.descripcion}
            error={error ? true : false}
            />
          <Form.Input
            name='in_descripcion' placeholder='in_descripcion...' 
            onChange={onChange}
            value={values.in_descripcion}
            error={error ? true : false}
          />

         <Form.TextArea name='explicacion' placeholder='explicacion...' 
            onChange={onChange}
            value={values.explicacion}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_explicacion' placeholder='in_explicacion...' 
            onChange={onChange}
            value={values.in_explicacion}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='rasgos' placeholder='rasgos...' 
            onChange={onChange}
            value={values.rasgos}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_rasgos' placeholder='in_rasgos...' 
            onChange={onChange}
            value={values.in_rasgos}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='pasion' placeholder='pasion...' 
            onChange={onChange}
            value={values.pasion}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_pasion' placeholder='in_pasion...' 
            onChange={onChange}
            value={values.in_pasion}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='fijacion' placeholder='fijacion...' 
            onChange={onChange}
            value={values.fijacion}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_fijacion' placeholder='in_fijacion...' 
            onChange={onChange}
            value={values.in_fijacion}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='autoimagen' placeholder='autoimagen...' 
            onChange={onChange}
            value={values.autoimagen}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_autoimagen' placeholder='in_autoimagen...' 
            onChange={onChange}
            value={values.in_autoimagen}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='temor' placeholder='temor...' 
            onChange={onChange}
            value={values.temor}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_temor' placeholder='in_temor...' 
            onChange={onChange}
            value={values.in_temor}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='deseo' placeholder='deseo...' 
            onChange={onChange}
            value={values.deseo}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_deseo' placeholder='in_deseo...' 
            onChange={onChange}
            value={values.in_deseo}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='distorsionDeseo' placeholder='distorsionDeseo...' 
            onChange={onChange}
            value={values.distorsionDeseo}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_distorsionDeseo' placeholder='in_distorsionDeseo...' 
            onChange={onChange}
            value={values.in_distorsionDeseo}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='manipular' placeholder='manipular...' 
            onChange={onChange}
            value={values.manipular}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_manipular' placeholder='in_manipular...' 
            onChange={onChange}
            value={values.in_manipular}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='justificacion' placeholder='justificacion...' 
            onChange={onChange}
            value={values.justificacion}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_justificacion' placeholder='in_justificacion...' 
            onChange={onChange}
            value={values.in_justificacion}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='agredir' placeholder='agredir...' 
            onChange={onChange}
            value={values.agredir}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_agredir' placeholder='in_agredir...' 
            onChange={onChange}
            value={values.in_agredir}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='defensa' placeholder='defensa...' 
            onChange={onChange}
            value={values.defensa}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_defensa' placeholder='in_defensa...' 
            onChange={onChange}
            value={values.in_defensa}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='subtipoConservacion' placeholder='subtipoConservacion...' 
            onChange={onChange}
            value={values.subtipoConservacion}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_subtipoConservacion' placeholder='in_subtipoConservacion...' 
            onChange={onChange}
            value={values.in_subtipoConservacion}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='subtipoSocial' placeholder='subtipoSocial...' 
            onChange={onChange}
            value={values.subtipoSocial}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_subtipoSocial' placeholder='in_subtipoSocial...' 
            onChange={onChange}
            value={values.in_subtipoSocial}
            style={{width: "800px"}}
            error={error ? true : false}
          />

          <Form.TextArea name='subtipoSexual' placeholder='subtipoSexual...' 
            onChange={onChange}
            value={values.subtipoSexual}
            style={{width: "800px"}}
            error={error ? true : false}
            />
          <Form.Input
            name='in_subtipoSexual' placeholder='in_subtipoSexual...' 
            onChange={onChange}
            value={values.in_subtipoSexual}
            style={{width: "800px"}}
            error={error ? true : false}
          />

           
          <Button type="submit" color="linkedin">
            Entrenar ->
          </Button>
        </Form.Field>
        
      </Form>
     )}
      {error && (
        <div className="ui error message" style={{ marginBottom: 20 }}>
          <ul className="list">
            <li>{error.graphQLErrors[0].message}</li>
          </ul>
        </div>
      )}
    </>
  );
}

const CREATE_TRAIN_MUTATION = gql`
  mutation createPsicoPost(
    $descripcion: String!,
    $in_descripcion: String!,
    $explicacion: String!,
    $in_explicacion: String!,
    $rasgos: String!,
    $in_rasgos: String!,
    $pasion: String!,
    $in_pasion: String!,
    $fijacion: String!,
    $in_fijacion: String!,
    $autoimagen: String!,
    $in_autoimagen: String!,
    $temor: String!,
    $in_temor: String!,
    $deseo: String!,
    $in_deseo: String!,
    $distorsionDeseo: String!,
    $in_distorsionDeseo: String!,
    $manipular: String!,
    $in_manipular: String!,
    $justificacion: String!,
    $in_justificacion: String!,
    $agredir: String!,
    $in_agredir: String!,
    $defensa: String!,
    $in_defensa: String!,
    $subtipoConservacion: String!,
    $in_subtipoConservacion: String!,
    $subtipoSocial: String!,
    $in_subtipoSocial: String!,
    $subtipoSexual: String!,
    $in_subtipoSexual: String!) {
    createPsicoPost(
        descripcion: $descripcion,
        in_descripcion: $in_descripcion,
        explicacion: $explicacion,
        in_explicacion: $in_explicacion,
        rasgos: $rasgos,
        in_rasgos: $in_rasgos,
        pasion: $pasion,
        in_pasion: $in_pasion,
        fijacion: $fijacion,
        in_fijacion: $in_fijacion,
        autoimagen: $autoimagen,
        in_autoimagen: $in_autoimagen,
        temor: $temor,
        in_temor: $in_temor,
        deseo: $deseo,
        in_deseo: $in_deseo,
        distorsionDeseo: $distorsionDeseo,
        in_distorsionDeseo: $in_distorsionDeseo,
        manipular: $manipular,
        in_manipular: $in_manipular,
        justificacion: $justificacion,
        in_justificacion: $in_justificacion,
        agredir: $agredir,
        in_agredir: $in_agredir,
        defensa: $defensa,
        in_defensa: $in_defensa,
        subtipoConservacion: $subtipoConservacion,
        in_subtipoConservacion: $in_subtipoConservacion,
        subtipoSocial: $subtipoSocial,
        in_subtipoSocial: $in_subtipoSocial,
        subtipoSexual: $subtipoSexual,
        in_subtipoSexual: $in_subtipoSexual) {
            id
            user
            descripcion
            in_descripcion
            explicacion
            in_explicacion
            rasgos
            in_rasgos
            pasion
            in_pasion
            fijacion
            in_fijacion
            autoimagen
            in_autoimagen
            temor
            in_temor
            deseo
            in_deseo
            distorsionDeseo
            in_distorsionDeseo
            manipular
            in_manipular
            justificacion
            in_justificacion
            agredir
            in_agredir
            defensa
            in_defensa
            subtipoConservacion
            in_subtipoConservacion
            subtipoSocial
            in_subtipoSocial
            subtipoSexual
            in_subtipoSexual
            createdAt
            username
    }
  }
`;





export default PostTrain;
