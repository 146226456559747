import React, { useContext } from 'react';
import { Button, Card, Icon, Label, Image } from 'semantic-ui-react';
//import { Link } from 'react-router-dom';
import moment from 'moment';
import '../../Steps.css';
import { AuthContext} from '../../../context/auth';
import ModalTrainIntencion from './ModalTrainIntencion';
//import DeleteButton from './DeleteButton';
//import MyPopup from '../util/MyPopup';




function PostCardTrain({
    trainpost: { in_Intencion,
                 out_Accion,
                 in_Contrapartida,
                 out_Contrapartida,
                 username,
                createdAt }, trainpost
}) {
  const { user } = useContext(AuthContext);
  function decodificar(d) {
    //var cadena = d;
    var caracteres = d.split('.');
    for (var i = 0; i < caracteres.length; i++){
      caracteres[i] = String.fromCharCode(caracteres[i]);
    }
    return caracteres;
  }

  return (
    <div>
    {user  &&(
    <Card fluid>
      <Card.Content>
        <Card.Header>{username}</Card.Header>
        <Card.Meta >
          {moment.utc(createdAt).fromNow(true)}
        </Card.Meta>
        <Card.Description >Intencion de entrada:<Card.Meta >{in_Intencion}</Card.Meta> </Card.Description>
        <Card.Description >Accion:<Card.Meta >{out_Accion}</Card.Meta> </Card.Description>
        <Card.Description >Accion entrada:<Card.Meta >{in_Contrapartida}</Card.Meta> </Card.Description>
        <Card.Description >Contrapartida:<Card.Meta >{out_Contrapartida}</Card.Meta> </Card.Description>
        {trainpost && <ModalTrainIntencion trainpost={trainpost}/>}
      </Card.Content>
      
    </Card>)}
    </div>
  );
}

export default PostCardTrain;