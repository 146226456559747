import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import Slide from "@material-ui/core/Slide";
import { Alert, AlertTitle } from '@material-ui/lab';
import Paquete1 from "../Paquete1";
import LogoFirst from "../../../../util/LogoFirst.svg";
import { Image, Popup, Icon, Message } from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
    root: {
    width: '100%',
    //maxWidth: '36ch',
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  grid: {
    width: "96%"
    // maxWidth: "300px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#4f8bc6",
    
  },
  paquete1: {
    margin: "0em 0em 0em 1em",
    backgroundColor: "#f2f2fc"
    //color: "#4f8bc6"
    // float: "left ",
  },
   barra: {
   
       backgroundColor: "#f2f2fc",
   // border: "1px solid #f2f2fc"
  },
  boton: {
       color: "#4f8bc6"
       //backgroundColor: "#f2f2fc",
   // border: "1px solid #f2f2fc"
  },
  logo: {
    height: "80%",
    width: "17%",
    margin: "0em 0em 0em 4em",
    float: "right",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
       <Popup
      trigger={
        <Icon
        color="blue"
        link
        name="tag"
        content="Open Portal"
        disabled={open}
        positive
        //onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "inverted" })}
        onClick={handleClickOpen}
        style={{
          padding: "0em 0em 0em 1em"
        }}
      />  
      
      }
      content='Información...'
      inverted
    />
      
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
            
          <Toolbar className={classes.barra}>
        <Popup
         trigger={
           <IconButton
              edge="start"
              color="#4f8bc6"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>     
        }
        content='cerrar info'
        inverted
        />  
      
            <Typography variant="h6" className={classes.title}>
              Generación y custodia de Wallets ERC20
            </Typography>
            <Image
                  src={LogoFirst}
                  className={classes.logo}
                  
                />
          </Toolbar>
        </AppBar>
        <List className={classes.root}>
            <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <ListItem alignItems="center">
             <Message info size="big" className={classes.grid}>
            El servicio de custodia y generación de wallets cripto, es un
            servicio que consiste en la generación de par de claves ERC20 en
            Ethereum por la cantidad minima de unidades de 50.000 wallets o de 100.000 wallets.
            </Message>
            </ListItem>

             <ListItem alignItems="center">
             <Message success size="big"  className={classes.grid}>
            El servicio de custodia y generación de wallets cripto, <strong>va dirigido a empresas </strong>
             que ofrecen servicios de wallets crypto, y quieran automatizar y asegurar el procesamiento de firma digital
            de las transacciones de sus clientes.
            </Message>
            </ListItem>

            <ListItem alignItems="center">
             <Message info size="big"  className={classes.grid}>
             Las claves generadas son
            utilizadas para entrenar un conjunto de nódos de redes neuronales
            para la administración automática y descentralizada de transacciones cripto. 
            </Message>
            </ListItem>
            <ListItem alignItems="center">
             <Message info size="big"  className={classes.grid}>
            Los nodos entrenados, firmarán digitalmente por medio de un protocolo descentralizado, cada trasacción que las wallets
            generadas soliciten al conjunto de redes neuronales. 
            </Message>
            </ListItem>
            <ListItem alignItems="center">
             <Message info size="big"  className={classes.grid}>
            Luego de
            la firma digital de transacciones, <strong>(los nodos de redes neuronales)</strong>, devuelven la transacción
            firmada al core cliente requirente... 
            </Message>
            </ListItem>
            <ListItem alignItems="center">
             <Message info size="big"  className={classes.grid}>
            De este modo, <strong>el servicio de
            custodia es seguro</strong>, dado que el par de claves núnca sale a internet,
            ni persisten en base de datos.
            </Message>
            </ListItem>

            <ListItem alignItems="center">
             <Message warning size="big"  className={classes.grid}>
            Los valores expresados por el Roadmap, estan colculados sobre la base de que el usuario / cliente
            posee la infraestructura y un servicio de cuentas crypto propias, esto es que, FirstCripto no contempla desarrollo
            alguno del lado del usuario / cliente... Si no cuenta con un servicio de cuenta crypto propia, deberá recorrer el
            Roadmap 3 [DESARROLLO DE WALLET CRYPTO].
            </Message>
            </ListItem>
          By — <strong>FirstCripto</strong>
          </Alert>
            
          
        </List>
      </Dialog>
    </span>
  );
}
