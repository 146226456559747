import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { ScatterplotChart } from 'react-easy-chart';
import ToolTip from '../../ToolTip';
//import Legend from '../Legend';
//import { escapeHTML } from '../util';
import Scrollspy from 'react-scrollspy';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY_PARTIDO = gql`
  {
    getPartidoPensamientoF
  }
`;



//const partidoq = partido;


const exampleText = [
  {
    type: 1,
    x: 'Tue',
    y: 10
  },
  {
    type: 1,
    x: 'Wed',
    y: 20
  },
  {
    type: 2,
    x: 'Thu',
    y: 30
  },
  {
    type: 3,
    x: 'Wed',
    y: 40
  }
];

const exampleTime = [
  {
    type: 1,
    x: '1-Jan-15',
    y: 10
  },
  {
    type: 1,
    x: '2-Jan-15',
    y: 20
  },
  {
    type: 2,
    x: '1-Jan-15',
    y: 30
  },
  {
    type: 2,
    x: '2-Jan-15',
    y: 30
  },
  {
    type: 3,
    x: '3-Jan-15',
    y: 40
  }
];

const bigData = [
  {
    type: 'One',
    x: 1,
    y: 5
  },
  {
    type: 'Two',
    x: 3,
    y: 1
  },
  {
    type: 'Three',
    x: 0,
    y: 6
  },
  {
    type: 'Four',
    x: 5,
    y: 2
  },
  {
    type: 'Five',
    x: 4,
    y: 4
  },
  {
    type: 'Six',
    x: 5,
    y: 9
  },
  {
    type: 'Seven',
    x: 9,
    y: 1
  },
  {
    type: 'Eight',
    x: 5,
    y: 6
  },
  {
    type: 'Nine',
    x: 3,
    y: 9
  },
  {
    type: 'Ten',
    x: 7,
    y: 9
  }
];

const bigDataZ = [
  {
    type: 'One',
    x: 1,
    y: 5,
    z: 500
  },
  {
    type: 'One',
    x: 3,
    y: 1,
    z: 100
  },
  {
    type: 'One',
    x: 0,
    y: 6,
    z: 600
  },
  {
    type: 'One',
    x: 5,
    y: 2,
    z: 200
  },
  {
    type: 'One',
    x: 4,
    y: 4,
    z: 400
  },
  {
    type: 'One',
    x: 5,
    y: 9,
    z: 900
  },
  {
    type: 'One',
    x: 9,
    y: 1,
    z: 100
  },
  {
    type: 'One',
    x: 5,
    y: 6,
    z: 600
  },
  {
    type: 'One',
    x: 3,
    y: 9,
    z: 900
  },
  {
    type: 'One',
    x: 7,
    y: 9,
    z: 900
  }
];

const config = [
  {
    type: 'One',
    color: '#1e313c',
    stroke: 'black'
  },
  {
    type: 'Two',
    color: '#3f4c55',
    stroke: 'black'
  },
  {
    type: 'Three',
    color: '#667178',
    stroke: 'black'
  },
  {
    type: 'Four',
    color: '#f4e956',
    stroke: 'black'
  },
  {
    type: 'Five',
    color: '#e3a51a',
    stroke: 'black'
  },
  {
    type: 'Six',
    color: '#aaac84',
    stroke: 'black'
  },
  {
    type: 'Seven',
    color: '#dce7c5',
    stroke: 'black'
  },
  {
    type: 'Eight',
    color: '#e4e8ec',
    stroke: 'black'
  }
];

const configGen = [
  {
    type: 'A',
    color: '#1e313c'
  },
  {
    type: 'B',
    color: '#3f4c55'
  },
  {
    type: 'C',
    color: '#667178'
  },
  {
    type: 'D',
    color: '#f4e956'
  },
  {
    type: 'E',
    color: '#e3a51a'
  },
  {
    type: 'F',
    color: '#aaac84'
  },
  {
    type: 'G',
    color: '#dce7c5'
  },
  {
    type: 'H',
    color: '#e4e8ec'
  }
];
export default class ScatterplotContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseOutHandler = this.mouseOutHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
  
    this.state = {
      dataDisplay: '',
      showToolTip: false,
      randomDataIntervalId: null,
      windowWidth: 400,
      componentWidth: 1000
    };
    
  }


  mouseOverHandler(d, e) {
    this.setState({
      showToolTip: true,
      top: `${e.screenY - 10}px`,
      left: `${e.screenX + 10}px`,
      y: d.y,
      x: d.x });
  }

  mouseMoveHandler(e) {
    if (this.state.showToolTip) {
      this.setState({ top: `${e.y - 10}px`, left: `${e.x + 10}px` });
    }
  }

  mouseOutHandler() {
    this.setState({ showToolTip: false });
  }

   consulta = ()=>{

    const {
        data: { getPartidoPensamientoF:partido }
      } = useQuery(QUERY_PARTIDO);
return partido;
}

 // clickHandler(d) {
 //   this.setState({ dataDisplay: `The amount selected is ${d.y}` });
 // }
 partidox = [
        
  this.props.partido,
  this.props.partido1,
  this.consulta[0]
  
  
];
 
  render() {
    

    const cn = this.state.active ? 'menu active' : 'menu';
    return (
      
      <div>
       <div>
          <section id="dataType">
            <h3 style={{color: "orange"}} >Patrones subtipo</h3>
             {this.Consulta}
            <ScatterplotChart
            
              data={[
                {
                  type: 'One',
                  x: 1,
                  y: 5,
                  z: this.partidox[0]
                },
                {
                  type: 'One',
                  x: 3,
                  y: 1,
                  z: 100
                },
                {
                  type: 'One',
                  x: 0,
                  y: 6,
                  z: 600
                },
                {
                  type: 'One',
                  x: 5,
                  y: 2,
                  z: 200
                },
                {
                  type: 'One',
                  x: 4,
                  y: 4,
                  z: 400
                },
                {
                  type: 'One',
                  x: 5,
                  y: 9,
                  z: 900
                },
                {
                  type: 'One',
                  x: 9,
                  y: 1,
                  z: 100
                },
                {
                  type: 'One',
                  x: 5,
                  y: 6,
                  z: 600
                },
                {
                  type: 'One',
                  x: 3,
                  y: 9,
                  z: 900
                },
                {
                  type: 'One',
                  x: 7,
                  y: 9,
                  z: 900
                }
              ]}
              axes
              axisLabels={{ x: 'My x Axis', y: 'My y Axis' }}
              dotRadius={6}
              width={480}
              height={270}
              grid
              verticalGrid
              mouseOverHandler={this.mouseOverHandler}
              mouseOutHandler={this.mouseOutHandler}
              mouseMoveHandler={this.mouseMoveHandler}
              clickHandler ={(d) => this.setState({
                dataDisplay: `The x value id ${d.x} and the y value is ${d.y}`
              })}
            />

            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'top',
                paddingLeft: '20px' }}
            >
              {(this.state.dataDisplay)
                ? this.state.dataDisplay
                : 'Click on a circle to show the value'
              }
            </div>
           
          </section>

         
          <br />
          <br />

          

        </div>
      </div>
    );
  }

}
function Encabezado(props) {
  const dataDisplay = '';
 //const [dataDisplay, setState] = React.useState('');
  
  return(
    <>
     <h2>Patrones subtipo</h2>

<ScatterplotChart
  data={bigDataZ}
  axes
  axisLabels={{ x: 'My x Axis', y: 'My y Axis' }}
  dotRadius={6}
  width={480}
  height={270}
  grid
  verticalGrid
  mouseOverHandler={props.mouseOverHandler}
  mouseOutHandler={props.propsmouseOutHandler}
  mouseMoveHandler={props.propsmouseMoveHandler}
  clickHandler={(d) => this.setState({
    dataDisplay: `The x value id ${d.x} and the y value is ${d.y}`
  })}
 
/>

<div
  style={{
    display: 'inline-block',
    verticalAlign: 'top',
    paddingLeft: '20px' }}
>
  {(dataDisplay)
    ? dataDisplay
    : 'Click on a circle to show the value'
  }
</div>
    </>
  );
}

ReactDOM.render(
  <ScatterplotContainer />, document.getElementById('edonme'));
  