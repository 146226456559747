import React, {  useState } from 'react';
import gql from 'graphql-tag';
import { useMutation} from '@apollo/react-hooks';
import { Confirm } from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router-dom";
import { CURRENT_POST2 } from '../../../util/graphql';

function BorradorButton({ _id, username,callback}) {
  const history = useHistory();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [borradorPost] = useMutation(PUBLIC_POST_MUTATIONB, {
    update(proxy, result) {
        setConfirmOpen(false);
        const data = proxy.readQuery({
          query: CURRENT_POST2,variables: {
            username,
             
            }
        });
       
       data.userPostPrev = [result.data.borradorPost, ...data.userPostPrev.filter((p) => p._id !== _id)];
       
        proxy.writeQuery({ query: CURRENT_POST2, variables: {
          username: username,
          },data});  
          if (callback) callback();
      },
      
    variables: {
      _id,
    },
  
  });

  function createPostCallback() {
    borradorPost();
    history.push("/home");
  }

  return (
    <>
        <Button 
          onClick={() => createPostCallback()}
        >
          Guardar
        </Button>
      
      <Confirm
        cancelButton= 'Cancelar'
        confirmButton= 'Confirmar'
        content= 'Esta seguro?...Has completado todo?'
        size= 'small'
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={createPostCallback}
      /> 
    </>
  );
}

const PUBLIC_POST_MUTATIONB = gql`
  mutation publicPost($_id: ID!) {
    borradorPost(_id: $_id){
      _id
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes{
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada{
        _id
        filename
        path
        mimetype
      }
      imagenes{
        _id
        filename
        path
        mimetype
      }
      especifica{
        id
        tipo
        titulo
        contenido
      }
      componentes{
        id
        tipo
        titulo
        contenido
      }
    }
  }
`;



export default BorradorButton;