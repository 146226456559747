import React,{ useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
////import CardActions from '@material-ui/core/CardActions';
//import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import MenuMini from './MenuMini';
import ModalPerfil from './ModalPerfil';
//import BotonDial from './EspecialBoton/BotonDial';
import { Link } from 'react-router-dom';
import { AuthContext} from '../context/auth';

const useStyles = makeStyles((theme) => ({
  root: {
   minWidth: 355,
   margin: 'auto',
  },
  media: {
    height: 0,
    paddingTop: '53.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard({
  post: { body,body2, createdAt, _id, username, likeCount, commentCount, likes, portada,contrapartida }
}) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  /////////recortando texto titulo////
var strtitulo = body;
var restitulo = strtitulo.substring(0, 35);
var finaltitulo = restitulo +"...";
  
/////////recortando texto subtitulo////
var str = body2;
var res = str.substring(0, 35);
var final = res +"...";

  return (
    <Card className={classes.root} >
      <CardHeader
        avatar={
          
          <ModalPerfil/>
        }
        action={
           <MenuMini postId={_id} username={username}/>
        }
        title={finaltitulo}
        subheader={final}
        
      />
      <Link
         to={{
               pathname: `/posts/${_id}`, 
               user:user,
               body:body,
               body2:body2,
               contrapartida:contrapartida
            }}>
         {portada ?(
            <CardMedia 
            className={classes.media}
            image={portada.path}
          />
          ):(<p className="registroUsuario" >No hay portada</p> 
         )}
      </Link> 
      
      
     
    </Card>
  );
}