import React,{Component} from 'react';
import './Footer.css';
import footer from '../../util/footer.svg';


class Footer extends Component {
   render(){
     return (
        <div className="Footer">
        
        <img className="logofooter" src={footer} alt="Table" />
        </div>
      
     );
    }


}

export default Footer;