import React, { useContext, useState, useRef, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
//import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { Form, Loader, Image, Grid, Segment } from "semantic-ui-react";

import { AuthContext } from "../../../context/auth";
//import LikeButton from '../components/LikeButton';
import DeleteButton from "../Builder/DeleteButtonComponente";
//import MyPopup from '../util/MyPopup';
import "../../../App.css";

import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CURRENT_POST2 } from "../../../util/graphql";
import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";

/*
const useStyles = makeStyles({
  root: {
    minWidth: 350,
    paddingTop: '1%',
  },
  bullet: {
    display: 'inline-block',
    margin: '1 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
*/
const useStyles = makeStyles(theme => ({
  root: {
    // minWidth: 350,
    // maxWidth: 355,

    width: 340,
    margin: theme.spacing(0.3)
  },
  bullet: {
    display: "inline-block",
    margin: "1 2px",
    transform: "scale(0.8)"
  },
  title: {
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  }
}));

const styles = {
  grid: {
    height: "auto",
    //width: 400,
    //maxWidth: 360,
    margin: "0 auto",
    padding: "3em 0em 0em 0em"
  },
  grid2: {
    // width: 360,
    padding: "3em 0em 10em 17em"
  },
  img: {
    height: 370,
    maxWidth: 500,
    //minWidth: 300,
    // overflow: 'hidden',
    // display: 'block',
    width: "100%"
  }
};

function AddComponente() {
  //const postId = props.match.params.postId;
  //const { user } = useContext(AuthContext);
  localStorage.setItem("userVacio", " ");
  const userSecundario = localStorage.getItem("userLocal");
  const primerUsuario = localStorage.getItem("userVacio");
  let usuario = () => {
    if (!userSecundario) {
      usuario = primerUsuario;
    } else {
      usuario = userSecundario;
    }
    return usuario;
  };

  const user = usuario();

  const queryPostInfo = useQuery(CURRENT_POST2, {
    notifyOnNetworkStatusChange: true,
    variables: {
      username: user
    }
  });
  const {
    data: { userPostPrev: postc }
  } = queryPostInfo;

  useEffect(() => {
    queryPostInfo.startPolling(1500);
    return () => queryPostInfo.stopPolling();
  }, [queryPostInfo]);

  /*
  let post = () => {
    if (!userSecundario) {
      post = "  ";
    } else {
      post = postc._id;
    }
    return post;
  };

  const idpost = post();
*/
  const {
    tituloInputRef,
    contenidoInputRef,
    tipoInputRef,
    tiempoInputRef
  } = useRef(null);

  const [titulo, setTitulo] = useState("");
  const [contenido, setContenido] = useState("");
  const [tipo, setTipo] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [checkeds, setCheckeds] = React.useState(false);
  const [postx, setPostx] = React.useState("");

  let post = () => {
    if (!postc) {
      post = " ";
    } else {
      post = postc._id;
    }
    return post;
  };
  const idpost = post();
  const handleCheck = event => {
    setCheckeds(false);
    setChecked(event.target.checked);
    if (checked === false) {
      setTiempo(event.target.value);
      setTipo("150.000");
      setTitulo("Custodia de wallets");
      setContenido("30.000 a 60.000");
    } else {
      setTiempo("");
      setTipo("");
      setTitulo("");
      setContenido("");
    }
  };

  const handleCheck2 = event => {
    setChecked(false);
    setCheckeds(event.target.checked);
    if (checkeds === false) {
      setTiempo(event.target.value);
      setTipo("250.000");
      setTitulo("Custodia de wallets");
      setContenido("61.000 a 90.000");
    } else {
      setTiempo("");
      setTipo("");
      setTitulo("");
      setContenido("");
    }
  };

  const [submitComponente, { error }] = useMutation(
    SUBMIT_COMPONENTE_MUTATION,
    {
      update() {
        setTitulo("");
        setContenido("");
        setTipo("");
        setTiempo("");
        setCheckeds(false);
        setChecked(false);
        tituloInputRef.current.blur();
        contenidoInputRef.current.blur();
        tipoInputRef.current.blur();
        tiempoInputRef.current.blur();
      },
      variables: {
        postId: idpost,
        titulo: titulo,
        contenido: contenido,
        tipo: tipo,
        tiempo: tiempo,
        username: user
      }
    }
  );

  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;

  let postMarkup;
  if (postc) {
    const {
      _id,
      body,
      body2,
      createdAt,
      username,
      especifica,
      componentes
    } = postc;

    postMarkup = (
      <>
        <Grid stackable columns={2}>
          <Grid.Column>
            <Segment>
              {user && (
                <div>
                  <CardContent>
                    <p>Cantidad de wallets a custodiar</p>
                    <Form>
                      <div>
                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          <Checkbox
                            name="tiempo"
                            checked={checked}
                            value="120 hs"
                            onChange={handleCheck}
                            //inputProps={{ "aria-label": "primary checkbox" }}
                            color="primary"
                            ref={tiempoInputRef}
                          />
                          30.000 a 60.000
                        </Typography>

                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          <Checkbox
                            name="tiempo2"
                            checked={checkeds}
                            value="200 hs"
                            onChange={handleCheck2}
                            //inputProps={{ "aria-label": "primary checkbox" }}
                            color="primary"
                            ref={tipoInputRef}
                          />
                          61.000 a 90.000k
                        </Typography>

                        <Typography
                          className={classes.title}
                          color="textSecondary"
                          gutterBottom
                        >
                          {tiempo} {tipo}
                        </Typography>
                        <button
                          type="submit"
                          className="ui button teal"
                          disabled={
                            titulo.trim() === "" || contenido.trim() === ""
                          }
                          onClick={submitComponente}
                        >
                          Agregar
                        </button>
                      </div>
                    </Form>
                    {error && (
                      <div
                        className="ui error message"
                        style={{ marginBottom: 20 }}
                      >
                        <ul className="list">
                          <li>{error.graphQLErrors[0].message}</li>
                        </ul>
                      </div>
                    )}
                  </CardContent>
                </div>
              )}
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <div>
                <CardContent>
                  <p>
                    Cotizacion para:
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {username}
                    </Typography>{" "}
                  </p>

                  <Typography className={classes.pos} color="textSecondary">
                    Cotización iniciada: {moment.utc(createdAt).fromNow(true)}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small">Información</Button>
                </CardActions>
              </div>
              {componentes.map(info => (
                <Card key={info.id}>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {info.titulo}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color="textSecondary"
                      gutterBottom
                    >
                      {info.contenido}
                    </Typography>
                    <Typography
                      className={classes.title}
                      color="textPrimary"
                      gutterBottom
                    >
                      {info.tipo} ars
                    </Typography>

                    <DeleteButton
                      floated="left"
                      postId={_id}
                      componenteId={info.id}
                      user={user}
                    />
                  </CardContent>
                  <CardContent></CardContent>
                </Card>
              ))}
            </Segment>
          </Grid.Column>
        </Grid>
      </>
    );
  } else {
    postMarkup = (
      <>
        <Grid columns={1} style={styles.grid}>
          <Grid.Row>
            <Alert severity="warning">
              No se ha completado el paso anterior
            </Alert>
          </Grid.Row>
        </Grid>
      </>
    );
  }
  return postMarkup;
}

const SUBMIT_COMPONENTE_MUTATION = gql`
  mutation(
    $postId: ID
    $titulo: String
    $contenido: String
    $tipo: String
    $tiempo: String
    $username: String
  ) {
    createComponente(
      postId: $postId
      titulo: $titulo
      contenido: $contenido
      tipo: $tipo
      tiempo: $tiempo
      username: $username
    ) {
      _id
      componentes {
        id
        titulo
        contenido
        tipo
        tiempo
        username
      }
    }
  }
`;

export default AddComponente;
