import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Slide from "@material-ui/core/Slide";
import { Alert, AlertTitle } from "@material-ui/lab";
import Paquete1 from "../Paquete1";
import LogoFirst from "../../../../util/LogoFirst.svg";
import { Image, Popup, Icon, Message, Grid } from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
    //maxWidth: '36ch',
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  grid: {
    width: "96%"
    // maxWidth: "300px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid2: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid3: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#4f8bc6"
  },
  paquete1: {
    margin: "0em 0em 0em 1em",
    backgroundColor: "#f2f2fc"
    //color: "#4f8bc6"
    // float: "left ",
  },
  barra: {
    backgroundColor: "#f2f2fc"
    // border: "1px solid #f2f2fc"
  },
  boton: {
    color: "#4f8bc6"
    //backgroundColor: "#f2f2fc",
    // border: "1px solid #f2f2fc"
  },
  logo: {
    height: "80%",
    width: "17%",
    margin: "0em 0em 0em 4em",
    float: "right",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Popup
        trigger={
          <Button onClick={handleClickOpen}>Ejemplo</Button>
        }
        content="Información..."
        inverted
      />
      <Popup
              trigger={
                <IconButton
                  edge="start"
                  color="#4f8bc6"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              }
              content="cerrar ejemplo 2"
              inverted
            />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.barra}>
            

            <Typography variant="h6" className={classes.title}>
              Ejemplo decisión 2.
            </Typography>
            
            <Image src={LogoFirst} className={classes.logo} />
          </Toolbar>
        </AppBar>

        <List className={classes.root}>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <ListItem alignItems="center">
              <Message info size="big" className={classes.grid}>
                El desarrollo del proceso de autenticación de wallet cripto,
                esta definido en Firstcripto como un desarrollo aparte y
                complementeario de una wallet. En el presente, la autenticación
                de wallets o cuentas digitales cliente, se ha vuelto cada ves
                mas compleja. Lo que requiere un apartado especial en cuanto a
                diseño y desarrollo, dado que involucra tecnologías variadas y
                que en ocaciones dista del simple hecho de desarrollar un
                loguín.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message success size="big" className={classes.grid}>
                El desarrollo Autenticación wallet cripto,{" "}
                <strong>va dirigido a empresas </strong>
                que además de brindar acceso seguro a sus clientes, ésta pueda
                verificar que el dispositivo desde donde se está ejecutando la
                aplicación cliente, sea el dispositivo del cliente, por medio de
                un segundo factor de identificación. Y, además de lo descripto,
                Firstcripto propone la capacidad de identifacaión fehaciente de
                la persona, y corroborar que sea quién dice ser.
              </Message>
            </ListItem>
            
            
            
            By — <strong>FirstCripto</strong>
          </Alert>
        </List>
      </Dialog>
    </span>
  );
}
