import React, { useContext, useState, useRef, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
//import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { Confirm, Button, Icon, Popup, Item,  Grid, Segment, Statistic, Header, Divider  } from "semantic-ui-react";

//import { AuthContext } from "../../../context/auth";
//import LikeButton from '../components/LikeButton';
//import DeleteButton from "../Builder/DeleteButtonComponente";
//import MyPopup from '../util/MyPopup';
import "../../../App.css";

import { makeStyles } from "@material-ui/core/styles";
//import Card from "@material-ui/core/Card";
//import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CURRENT_POST2 } from "../../../util/graphql";
//import Checkbox from "@material-ui/core/Checkbox";
import Alert from "@material-ui/lab/Alert";

//import Deploy from "./DeployInfraGenWallets";
//import Terminos from "./TerminosDeployCustodia";
//import DeployFirst from "./DeployGenWallFirst";
//import DeployCliente from "./DeployGenWallCliente";
import Collapse from "./ColapseItem";
import ImagenEnviar from "../../../util/Enviar.svg";
//import Publicar from "../../../components/edon.me/Intencion/PublicarButton";



const useStyles = makeStyles(theme => ({
    root: {
        // minWidth: 350,
        // maxWidth: 355,

        width: 340,
        margin: theme.spacing(0.3)
    },
    bullet: {
        display: "inline-block",
        margin: "1 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
}));

const styles = {
    grid: {
        height: "auto",
        //width: 400,
        //maxWidth: 360,
        margin: "0 auto",
        padding: "3em 0em 0em 0em"
    },
    grid2: {
        // width: 360,
        padding: "3em 0em 10em 17em"
    },
    img: {
        height: 370,
        maxWidth: 500,
        //minWidth: 300,
        // overflow: 'hidden',
        // display: 'block',
        width: "100%"
    }
};

const useStyles2 = makeStyles({
    root2: {
        height: 50,
        flexGrow: 1,
        maxWidth: 700,
    },
});


function AddComponente({handleNext, callback}) {
    //const postId = props.match.params.postId;
    //const { user } = useContext(AuthContext);
    localStorage.setItem("userVacio", " ");
    const userSecundario = localStorage.getItem("userLocal");
    const primerUsuario = localStorage.getItem("userVacio");
    let usuario = () => {
        if (!userSecundario) {
            usuario = primerUsuario;
        } else {
            usuario = userSecundario;
        }
        return usuario;
    };
    const classes2 = useStyles2();
    const user = usuario();

    const queryPostInfo = useQuery(CURRENT_POST2, {
        notifyOnNetworkStatusChange: true,
        variables: {
            username: user
        }
    });
    const {
        data: { userPostPrev: postc }
    } = queryPostInfo;

    useEffect(() => {
        queryPostInfo.startPolling(1000);
        return () => queryPostInfo.stopPolling();
    }, [queryPostInfo]);

    const {
        tituloInputRef,
        contenidoInputRef,
        tipoInputRef,
        tiempoInputRef
    } = useRef(null);

    const [titulo, setTitulo] = useState("");
    const [contenido, setContenido] = useState("");
    const [tipo, setTipo] = useState("");
    const [tiempo, setTiempo] = useState("");
    const [checked, setChecked] = React.useState(false);
    const [checkeds, setCheckeds] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);

    let post = () => {
        if (!postc) {
            post = " ";
        } else {
            post = postc._id;
        }
        return post;
    };
    const idpost = post();
    const _id = idpost;
    const username = user;
    
    const [publicPost] = useMutation(PUBLIC_POST_MUTATION, {
        update(proxy, result) {
            setConfirmOpen(false);
            
            const data = proxy.readQuery({
                query: CURRENT_POST2,
                variables: {
                    username
                }
            });
            handleNext();
            data.userPostPrev = [
                result.data.publicPost,
                ...data.userPostPrev.filter(p => p._id !== _id)
            ];

            proxy.writeQuery({
                query: CURRENT_POST2,
                variables: {
                    username: username
                },
                data
            });
            if (callback) callback();
            
        },
        
        variables: {
            _id
            
        },
        handleNext(){
            
        }
    });

    function createPostCallback() {
        publicPost();
        
        //history.push("/home");
    }
   

    const classes = useStyles();
    
    
    let postMarkup;
    if (postc) {
        const {
            _id,
            body,
            body2,
            createdAt,
            username,
            especifica,
            componentes
        } = postc;
       
        const subtotal = especifica[0];
        const total = subtotal.contenido;
        const totalHs = subtotal.tipo;
    
        postMarkup = (
            <>
                <Grid stackable columns={2}>
                    <Grid.Column>
                        <Segment>
                            {user && (
                                <div>
                                    <Item.Group divided>
                                    
                                        <Item>
                                            <Item.Image src={ImagenEnviar} />
                                            <Item.Content>
                                                <Item.Header as='a'> Revision y envio de Roadmap </Item.Header>
                                                <Item.Meta>
                                                    
                                                </Item.Meta>
                                                <Item.Description>
                                                    
                                                    <CardContent>
                                                        <p>Revise el Roadmap, y si todo esta bien, Presione el boton "Enviar""
                                                         . El Roadmap será recibido por el área comercial de FirstCripto. Revise su casilla de
                                                         email donde recibirá la confirmación de recepción de su Roadmap.</p>
                                                    
                                                    </CardContent>
                                                
                                                </Item.Description>
                                                <Item.Extra>
                                                    <Popup content='El Roadmap será enviado y se notificará a un representante comercial de FirstCripto mediante email y WhatsApp' 
                                                       trigger={
                                                     <Button
                                                        onClick={createPostCallback}
                                                        primary
                                                        floated="right"
                                                        className="ui button teal"
                                                    >
                                                        Enviar
                                                     <Icon name="right chevron" />
                                                     </Button>
    
                                                      } inverted
                                                     />
                                                    
                                                    <Confirm
                                                        cancelButton="Cancelar"
                                                        confirmButton="Confirmar"
                                                        content="Esta seguro?...Has completado todo?"
                                                        size="small"
                                                        open={confirmOpen}
                                                        onCancel={() => setConfirmOpen(false)}
                                                        onConfirm={createPostCallback}
                                                    />
                                                </Item.Extra>
                                            </Item.Content>
                                        </Item>

                                        
                                    </Item.Group>
                                    
                                </div>
                            )}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <div>
                                <CardContent>
                                    <p>
                                        Cotizacion para:
                                       <Typography
                                            className={classes.title}
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            <span style={{
                                                padding: "0em 1em 0em 0em",
                                                color: "blue"
                                            }}>{username}</span>
                                            
                                            <Statistic color='orange' floated='right' size='small'>
                                                <Statistic.Value>{totalHs}</Statistic.Value>
                                                <Statistic.Label>Horas acumuladas</Statistic.Label>
                                            </Statistic>
                                            <Statistic floated='right' color='blue' size='small'>
                                                <Statistic.Value>{Number(total).toLocaleString('de-DE')}</Statistic.Value>
                                                <Statistic.Label>Costo acumulado (ars)</Statistic.Label>
                                            </Statistic>
                                            
                                        </Typography>{" "}
                                    </p>

                                    <Typography className={classes.pos} color="textSecondary">
                                        Cotización iniciada: {moment.utc(createdAt).fromNow(true)}
                                    </Typography>
                                </CardContent>
                                
                                <Divider horizontal>
                                    <Header as='h5' color='grey'>
                                        <Icon name='bar chart' />
                                        Especificaciones
                                    </Header>
                                </Divider>
                                
                            </div>
                            {componentes.map(info => (
                                <>
                                    <Collapse key={info.id} info={info} postId={_id} user={user}/>
                                
                                </>
                            ))}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </>
        );
    } else {
        postMarkup = (
            <>
                <Grid columns={1} style={styles.grid}>
                    <Grid.Row>
                        <Alert severity="warning">
                            No se ha completado el paso anterior
            </Alert>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
    return postMarkup;
}

const SUBMIT_COMPONENTE_MUTATION = gql`
  mutation(
    $postId: ID
    $titulo: String
    $contenido: String
    $tipo: String
    $tiempo: String
    $username: String
  ) {
    createComponente(
      postId: $postId
      titulo: $titulo
      contenido: $contenido
      tipo: $tipo
      tiempo: $tiempo
      username: $username
    ) {
      _id
      componentes {
        id
        titulo
        contenido
        tipo
        tiempo
        username
      }
    }
  }
`;

const PUBLIC_POST_MUTATION = gql`
  mutation publicPost($_id: ID!) {
    publicPost(_id: $_id) {
      _id
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes {
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada {
        _id
        filename
        path
        mimetype
      }
      imagenes {
        _id
        filename
        path
        mimetype
      }
      especifica {
        id
        tipo
        titulo
        contenido
      }
      componentes {
        id
        tipo
        titulo
        contenido
        tiempo
        username
      }
    }
  }
`;

export default AddComponente;