import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Transition } from "semantic-ui-react";
//import GridListTile from '@material-ui/core/GridListTile';
//import { Link } from 'react-router-dom'
import { AuthContext } from "../../context/auth";
//import CardPostPropia from './CardPostPropia';
import Carrousel from "../tesauri/Carrousel";
import { CURRENT_POST2 } from "../../util/graphql";
import { makeStyles } from "@material-ui/core/styles";
import UploadPortada from "./uploadPortada";
import Imagenes from "./uploadImagenes";
import Componentes from "./Builder/AddComponente";
import Paper from "@material-ui/core/Paper";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import PublicarButton from "./Intencion/PublicarButton";
import BorradorButton from "./Intencion/BorradorButton";
import DescartarButton from "./Intencion/DescartarButton";
import Loading from "./Loading";

const useStyles = makeStyles(theme => ({
  root: {
    // minWidth: 350,
    // maxWidth: 355,

    width: 360,
    margin: theme.spacing(0.1)
  },
  root2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

const styles = {
  grid: {
    height: "auto",
    width: "360px",
    margin: "0 auto"
  },
  grid2: {
    // width: 360,
    padding: "1em 0em 0em 0em"
  },
  grid3: {
    // width: 360,
    padding: "5em 0em 10em 2em"
  },

  paper: {
    width: 170,
    padding: "1em 0em 0em 0em"
    // display: 'flex',
    // flexWrap: 'wrap',
  },
  img: {
    height: 360,
    maxWidth: 360,
    //minWidth: 300,
    // overflow: 'hidden',
    // display: 'block',
    width: "98%"
  }
};

function CurrentPostPrev() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const queryPostInfo = useQuery(CURRENT_POST2, {
    notifyOnNetworkStatusChange: true,
    variables: {
      username: user.username
    }
  });

  const {
    data: { userPostPrev: post }
  } = queryPostInfo;

  useEffect(() => {
    queryPostInfo.startPolling(1500);
    return () => queryPostInfo.stopPolling();
  }, [queryPostInfo]);

  let postMarkup;
  if (!post) {
    postMarkup = <Loading />;
  } else {
    //const {body,body2,createdAt,username,portada, imagenes} = currentPost;

    postMarkup = (
      <div style={styles.grid} fluid="true">
        {user && (
          <Grid
            columns={1}
            style={styles.grid}
            justify="center"
            alignItems="center"
          >
            <Grid.Row>
              <Componentes post={post} />
            </Grid.Row>
            <Grid.Column style={styles.paper}>
              <Grid.Row alignItems="center">
                <Paper>
                  {" "}
                  <UploadPortada postId={post._id} />{" "}
                </Paper>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column style={styles.paper}>
              <Grid.Row alignItems="center">
                <Paper>
                  {" "}
                  <Imagenes postId={post._id} />
                </Paper>
              </Grid.Row>
            </Grid.Column>

            <Grid.Row>
              {
                <Transition.Group style={styles.grid}>
                  <Grid.Row>
                    {user && <p className="registroUsuario">Portada</p>}
                  </Grid.Row>
                  <Grid.Column>
                    {post && (
                      <div>
                        <img
                          style={styles.img}
                          src={post.portada.path}
                          alt={"portada"}
                        />
                      </div>
                    )}
                  </Grid.Column>
                  <Grid.Row>
                    {user && <p className="registroUsuario">Imagenes</p>}
                  </Grid.Row>
                  <Grid.Column>
                    {post && (
                      <Carrousel file={post.imagenes} style={styles.grid} />
                    )}
                  </Grid.Column>
                </Transition.Group>
              }
              {user && post && (
                <div style={styles.grid3}>
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    aria-label="contained primary button group"
                  >
                    <PublicarButton _id={post._id} username={post.username} />
                    <BorradorButton _id={post._id} username={post.username} />
                    <DescartarButton
                      postId={post._id}
                      username={post.username}
                    />
                  </ButtonGroup>
                </div>
              )}
            </Grid.Row>
          </Grid>
        )}
      </div>
    );
  }
  return postMarkup;
}

export default CurrentPostPrev;
