import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AuthContext } from '../../../context/auth';
import InfoAlert from './infoAlert';



const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function AcordionInformacion({componentes}) {
    const { user } = useContext(AuthContext);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Informacion de tu intencion</Typography>
        </AccordionSummary>
        
        {componentes && componentes.map((componente) => (
         <AccordionDetails className={classes.root} key={componente._id}>
                 
                     {user &&  <InfoAlert  titulo={componente.titulo} contenido={componente.contenido} className={classes.root}/>}  
                 
         </AccordionDetails>
                 ))}
        
      </Accordion>
      
        
     
    </div>
  );
}
