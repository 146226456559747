import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/auth";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Confirm } from "semantic-ui-react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
//import { GET_USER_POSTS } from '../util/graphql';
import { CURRENT_POST2 } from "../../../util/graphql";
//import MyPopup from '../util/MyPopup';

function DescartarButton({ postId, commentId, callback, username }) {
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const mutation = commentId ? DELETE_COMMENT_MUTATION : DELETE_POST_MUTATION;

  const [deletePostOrMutation] = useMutation(mutation, {
    update(proxy) {
      setConfirmOpen(false);
      if (!commentId) {
        const data = proxy.readQuery({
          query: CURRENT_POST2,
          variables: {
            username
          }
        });
        // data.userPostPrev = data.userPostPrev.filter((p) => p._id !== postId);
        data.userPostPrev = data.userPostPrev.filter(p => p._id !== postId);
        // data.userPostPrev = data.userPostPrev.filter((p) => p._id !== postId);
        proxy.writeQuery({
          query: CURRENT_POST2,
          variables: {
            username
          },
          data
        });
      }
      if (callback) callback();
    },
    variables: {
      postId,
      username: username,
      commentId
    }
  });

  function createPostCallback() {
    deletePostOrMutation();
  }

  return (
    <>
      <Button onClick={() => createPostCallback()}>Descartar</Button>

      <Confirm
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        content="Esta seguro?"
        size="small"
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deletePostOrMutation}
      />
    </>
  );
}

const DELETE_POST_MUTATION = gql`
  mutation deletePost($postId: ID!, $username: String!) {
    deletePost(postId: $postId, username: $username)
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($postId: ID!, $commentId: ID!) {
    deleteComment(postId: $postId, commentId: $commentId) {
      _id
      comments {
        id
        username
        createdAt
        body
      }
      commentCount
    }
  }
`;

export default DescartarButton;
