import React from 'react';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ParallaxSlide from '@mui-treasury/components/slide/parallax';

import { useArrowDarkButtonStyles } from '@mui-treasury/styles/button/arrowDark';

import MobileStepper from '@material-ui/core/MobileStepper';


const useStyles = makeStyles(({ palette, breakpoints, spacing }) => ({
  root: {
    position: 'relative',
    width: '98%',
  },
  root2: {
    maxWidth: 400,
    flexGrow: 1,
  },
  slide: {
    perspective: 1000, // create perspective
    overflow: 'hidden',
    // relative is a must if you want to create overlapping layers in children
    position: 'relative',
    paddingTop: spacing(0),
    [breakpoints.up('sm')]: {
      paddingTop: spacing(0),
    },
    [breakpoints.up('md')]: {
      paddingTop: spacing(0),
    },
  },
  imageContainer: {
    display: 'block',
    position: 'relative',
    zIndex: 2,
    paddingBottom: '56.25%',
  },
  image: {
    display: 'block',
    position: 'absolute',
    zIndex: 10,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    marginLeft: '12%',
    [breakpoints.up('sm')]: {
      marginLeft: '4%',
    },
  },
  img: {
    height: 450,
    maxWidth: 550,
    //minWidth: 300,
   // overflow: 'hidden',
   // display: 'block',
    width: '100%',
  },
  arrow: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    [breakpoints.up('xs')]: {
      display: 'inline-flex',
    },
  },
  arrowLeft: {
    left: 0,
    [breakpoints.up('lg')]: {
      left: -68,
    },
  },
  arrowRight: {
    right: 0,
    [breakpoints.up('lg')]: {
      right: -68,
    },
  },
  text: {
    // shared style for text-top and text-bottom
    fontFamily: 'Poppins, san-serif',
    fontWeight: 900,
    position: 'absolute',
    color: palette.common.white,
    padding: '0 8px',
    transform: 'rotateY(45deg)',
    lineHeight: 1.2,
    [breakpoints.up('sm')]: {
      padding: '0 16px',
    },
    [breakpoints.up('md')]: {
      padding: '0 24px',
    },
  },
  title: {
    top: 20,
    left: '20%',
    height: '40%',
    fontSize: 40,
    zIndex: 1,
    background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, #9c9c9c 100%)',
    [breakpoints.up('sm')]: {
      top: 40,
      fontSize: 72,
    },
    [breakpoints.up('md')]: {
      top: 52,
      fontSize: 72,
    },
  },
  subtitle: {
    top: 60,
    left: '0%',
    height: '52%',
    fontSize: 56,
    zIndex: 2,
    background: 'linear-gradient(0deg, rgba(255,255,255,0) 0%, #888888 100%)',
    [breakpoints.up('sm')]: {
      top: 112,
      left: '6%',
      fontSize: 96,
    },
    [breakpoints.up('md')]: {
      top: 128,
      fontSize: 104,
    },
  },
  indicatorContainer: {
    textAlign: 'center',
  },
}));

const ParallaxCarousel = ({file}) => {
  const maxSteps = file.length;
  const classes = useStyles();
  
  const createStyle = (slideIndex, fineIndex) => {
    const diff = slideIndex - fineIndex;
    if (Math.abs(diff) > 1) return {};
    return {
      transform: `rotateY(${(-diff + 1) * 45}deg)`,
    };
  };
  // eslint-disable-next-line react/prop-types
  const renderElements = ({ index, onChangeIndex }) => (
    <>
    <MobileStepper
        steps={maxSteps}
        position="static"
        variant="text"
        activeStep={index}
        nextButton={
      <Button
        className={cx(classes.arrow, classes.arrowLeft)}
        
        disabled={index === 0}
        onClick={() => onChangeIndex(index - 1)}
      >
        <KeyboardArrowLeft fontSize="large"/>
        
      </Button>
       }
       backButton={
      <Button
        className={cx(classes.arrow, classes.arrowRight)}
        
        disabled={index === file.length - 1}
        onClick={() => onChangeIndex(index + 1)}
      >
        <KeyboardArrowRight fontSize="large"/>
      </Button>
      }
      />
     
    </>
  );
  const renderChildren = () =>
    file.map(({  id, filename, mimetype, path }, i) => (
      <div key={id} className={classes.slide}>
        
        
          <img className={classes.img} src={path} alt={'slide'} />
        
       
      </div>
    ));
  return (
    <div className={classes.root}>
      <ParallaxSlide renderElements={renderElements}>
        {renderChildren}
      </ParallaxSlide>
      
    </div>
  );
};


export default ParallaxCarousel;