import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
//import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { AuthContext } from '../context/auth';
import {PieChart, Legend} from 'react-easy-chart';
import { Link } from 'react-router-dom';
import PiechartCompleto from './PiechartCompleto';
const styles={
    grid:{
      height: "auto",
      width: "360px",
      margin: "0 auto",
      padding: "12em 0em 0em 5em"
    },
  
    grid2:{
        padding: "1em 1em 0em 0em"
    },
    
  }

 

function PartidoDemocrata(props) {
  const { user } = useContext(AuthContext);
  
  
  

  const {
    data: { getPartidoPensamientoF:partido }
  } = useQuery(QUERY_PARTIDO);

 
  let postMarkup;
  if (!partido) {
    postMarkup = <p>Loading post..</p>;
  } else {
   // const {_id,body,body2,createdAt,username,commentCount} = getPost;

   const bigDataZ = [
    {
      type: 'One',
      x: 1,
      y: 5,
      z: 500
    },
    {
      type: 'One',
      x: 3,
      y: 1,
      z: 100
    },
    {
      type: 'One',
      x: 0,
      y: 6,
      z: 600
    },
    {
      type: 'One',
      x: 5,
      y: 2,
      z: 200
    },
    {
      type: 'One',
      x: 4,
      y: 4,
      z: 400
    },
    {
      type: 'One',
      x: 5,
      y: 9,
      z: 900
    },
    {
      type: 'One',
      x: 9,
      y: 1,
      z: 100
    },
    {
      type: 'One',
      x: 5,
      y: 6,
      z: 600
    },
    {
      type: 'One',
      x: 3,
      y: 9,
      z: 900
    },
    {
      type: 'One',
      x: 7,
      y: 9,
      z: 900
    }
  ];
  

    const ene = [ 
                 parseInt([partido[0]/partido[9]*100]).toFixed(0) +"%", 
                 parseInt([partido[1]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[2]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[3]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[4]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[5]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[6]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[7]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[8]/partido[9]*100]).toFixed(0) +"%"
                ];
    
     const legendCantidad = [ 
       
                    "Neural 1: "+partido[0], 
                    "Neural 2: "+partido[1],
                    "Neural 3: "+partido[2],
                    "Neural 4: "+partido[3],
                    "Neural 5: "+partido[4],
                    "Neural 6: "+partido[5],
                    "Neural 7: "+partido[6],
                    "Neural 8: "+partido[7],
                    "Neural 9: "+partido[8]
                   ];
    const link1 = <Link to={{pathname: `/home`}}>{partido[0]}</Link> 
    //const html = `<Link to={{pathname: `/home`}}>{partido[0]}</Link> `;

    postMarkup = (
        <>
        <section >
      <div style={styles.grid} fluid = "true">
         {user && partido && ene&& ( 
             
       /*   <PieChart size={300}
            labels
               data={[
                     { key: ene[0], value: partido[0] },
                     { key: ene[1], value: partido[1] },
                     { key: ene[2], value: partido[2] },
                     { key: ene[3], value: partido[3] },
                     { key: ene[4], value: partido[4] },
                     { key: ene[5], value: partido[5] },
                     { key: ene[6], value: partido[6] },
                     { key: ene[7], value: partido[7] },
                     { key: ene[8], value: partido[8] }
                 ]}
              styles={{
                '.chart_text': {
                fontSize: '0.5em',
                fill: '#fff'
                }
              }}
         />)}
*/
<div>Nada</div>
)}

         <Legend data={[
                     { key: legendCantidad[0], value: partido[0]},
                     { key: legendCantidad[1], value: partido[1] },
                     { key: legendCantidad[2], value: partido[2] },
                     { key: legendCantidad[3], value: partido[3] },
                     { key: legendCantidad[4], value: partido[4] },
                     { key: legendCantidad[5], value: partido[5] },
                     { key: legendCantidad[6], value: partido[6] },
                     { key: legendCantidad[7], value: partido[7] },
                     { key: legendCantidad[8], value: partido[8] }
                 ]} dataId={'key'} horizontal />
      <h5>Total padron PDC: </h5><span style={{color: "red"}}>{partido[9]}</span><p style={{color: "gray"}}>Afiliados</p>
     </div>
     </section>
     <PiechartCompleto 
             partido={partido[0]} 
             partido1={partido[1]} 
             partido2={partido[2]}
             partido3={partido[3]}
             partido4={partido[4]}
             partido5={partido[5]}
             partido6={partido[6]}
             partido7={partido[7]}
             partido8={partido[8]}
             eneatipo={ene[0]}
             eneatipo1={ene[1]}
             eneatipo2={ene[2]}
             eneatipo3={ene[3]}
             eneatipo4={ene[4]}
             eneatipo5={ene[5]}
             eneatipo6={ene[6]}
             eneatipo7={ene[7]}
             eneatipo8={ene[8]}
             legend={legendCantidad[0]}
             legend1={legendCantidad[1]}
             legend2={legendCantidad[2]}
             legend3={legendCantidad[3]}
             legend4={legendCantidad[4]}
             legend5={legendCantidad[5]}
             legend6={legendCantidad[6]}
             legend7={legendCantidad[7]}
             legend8={legendCantidad[8]}
             
             />
     
     
     </>
    );
  }
  return postMarkup;
}



const QUERY_PARTIDO = gql`
  {
    getPartidoPensamientoF
  }
`;



export default PartidoDemocrata;
