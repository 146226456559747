import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

import { AuthContext } from '../context/auth';
//import Profile from './Profile';
import Neura from './Train/NeuraUsuario';
import Avatar from '@material-ui/core/Avatar'


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const styles={
    grid:{
      height: "auto",
      width: "360px",
      margin: "0 auto",
   //   padding: "1em 1em 0em 0em"
    },
  
    grid2:{
        padding: "1em 1em 0em 0em"
    },
    
  }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalPerfil() {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
///<Avatar  src={"images/Uz2UOB3og-mi amor.jpg"} />
  return (
    <div >
      <Button color="primary" onClick={handleClickOpen} >
      <Avatar  size="mini" src="images/TGn3h96aT-IMG_20151202_122625.jpg" className={classes.small} />
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Perfil
            </Typography>
           
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary="Demo" secondary="Perfil de usuario {Recordad poner avatar...!}" />
          </ListItem>
          
          <Divider />
        </List>
        <div>
         <Grid columns={1} style={styles.grid}>
           <Grid container alignItems="center">
              {user && (<Neura />)}
           </Grid> 
          </Grid>
        </div>
      </Dialog>
    </div>
  );
}