import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
//import Grid from '@material-ui/core/Grid';

import {  Card  } from 'semantic-ui-react';
import moment from 'moment';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import { AuthContext } from '../../../context/auth';
//import Uploads from './Uoloads';
//import WithPreviews from './uploadWithPreview'

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#fafafa',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grid:{
    height: "auto",
    width: "360px",
    margin: "0 auto",
 //   padding: "1em 1em 0em 0em"
  },
}));

const styles={
    grid:{
      height: "auto",
      width: "360px",
      margin: "0 auto",
   //   padding: "1em 1em 0em 0em"
    },
  
    grid2:{
      padding: "0em 1em 2em 1em"
    },
    
  }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalTrainIntencion({
    trainpost: { in_Intencion,
        out_Accion,
        in_Contrapartida,
        out_Contrapartida,
        username,
       createdAt }
}) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  function decodificar(d) {
    //var cadena = d;
    var caracteres = d.split('.');
    for (var i = 0; i < caracteres.length; i++){
      caracteres[i] = String.fromCharCode(caracteres[i]);
    }
    return caracteres;
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
   {user  &&( <div >
      <Button size="small" color="secondary" onClick={handleClickOpen} >
        ver detalle
      </Button>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Detalle del entrenamiento de Intenciones
            </Typography>
           
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary="Vista" secondary="Ver que contiene cada sesion de entrenamiento de la red neuronal para sugeridos de intenciones...!" />
          </ListItem>
          
          <Divider /> 
        </List>
        <div className ="Psicocard">
    {user  &&(
    <Card fluid>
      <Card.Content>
        <Card.Header>{username}</Card.Header>
        <Card.Meta >
          {moment.utc(createdAt).fromNow(true)}
        </Card.Meta>
        <Card.Header>Titulo de clasificacion: Aca va el titulo de la clasificacion de intenciones</Card.Header>
        <Card.Description >Descripcion: Por ejemplo Inmobiliaria, Compra venta indumentaria, Viajes, Parejas, Diversion, Deportes, etc...</Card.Description>
       
      </Card.Content>
      <Card.Content>
        <Card.Header>Entradas:</Card.Header>
        <Card.Meta >  {in_Intencion}</Card.Meta>
      </Card.Content>
      <Card.Content>
        <Card.Header> Accion de salida:</Card.Header>
        <Card.Meta >  {out_Accion}</Card.Meta>
      </Card.Content>
      <Card.Content>
        <Card.Header>Entrada para contrapartida:</Card.Header>
        <Card.Meta >  {in_Contrapartida}</Card.Meta>
      </Card.Content>
      <Card.Content>
        <Card.Header>Salida Contrapartida:</Card.Header>
        <Card.Meta >  {out_Contrapartida}</Card.Meta>
      </Card.Content>
      
      
    </Card>)}
    </div>
      </Dialog >
    </div>)}
    </ThemeProvider>
  );
}