import React from 'react';
//import { Grid, Transition } from 'semantic-ui-react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
//import { Link } from 'react-router-dom'
//import Avatar from '@material-ui/core/Avatar';
//import SteperAuto from './SteperAuto';
import Carrousel from './tesauri/Carrousel';
import { makeStyles } from '@material-ui/core/styles';



export const FileQuery = gql`
  {
    files {
      id
      filename
      mimetype
      path
    }
  }
`;

const styles={
  grid:{
    height: "auto",
    width: "360px",
    margin: "0 auto",
 //   padding: "1em 1em 0em 0em"
  },

  grid2:{
    padding: "0em 1em 2em 1em"
  },
  
}

export default function Uploads() {

  

  const { loading, data } = useQuery(FileQuery);
  
  if (loading) {
    return <h1>Loading...</h1>;
  } else if (!data) {
    return <h1>No images to show</h1>;
  } else {
    return (
      <>
         <h4 className='text-center'>Carrousell modelo 2</h4 >
         <Carrousel file={data.files}style={styles.grid}/>
      </>
    );
  }
}
