import React from "react";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { layoutGenerator } from "react-break";
import imagen from "../../util/Roadmap.svg";
import responsive from "../../util/Roadmap.svg";
import Paquete1 from "./steps/Variables/Dialog1";
import Paquete2 from "./steps/Variables/Dialog2";
import Paquete3 from "./steps/Variables/Dialog3";
import Paquete4 from "./steps/Variables/Dialog4";
import Paquete5 from "./steps/Variables/Dialog5";
import Paquete6 from "./steps/Variables/Dialog6";

const styles = {
  gridRoadmapDiv: {
    // height: "100%",
    // width: "100%",
    // margin: "0 auto",
    // backgroundColor: "#f2f2fc",
    // border: "1px solid #f2f2fc",
    padding: "2em 0em 0em 2em"
  }
};

const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 992
});

const OnMobile = layout.is("mobile");
//const OnAtLeastTablet = layout.isAtLeast("tablet");
//const OnAtMostPhablet = layout.isAtMost("phablet");
const OnDesktop = layout.is("desktop");

var sectionStyle = {
  //width: "100%",
  //height: "600px",
  width: "100vw",
  height: "80vh",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundImage: url(${AsyncImageInicio}),
  //backgroundImage: `${Background}`,
  backgroundImage: `url(${imagen})`
};

var sectionMobile = {
  //width: "100%",
  //height: "600px",
  width: "100vw",
  height: "160vw",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundImage: url(${AsyncImageInicio}),
  //backgroundImage: `${Background}`,
  backgroundImage: `url(${responsive})`
};

const AsyncImageInicioDesktop = props => {
  const [loadedSrc, setLoadedSrc] = React.useState(
    <Loader active size="massive" />
  );
  React.useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <>
        <div style={sectionStyle}>
          <div>
            {" "}
            <Typography
              variant="h4"
              gutterBottom
              style={{
                padding: "1em 0em 0em 4.1em",
                color: "#3a70b1"
              }}
            >
              Roadmap to BlockChain
            </Typography>
          </div>
          <Paquete1 />
          <Paquete2 />
          <Paquete3 />
          <Paquete4 />
          <Paquete5 />
          <Paquete6 />
        </div>
      </>
    );
  }
  return <Loader active size="massive" />;
};

const AsyncImageInicioMobile = props => {
  const [loadedSrc, setLoadedSrc] = React.useState(
    <Loader active size="massive" />
  );
  React.useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <>
        <div>
          <Typography>
            <Alert severity="warning">
              Por el momento <strong>Roadmap to Blockchain </strong> solo
              funciona en desktop.
            </Alert>
          </Typography>
        </div>
      </>
    );
  }
  return <Loader active size="massive" />;
};

export default ({
  styles,
  handleClick,
  handleSubmit,
  handleChange,
  onClick,
  args,
  errors
}) => {
  return (
    <>
      <OnDesktop>
        <AsyncImageInicioDesktop
          src={imagen}
          handleClick={handleClick}
          styles={styles}
        />
      </OnDesktop>
      <OnMobile>
        <AsyncImageInicioMobile
          src={responsive}
          handleClick={handleClick}
          styles={styles}
        />
      </OnMobile>
    </>
  );
};
