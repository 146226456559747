import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import Slide from "@material-ui/core/Slide";
import { Alert, AlertTitle } from '@material-ui/lab';
import Paquete1 from "../Paquete1";
import LogoFirst from "../../../../util/LogoFirst.svg";
import { Image, Popup, Icon, Message } from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
    root: {
    width: '100%',
    //maxWidth: '36ch',
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  grid: {
    width: "96%"
    // maxWidth: "300px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#4f8bc6",
    
  },
  paquete1: {
    margin: "0em 0em 0em 1em",
    backgroundColor: "#f2f2fc"
    //color: "#4f8bc6"
    // float: "left ",
  },
   barra: {
   
       backgroundColor: "#f2f2fc",
   // border: "1px solid #f2f2fc"
  },
  boton: {
       color: "#4f8bc6"
       //backgroundColor: "#f2f2fc",
   // border: "1px solid #f2f2fc"
  },
  logo: {
    height: "80%",
    width: "17%",
    margin: "0em 0em 0em 4em",
    float: "right",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
       <Popup
      trigger={
        <Icon
        color="blue"
        link
        name="tag"
        content="Open Portal"
        disabled={open}
        positive
        //onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "inverted" })}
        onClick={handleClickOpen}
        style={{
          padding: "0em 0em 0em 1em"
        }}
      />  
      
      }
      content='Información...'
      inverted
    />
      
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
            
          <Toolbar className={classes.barra}>
        <Popup
         trigger={
           <IconButton
              edge="start"
              color="#4f8bc6"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>     
        }
        content='cerrar info'
        inverted
        />  
      
            <Typography variant="h6" className={classes.title}>
              Deploy e infraestructura
            </Typography>
            <Image
                  src={LogoFirst}
                  className={classes.logo}
                  
                />
          </Toolbar>
        </AppBar>
        <List className={classes.root}>
            <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <ListItem alignItems="center">
             <Message info size="big" className={classes.grid}>
            El servicio de Deploy e infraestructura de generación y custodia de
            wallets cripto representa el despliegue del software e
            infraestructura necesaria para soportar la generación y custodia de
            las wallets cotizadas en el paso anterior [Generación y custodia de
            wallets ERC20]... 
            </Message>
            
            </ListItem>

            <ListItem alignItems="center">
              <Message info size="big" className={classes.grid}>
            <strong>  Ofrecemos dos alternativas de deploy: </strong> 
            </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message info size="big" className={classes.grid}>
            El deploy <strong> FirstCripto:</strong>  En esta alternativa, FirstCripto pone a disposición del cliente infraestructura propia
            de ulltima generación, escalable y con un 99,9% de disponibilidad.
            </Message>
            </ListItem>
            <ListItem alignItems="center">
             <Message info size="big" className={classes.grid}>
             El deploy <strong> Cliente:</strong>  En esta alternativa, FirstCripto pone a disposicion del cliente infraestructura propia
            y recursos de programacion básica a medida. La programación se limita solo al desarrollo de integraciones a medida. Además, recursos
            que deplegarán en los sitemas operativos e infraestructura del cliente, lo necesario para hacer funcionar la integración con el core FirstCripto.
            </Message>
            </ListItem>
            
           
          By — <strong>FirstCripto</strong>
          </Alert>
          <Alert severity="error">
            <AlertTitle>Info</AlertTitle>
            <ListItem alignItems="center">
              <Message size="big" negative className={classes.grid}>
            El servicio de deploy Cliente, se limita a el despliegue de software, y no es parte de esta cotización preliminar,
            todo lo relacionado con: <strong>1)</strong> Acceso a internet, <strong>2)</strong> Redes internas o externas de cableado estructurado, routers, switch etc... <strong>3)</strong> Soportes de
            energia <strong>4)</strong> Instalaciones de Racks servidores y cualquier tipo de hardware.
            </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message size="big" negative className={classes.grid}>
            FirstCripto entiende que el cliente ya tiene toda la infraestructura de Hardware, comunicaciones y soporte de energía lista, de lo contrario
            será cotizado aparte...
            </Message>
            </ListItem>
          By — <strong>FirstCripto</strong>
          </Alert>
            
          
        </List>
      </Dialog>
    </span>
  );
}
