import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/auth';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Button, Confirm, Icon } from 'semantic-ui-react';

import { GET_USER_POSTS } from '../util/graphql';
//import MyPopup from '../util/MyPopup';

function DeleteButton({ postId, commentId, callback }) {
  
  const { user } = useContext(AuthContext);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const mutation = commentId ? DELETE_COMMENT_MUTATION : DELETE_POST_MUTATION;

  
  

  const [deletePostOrMutation] = useMutation(mutation, {
    update(proxy) {
      setConfirmOpen(false);
      if (!commentId) {
        const data = proxy.readQuery({
          query: GET_USER_POSTS,variables: {
            username:user.username
             
            }
        });
        data.userPosts = data.userPosts.filter((p) => p._id !== postId);
        proxy.writeQuery({ query: GET_USER_POSTS, variables: {
          username:user.username
          }, data });
      }
      if (callback) callback();
    },
    variables: {
      postId,
      commentId
    }
  });
  return (
    <>
      
        <Button content={commentId ? 'Borrar comentario' : 'Borrar Intencion'}
          as="div"
          color="red"
          floated="right"
          size="mini"
          onClick={() => setConfirmOpen(true)}
        >
          <Icon name="trash" style={{ margin: 0 }} />
        </Button>
      
      <Confirm
        cancelButton= 'Cancelar'
        confirmButton= 'Confirmar'
        content= 'Estas seguro/a de borrar la inetncion...?'
        size= 'small'
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deletePostOrMutation}
      /> 
    </>
  );
}

const DELETE_POST_MUTATION = gql`
  mutation deletePost($postId: ID!) {
    deletePost(postId: $postId)
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($postId: ID!, $commentId: ID!) {
    deleteComment(postId: $postId, commentId: $commentId) {
      _id
      comments {
        id
        username
        createdAt
        body
      }
      commentCount
    }
  }
`;

export default DeleteButton;
