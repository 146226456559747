import gql from 'graphql-tag';


export const FETCH_POSTS_QUERY = gql`
  {
    getPosts {
      _id
      contrapartida
      estadoPost
      body
      body2
      username
      user
      likes{
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada{
        _id
        filename
        path
        mimetype
      }
      imagenes{
        _id
        filename
        path
        mimetype
      }
      especifica{
        id
        tipo
        titulo
        contenido
      }
      componentes{
        id
        titulo
        contenido
        tipo
        username
      }
    }
  }
`;

export const FETCH_POSTS_CONTRA = gql`
  query ($searchTerm: String!, $searchTerm1: String!) {
    searchPost2(searchTerm: $searchTerm, searchTerm1: $searchTerm1 ) {
      _id
      contrapartida
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes{
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada{
        _id
        filename
        path
        mimetype
      }
      imagenes{
        _id
        filename
        path
        mimetype
      }
      especifica{
        id
        tipo
        titulo
        contenido
      }
      componentes{
        id
        titulo
        contenido
        tipo
        username
      }
    }
  }
`;


export const GET_USER_POSTS = gql`
  query($username: String!) {
    userPosts(username: $username) {
      _id
      contrapartida
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes{
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada{
        _id
        filename
        path
        mimetype
      }
      imagenes{
        _id
        filename
        path
        mimetype
      }
      especifica{
        id
        tipo
        titulo
        contenido
      }
      componentes{
        id
        titulo
        contenido
        tipo
        username
      }
      
    }
  }
`;





export const FETCH_SECCION_QUERY = gql`
  {
    getPostSeccion {
      id
      user
      seccionTitulo
      descripcionSeccion
      createdAt
      username
      boniato {
        id
        username
        createdAt
      }
      boniatoCount
      productos {
        id
        username
        createdAt
      }
      insumos {
        id
        username
        createdAt
      }
      comments {
        id
        body
        username
        createdAt
      }
      commentCount
    }
  }
`;


export const FETCH_PSICO_QUERY = gql`
  {
    getPsicoPosts {
      id
      user
      descripcion
      in_descripcion
      explicacion
      in_explicacion
      rasgos
      in_rasgos
      pasion
      in_pasion
      fijacion
      in_fijacion
      autoimagen
      in_autoimagen
      temor
      in_temor
      deseo
      in_deseo
      distorsionDeseo
      in_distorsionDeseo
      manipular
      in_manipular
      justificacion
      in_justificacion
      agredir
      in_agredir
      defensa
      in_defensa
      subtipoConservacion
      in_subtipoConservacion
      subtipoSocial
      in_subtipoSocial
      subtipoSexual
      in_subtipoSexual
      createdAt
      username
    }
  }
`;

export const INTENCION_TRAIN_QUERY = gql`
  {
    getTrainPosts {
      id
      user
      in_Intencion
      out_Accion
      in_Contrapartida
      out_Contrapartida
      createdAt
       username
    }
  }
`;

export const FETCH_SECCION_QUERY2 = gql`
query($suscripcion: String!, $categoria: String!) {
  getPostSeccion2(search: $suscripcion, categoria: $categoria) {
    id
    user
    seccionTitulo
    descripcionSeccion
    createdAt
    suscripcion
    categoria
    username
    boniato {
      id
      username
      createdAt
    }
    boniatoCount
    productos {
      id
      username
      createdAt
    }
    insumos {
      id
      username
      createdAt
    }
    comments {
      id
      username
      createdAt
      body
    }
    commentCount
  }
}
`;

export const FETCH_RECIPES = gql`
  query {
    recipesList {
      _id
      name
      category
      likes
      username
      imageUrl
    }
  }
`;

export const FETCH_RECIPE = gql`
  query($_id: ID!) {
    getRecipe(_id: $_id) {
      _id
      name
      category
      description
      instructions
      createdDate
      likes
      username
      imageUrl
    }
  }
`;

export const SEARCH_RECIPES = gql`
  query($searchTerm: String) {
    searchRecipe(searchTerm: $searchTerm) {
      _id
      name
      category
      description
      instructions
      createdDate
      likes
      username
      imageUrl
    }
  }
`;

export const GET_CURRENT_USER = gql`
query($username: String!)  {
    currentUser(username: $username) {
      username
      email
      favourites {
        _id
        name
        category
        imageUrl
      } 
      borradores{
        _id
        body
        body2
        createdAt
       
      }
    }
  }
`;

export const CURRENT_POST = gql`
query($_id: ID!) {
  currentPost(_id: $_id) {
    _id
    contrapartida
    estadoPost
    body
    body2
    username
    user
    likes{
      username
      userPost
      createdAt
    }
    likeCount
    createdAt
    portada{
      _id
      filename
      path
      mimetype
    }
    imagenes{
      _id
      filename
      path
      mimetype
    }
    especifica{
      id
      tipo
      titulo
      contenido
    }
    componentes{
      id
      titulo
      contenido
      tipo
      username
    }
    matchs{
      _id
      bodyRx
      body2Rx
      bodyTx
      body2Tx
      username
      userPost
      postId
      postIdLike
      portadaPost
      portadarx
      createdAt
      imagenesTx{
        _id
        path
      }
      imagenesRx{
        _id
        path
      }
      componentesTx{
        id
        titulo
        contenido
        tipo
      }
      componentesRx{
        id
        titulo
        contenido
        tipo
      }
      }
  }
}
`;

export const CURRENT_POST2 = gql`
query($username: String!) {
  userPostPrev(username: $username) {
    _id
    contrapartida
    estadoPost
    body
    body2
    username
    user
    likes{
      username
      userPost
      createdAt
    }
    likeCount
    createdAt
    portada{
      _id
      filename
      path
      mimetype
    }
    imagenes{
      _id
      filename
      path
      mimetype
    }
    especifica{
      id
      tipo
      titulo
      contenido
    }
    componentes{
      id
      titulo
      contenido
      tipo
      username
    }
    matchs{
      _id
      bodyRx
      body2Rx
      bodyTx
      body2Tx
      username
      userPost
      postId
      postIdLike
      portadaPost
      portadarx
      createdAt
      imagenesTx{
        _id
        path
      }
      imagenesRx{
        _id
        path
      }
      componentesTx{
        id
        titulo
        contenido
        tipo
      }
      componentesRx{
        id
        titulo
        contenido
        tipo
      }
      }
  }
}
`;


export const GET_USER_RECIPES = gql`
  query($username: String!) {
    userRecipes(username: $username) {
      _id
      name
      category
      likes
      username
      imageUrl
    }
  }
`;

//////////////MUTACIONES RECIPES /////////

export const CREATE_RECIPE = gql`
  mutation(
    $name: String!
    $category: String!
    $description: String!
    $instructions: String!
    $username: String!
    $imageUrl: String!
  ) {
    createRecipe(
      recipeInput: {
        name: $name
        category: $category
        description: $description
        instructions: $instructions
        username: $username
        imageUrl: $imageUrl
      }
    ) {
      _id
      name
      category
      description
      instructions
      createdDate
      likes
      username
      imageUrl
    }
  }
`;

export const LIKE_RECIPE = gql`
  mutation($_id: ID!, $username: String!) {
    likeRecipe(likeInput: { _id: $_id, username: $username }) {
      _id
      name
      likes
    }
  }
`;

export const UNLIKE_RECIPE = gql`
  mutation($_id: ID!, $username: String!) {
    unlikeRecipe(likeInput: { _id: $_id, username: $username }) {
      _id
      name
      likes
    }
  }
`;

export const DELETE_RECIPE = gql`
  mutation($_id: ID!) {
    deleteRecipe(_id: $_id) {
      _id
      name
      username
    }
  }
`;

