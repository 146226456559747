import React, { useContext, useState } from 'react';
import { Button, Form } from 'semantic-ui-react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import { AuthContext } from '../context/auth';
import { useForm } from '../util/hooks';

function Register(props) {
  const {context, user} = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  
  const admin = "admin";

  const { onChange, onSubmit, values } = useForm(registerUser, {
    username: '',
    email: '',
    password: '',
    fechaNacim: '',
    suscripcion: '',
    categoria: '',
    confirmPassword: ''
  });
  
  const [addUser, { loading }] = useMutation(REGISTER_USER, {
    update(
      _,
      {
        data: { register: userData }
      }
    ) {
      context.login(userData);
      props.history.push('/');
    },
    onError(err) {
      setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values
  });

  function registerUser() {
    addUser();
  }

  //{user && user.username === admin && (...)
  return (
   
    <div className="form-container">
      {user && user.categoria === admin && ( 
      <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
        <h4 className="registroUsuario">Registro de usuario</h4>
        <Form.Input
          label="Username"
          placeholder="Igrese un nombre de usuario..."
          name="username"
          type="text"
          value={values.username}
          error={errors.username ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="Email"
          placeholder="Ingrese un email.."
          name="email"
          type="email"
          value={values.email}
          error={errors.email ? true : false}
          onChange={onChange}
        />
         <Form.Input
          label="Fecha de nacimiento"
          placeholder="ddmmaaaa.."
          name="fechaNacim"
          type="text"
          value={values.fechaNacim}
          error={errors.email ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="Suscripcion"
          placeholder="tipo de suscripcion.."
          name="suscripcion"
          type="text"
          value={values.suscripcion}
          error={errors.email ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="Categoria"
          placeholder="Categoria de usuario.."
          name="categoria"
          type="text"
          value={values.categoria}
          error={errors.email ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="Password"
          placeholder="Ingrese una clave..."
          name="password"
          type="password"
          value={values.password}
          error={errors.password ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="Confirme clave"
          placeholder="Confirme clave..."
          name="confirmPassword"
          type="password"
          value={values.confirmPassword}
          error={errors.confirmPassword ? true : false}
          onChange={onChange}
        />
        <Button fluid color="linkedin" type="submit" >
          Registrarme
        </Button>
      </Form>)}
      {Object.keys(errors).length > 0 && (
        <div className="ui error message">
          <ul className="list">
            {Object.values(errors).map((value) => (
              <li key={value}>{value}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
    
  );

}

const REGISTER_USER = gql`
  mutation register(
    $username: String!
    $email: String!
    $password: String!
    $confirmPassword: String!
    $fechaNacim: String!
    $suscripcion: String!
    $categoria: String!
  ) {
    register(
      registerInput: {
        username: $username
        email: $email
        password: $password
        confirmPassword: $confirmPassword
        fechaNacim: $fechaNacim
        suscripcion: $suscripcion
        categoria: $categoria
      }
    ) {
      id
      email
      username
      fechaNacim
      suscripcion
      categoria
      createdAt
      token
    }
  }
`;

export default Register;
