import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
//import IconButton from '@material-ui/core/IconButton';
import { Grid } from 'semantic-ui-react';
//import ListSubheader from '@material-ui/core/ListSubheader';
//import InfoIcon from '@material-ui/icons/Info';
//import tileData from './tileData';
//import ModalCardPropia from'../../ModalCardPropia';
import { AuthContext } from '../../../../context/auth';
//import ModalCardMatch from'./ModalCardMatch';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    //overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    
  },
  title: {
    color: theme.palette.info.light,
  },
  subtitle: {
    color: theme.palette.warning.light,
  },
  titleBar: {
    background:
     'linear-gradient(45deg, rgba(0,0,0,0.50) 90%, rgba(0,0,0,0.77) 90%, rgba(0,0,0,77) 90%)',
      //'linear-gradient(45deg,to up #E7E6E1 30%, #FF8E53 10%)',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
}));


export default function SingleLineGridList({matchData, postIdLike, post: { body,body2,contrapartida }}) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  return (
    
   
  <Grid.Row className={classes.root}>
    <span className="registroUsuario" >Intenciones en accion</span>
      <GridList className={classes.gridList} cellHeight={150}  cols={1.6} >
        {postIdLike && matchData && matchData.map((match) => (
    
         <GridListTile key={match._id}> 
           <Link to={{pathname: `/match/${match._id}`, 
               postId:postIdLike,
               body:body,
               body2:body2,
               contrapartida:contrapartida
            }}>
               <img src={match.portadarx}  />
          </Link> 
          
            
            <GridListTileBar
              title={match.bodyTx}
              subtitle={<span>{match.bodyRx}</span>}
              classes={{
                root: classes.titleBar,
                subtitle: classes.subtitle,
              }}
              
              
            />



          </GridListTile>
        ))}
       </GridList>
   </Grid.Row>
   
  );
}