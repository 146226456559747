import React, { useContext }from 'react';
import { Button, Form } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useForm } from '../../../util/hooks';
import { AuthContext } from '../../../context/auth';
import { INTENCION_TRAIN_QUERY } from '../../../util/graphql';





function PostIntencionTrain(props) {
  
  const { user } = useContext(AuthContext);
  const { values, onChange, onSubmit} = useForm(createTrainPostCallback, {
   
    in_Intencion : '' ,
    out_Accion: '',
    in_Contrapartida: '',
    out_Contrapartida: '',
    
  });

  

  const [createTrainPost, { error }] = useMutation(INTENCION_TRAIN_MUTATION, {
    variables: values, 
    update(proxy, result) {
      const data = proxy.readQuery({
        query: INTENCION_TRAIN_QUERY
      });
      data.getTrainPosts = [result.data.createTrainPost, ...data.getTrainPosts];
      proxy.writeQuery({ query: INTENCION_TRAIN_QUERY, data });
      values.in_Intencion= '';
      values.out_Accion= '';
      values.in_Contrapartida= '';
      values.out_Contrapartida= '';
      
    }
  });
 
  //const codif = this.codificar();

  function createTrainPostCallback() {
    createTrainPost();
  }


  return (
    <>
      {user &&(
      <Form name='form' onSubmit={onSubmit}  >
        <h4>Entrenamiento de intenciones...</h4>
        <Form.Field>
          <Form.TextArea name='in_Intencion' placeholder='in_Intencion...' 
            onChange={onChange}
            
            value= {values.in_Intencion}
            error={error ? true : false}
            />
          <Form.Input
            name='out_Accion' placeholder='out_Accion...' 
            onChange={onChange}
            value={values.out_Accion}
            error={error ? true : false}
          />

          <Form.Input 
            name='in_Contrapartida' placeholder='in_Contrapartida...' 
            onChange={onChange}
            
            value={values.in_Contrapartida}
            error={error ? true : false}
            />
          <Form.Input
            name='out_Contrapartida' placeholder='out_Contrapartida...' 
            onChange={onChange}
            
            value={values.out_Contrapartida}
            error={error ? true : false}
          />
          <Button type="submit" color="linkedin">
            Entrenar ->
          </Button>
        </Form.Field>
        
      </Form>
     )}
      {error && (
        <div className="ui error message" style={{ marginBottom: 20 }}>
          <ul className="list">
            <li>{error.graphQLErrors[0].message}</li>
          </ul>
        </div>
      )}
    </>
  );
}

const INTENCION_TRAIN_MUTATION = gql`
  mutation createTrainPost(
    $in_Intencion: String!,
    $out_Accion: String!,
    $in_Contrapartida: String!,
    $out_Contrapartida: String!) {
     createTrainPost(
        in_Intencion: $in_Intencion,
        out_Accion: $out_Accion,
        in_Contrapartida: $in_Contrapartida,
        out_Contrapartida: $out_Contrapartida) {
            id
            user
            in_Intencion
            out_Accion
            in_Contrapartida
            out_Contrapartida
            createdAt
            username
    }
  }
`;





export default PostIntencionTrain;