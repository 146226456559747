import React from "react";
import { Button, Modal, Icon, Message } from "semantic-ui-react";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

function ModalExampleDimmer() {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined
  });
  const { open, dimmer } = state;

  return (
    <span>
      <Icon
        color="blue"
        link
        name="tag"
        content="Open Portal"
        disabled={open}
        positive
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "inverted" })}
        style={{
          padding: "0em 0em 0em 1em"
        }}
      />

      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
      >
        <Modal.Header>Deploy e infraestructura Cliente</Modal.Header>
        <Modal.Content>
          <Message info size="huge">
            El presente servicio de Deploy e infraestructura Firstcripto
            Consiste en desplegar el software en la infraestructura de
            firstcripto, y firstcripto comparte el código necesario para
            disponibilizar una API para el adquirente e integrar código del lado
            de los sistemas del adquirente poniendo a disposición programadores
            de Firstcripto, para completar la integración.... Firstcripto
            asignará 48 hs de diseño a medida de las APIS correspondientes, del
            lado de la infraestructura y core del solicitante para implementar y
            dejar funcionando el servicio de generación y custodia de wallets
            cripto...Ademas, 272 hs de análisis y desarrollo de integracion y
            compatibilidad de lenguajes específicos conforme a los sistemas del
            solicitante. No cubre desarrollos de front, ni ningún otro
            desarrollo que no sea específicamente APIs de integracion core
            (Backend).
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="blue"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
          >
            Cerrar
          </Button>
        </Modal.Actions>
      </Modal>
    </span>
  );
}

export default ModalExampleDimmer;
