import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import { Grid } from 'semantic-ui-react';
import {Image} from 'semantic-ui-react';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
//import StarBorderIcon from '@material-ui/icons/StarBorder';
import ModalCardPropia from'../../ModalCardPropia';
import CardMedia from '@material-ui/core/CardMedia';
import ListSubheader from '@material-ui/core/ListSubheader';
const useStyles = makeStyles((theme) => ({
  root: {
   display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
   overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    margin: "0.3em 0em 0em 0em",
    color: 'white',
    radius: 2,
    //width: 500,
    //height: 550,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
   // transform: 'translateZ(0)',
  },
  title: {
    color: theme.palette.info.light,
  },
  subtitle: {
    color: theme.palette.warning.light,
  },
  titleBar: {
    background:
      'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
      'rgba(0,0,0,0.60) 100%, rgba(0,0,0,0) 100%)',
  },
  img: {
    height: '100%',
    width: 368,
    //minWidth: 300,
    overflow: 'hidden',
   display: 'block',
   // width: '100%',
  },
  media: {
   // height: 0,
    height: '100%',
    width: 368,
    paddingTop: '130%', // 16:9
    
    
  },
  icon: {
    color: 'white',
  },
}));

/**
 * The example data is structured as follows:
 *
 * import image from 'path/to/image.jpg';
 * [etc...]
 *
 * const tileData = [
 *   {
 *     img: image,
 *     title: 'Image',
 *     author: 'author',
 *     featured: true,
 *   },
 *   {
 *     [etc...]
 *   },
 * ];
 */
export default function AdvancedGridList({tileData, postIdLike}) {
  const classes = useStyles();

  return (
    
     
   
    <Grid.Row className={classes.root}>
    <span className="registroUsuario" >Inteciones sugeridas</span>
        {tileData.map((tile) => (
          <GridListTile key={tile._id} cols={1} className={classes.gridList}>
           
             <CardMedia  
            className={classes.media}
            image={tile.portada.path}
          />
          
           
            <GridListTileBar
              title={tile.body}
              titlePosition="top"
              subtitle={<span >{tile.body2}</span>}
              classes={{
                subtitle: classes.subtitle,
              }}
              
              actionIcon={
                <IconButton aria-label={`info about ${tile.title}`} className={classes.icon}>
                  <ModalCardPropia tile={tile} imagenes={tile.imagenes} body={tile.body} postIdLike={postIdLike} />
                </IconButton>}
              actionPosition="right"
              className={classes.titleBar}
            />
          </GridListTile>
        ))}
      </Grid.Row>
    
   
  );
}
