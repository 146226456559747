import React from "react";
import { Button, Modal, Icon, Message } from "semantic-ui-react";

function exampleReducer(state, action) {
  switch (action.type) {
    case "OPEN_MODAL":
      return { open: true, dimmer: action.dimmer };
    case "CLOSE_MODAL":
      return { open: false };
    default:
      throw new Error();
  }
}

function ModalExampleDimmer() {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    open: false,
    dimmer: undefined
  });
  const { open, dimmer } = state;

  return (
    <span>
      <Icon
        color="blue"
        link
        name="tag"
        content="Open Portal"
        disabled={open}
        positive
        onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "inverted" })}
        style={{
          padding: "0em 0em 0em 1em"
        }}
      />

      <Modal
        dimmer={dimmer}
        open={open}
        onClose={() => dispatch({ type: "CLOSE_MODAL" })}
      >
        <Modal.Header>Cantidad de wallets</Modal.Header>
        <Modal.Content>
          <Message info size="huge">
            El servicio de generación cubre: La generación de noventa mil
            wallets cripto; Desarrollo e implementación de una red neuronal para
            administrar noventa mil wallets, y el entrenamiento de la red
            neuronal para administrar automáticamente la fírma digital de las
            noventa mil wallets cotizadas. El precio del presente servicio esta
            sujeto a cambios según los términos y condiciones. Ver "Términos y
            condiciones"...
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="blue"
            onClick={() => dispatch({ type: "CLOSE_MODAL" })}
          >
            Cerrar
          </Button>
        </Modal.Actions>
      </Modal>
    </span>
  );
}

export default ModalExampleDimmer;
