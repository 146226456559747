import React, { useContext, useEffect, useState, useRef } from "react";
import Grid from "@material-ui/core/Grid";

import { makeStyles } from "@material-ui/core/styles";

//import Dialog from '@material-ui/core/Dialog';
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";

import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";

import { AuthContext } from "../../../../context/auth";

import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { Card, Form } from "semantic-ui-react";
import gql from "graphql-tag";

import DeletteButonCommentM2 from "./DeletteButonCommentM2";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#a2afad"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#0066ff",
      main: "#0044ff",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "rgba(0, 0, 0, 0.60)"
    },
    camara: {
      light: "#0066ff",
      main: "#0044ff",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "rgba(0, 0, 0, 0.90)"
    },

    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  grid: {
    height: "auto",
    width: "360px",
    margin: "0 auto"
    //   padding: "1em 1em 0em 0em"
  }
}));

const styles = {
  grid: {
    height: "auto",
    width: "370px",
    margin: "0 auto"
    //   padding: "1em 1em 0em 0em"
  },

  grid2: {
    padding: "2em 2em 0em 2em"
  }
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DemoComment(props) {
  // const matchId = props.match.params.matchId;
  const matchId = "603a73b94971fc0560a4db5b";
  //const modera = "Moderado";
  //const postId = props.location.postId;
  const { user } = useContext(AuthContext);
  //const { user } = "demo";
  const commentInputRef = useRef(null);
  const [comment, setComment] = useState("");
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  /*
  const {
    data: { getTrainPosts: trainposts }
  } = useQuery(INTENCION_TRAIN_QUERY);
*/

  const queryMatch = useQuery(FETCH_MATCH_QUERY, {
    notifyOnNetworkStatusChange: true,
    variables: {
      matchId
    }
  });

  const {
    data: { getMatch }
  } = queryMatch;
  useEffect(() => {
    queryMatch.startPolling(4000);
    return () => queryMatch.stopPolling();
  }, [queryMatch]);

  const [submitComment] = useMutation(SUBMIT_COMMENT_MUTATION, {
    update() {
      setComment("");
      commentInputRef.current.blur();
    },
    variables: {
      matchId,
      body: comment
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* function deletePostCallback() {
    props.history.push('/');
  }

  const {createdAt,username,comments,postId,userPost } = getMatch;
*/

  return (
    //  <ThemeProvider theme={theme}>
    //  < >
    <Grid columns={1} style={styles.grid}>
      {user && user && (
        <Grid container alignItems="center" justify="center">
          <IconButton color="primary" onClick={handleClickOpen}></IconButton>

          <AppBar className={classes.appBar}>
            <Toolbar>
              {user && (
                <Typography variant="h6" className={classes.title}>
                  Moderador de comentarios Neuraldinamica
                </Typography>
              )}
            </Toolbar>
          </AppBar>
          <List>
            <ListItem button>
              <ListItemText
                primary="Demostracion para clientes"
                secondary="Version 0.17 2021"
              />
            </ListItem>
            <Divider />
          </List>
          <div style={styles.grid}>
            {user && getMatch && (
              <Card fluid>
                <Card.Content>
                  <Card.Header>Neuraldinamica.com</Card.Header>
                  <Card.Meta>
                    Demo creado: {moment.utc(getMatch.createdAt).fromNow(true)}
                  </Card.Meta>
                  <br />
                  <Card.Description as={Link} to={`/charcomments`}>
                    <a>--> Ver dashboard Neural...</a>{" "}
                  </Card.Description>
                </Card.Content>
                <hr />
              </Card>
            )}

            {user && (
              <Card fluid>
                <Card.Content>
                  {user && getMatch && (
                    <p> Ingresa comentarios y observa como modera...</p>
                  )}
                  <Form>
                    <div className="ui action input fluid">
                      <input
                        type="text"
                        placeholder="Comment.."
                        name="comment"
                        value={comment}
                        onChange={event => setComment(event.target.value)}
                        ref={commentInputRef}
                        autocomplete="off"
                      />
                      <button
                        type="submit"
                        className="ui button teal"
                        disabled={comment.trim() === ""}
                        onClick={submitComment}
                      >
                        Enviar
                      </button>
                    </div>
                  </Form>
                  <Card.Meta>by Neuraldinamica ©</Card.Meta>
                </Card.Content>
              </Card>
            )}

            {getMatch &&
              getMatch.comments.map(comment => (
                <Card fluid key={comment.id}>
                  {user && (
                    <>
                      <Card.Content>
                        <Card.Header>{comment.username}</Card.Header>
                        <Card.Meta>
                          {moment(comment.createdAt).fromNow()}
                        </Card.Meta>
                        <Card.Description>{comment.body}</Card.Description>
                      </Card.Content>
                      <Card.Content extra>
                        <a>Area interesada: {comment.body2}</a>
                        <Card.Meta style={{ color: "orange" }}>
                          Emocion asociada: {comment.emocion}
                        </Card.Meta>
                      </Card.Content>
                      <Card.Content extra>
                        {user && user.username === comment.username && (
                          <DeletteButonCommentM2 commentId={comment.id} />
                        )}
                      </Card.Content>
                    </>
                  )}
                </Card>
              ))}
          </div>
        </Grid>
      )}
    </Grid>
  );
}

const SUBMIT_COMMENT_MUTATION = gql`
  mutation($matchId: ID!, $body: String!) {
    createCommentM2(matchId: $matchId, body: $body) {
      _id
      comments {
        id
        body
        body2
        emocion
        trainNeura
        psico
        createdAt
        username
      }
    }
  }
`;

const FETCH_MATCH_QUERY = gql`
  query($matchId: ID!) {
    getMatch(matchId: $matchId) {
      _id
      username
      postIdLike
      userPost
      postId
      createdAt
      comments {
        id
        username
        createdAt
        body
        body2
        emocion
        trainNeura
        psico
      }
    }
  }
`;

const INTENCION_TRAIN_QUERY = gql`
  {
    getTrainPosts {
      id
      user
      in_Intencion
      out_Accion
      in_Contrapartida
      out_Contrapartida
      createdAt
      username
    }
  }
`;

export default DemoComment;
