import React, { useContext, useState, useRef, useEffect } from 'react';
import gql from 'graphql-tag';
import {  useMutation, useQuery } from '@apollo/react-hooks';
//import moment from 'moment';
import {Form} from 'semantic-ui-react';
import CurrentPostprev from './CurrentPostPrev';
import { AuthContext } from '../../context/auth';
import { CURRENT_POST2 } from '../../util/graphql';
import '../../App.css';
import Bayesv from '../../util/gov'
import Bayes from '../../util/go'
//import Loading from './Loading'
import { Grid} from 'semantic-ui-react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Alert from '@material-ui/lab/Alert';
import DescartarButtonAlert from './Intencion/DescartarButtonAlert'

const styles={
  grid:{
    height: "auto",
    width: "360px",
    margin: "0 auto",
  },
  grid2:{
     // width: 360,
      padding: "3em 0em 10em 17em"
  },
 
}

const INTENCION_TRAIN_QUERY = gql`
  {
    getTrainPosts {
      id
      user
      in_Intencion
      out_Accion
      in_Contrapartida
      out_Contrapartida
      createdAt
      username
    }
  }
`;

function AddComponente({handleNext, post}) {
  const { user } = useContext(AuthContext);
  const {tituloInputRef} = useRef(null);
 
 

  const {
    data: { getTrainPosts: trainposts }
  } = useQuery(INTENCION_TRAIN_QUERY);

  const queryPostInfo = useQuery(CURRENT_POST2,{notifyOnNetworkStatusChange: true,variables: {
    username:user.username }});
  const {
    data: { userPostPrev: postc}
  } = queryPostInfo;

  useEffect(() => {
    queryPostInfo.startPolling(1500)
  return () => queryPostInfo.stopPolling();
}, [queryPostInfo]);


  const [body, setTitulo] = useState('');
  
  
  
////////////////////////// Agrego core Bayes ////////////////////



function gov (f) {
    var item2 = trainposts ;
    item2 && item2.map((item2)=>{
      Bayesv.train(item2.in_Intencion,item2.out_Accion);
  });
    var scores = Bayesv.guess(f);
    var winner = Bayesv.extractWinner(scores);
   return winner.label;
   
  };

  /*
  function codificar2(cadena) {
    var caracteres = [];
    var valor = cadena;
    for (var i = 0; i < valor.length; i++){
      caracteres[i] = valor.charAt(i).charCodeAt(0);
    }console.log(caracteres);
    return caracteres.toString().replace(/[,]/g, ".");
  }
  */
  function falta (probar) {
    var probar = body;
    let text = probar;
    let falta = gov(text);
    //console.log("Intencion:", probar);
    return falta;
  } 

  function go (h) {
    var item = trainposts ;
    item && item.map((item)=>{
      Bayes.train(item.in_Contrapartida,item.out_Contrapartida);
  });
    var scores = Bayes.guess(h);
    var winner = Bayes.extractWinner(scores);
  //  console.log("winer:", winner.label);
   return winner.label;
   
  };
  
  

  /*
  function decodificar(d) {
    //var cadena = d;
    var caracteres = d.split('.');
    for (var i = 0; i < caracteres.length; i++){
      caracteres[i] = String.fromCharCode(caracteres[i]);
    }
    return caracteres;
  }
  */
  
   var body2 = falta() ||'';
  // console.log(" Accion body2:", body2);

   function falta2 () {
    let falta2 = go(body2);
    //console.log("Contrapartida:", falta2);
    return falta2;
  }

   var contra = falta2();
  
//////////////Fin bayes////////////////////////////////

  const [submitComponente, { error }] = useMutation(CREATE_POST_MUTATION, {
    update() {
        setTitulo('');
        handleNext();
        tituloInputRef.current.blur();
    },
    variables: {
      body:body,
      body2,
      contra,
      
    }
  });


  let postMarkup;
  if (!post ||!postc) {

    postMarkup = (
  
      <div >
            {user && (
              
                  <Form>
                    
                    <div >
                      <Form.Field style={{width: "290px"}}
                        size='small'
                        control='input'
                        autoFocus={false} 
                        type="text"
                        maxlength="45" 
                        minlength="10"
                        placeholder="Quiero..." 
                        name="body"
                        value={body}
                        error={error ? true : false}
                        onChange={(event) => setTitulo(event.target.value)}
                        ref={tituloInputRef}
                      />
                      
                      <button
                        type="submit"
                        className="ui button teal"
                        disabled={body.trim() === '' || body.length < 10}
                        onClick={submitComponente}
                      >
                        Siguiente
                      </button>
                    </div>
                  </Form>
               
            )}
           {error && (
               <div className="ui error message" style={{ marginBottom: 20 }}>
                 <ul className="list">
                  <li>{error.graphQLErrors[0].message}</li>
                 </ul>
               </div>
            )}
        
     </div>
     
    );

  } else {
    postMarkup = (
    <>
      {user && post && (
      <Grid columns={1} style={styles.grid}>  
          <Grid.Row > 
          <Alert severity="warning">
          La intencion --> {post.body}... esta pendiente!
         
         </Alert>
         <ButtonGroup variant="contained" color="primary" >
             
             <DescartarButtonAlert  postId={post._id} username={post.username}/>
             <Button  onClick={handleNext} color="inherit"
              size="small">
             Completar
            </Button>
           </ButtonGroup>
          
          </Grid.Row>
       
      
      </Grid> )}

     </>  
    );
  }
  return postMarkup;
}

const CREATE_POST_MUTATION = gql`
  mutation createPost($body: String, $body2: String!, $contra: String) {
    createPost(body: $body, body2: $body2, contra: $contra) {
      _id
      contrapartida
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes{
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada{
        _id
        filename
        path
        mimetype
      }
      imagenes{
        _id
        filename
        path
        mimetype
      }
      especifica{
        id
        tipo
        titulo
        contenido
      }
      componentes{
        id
        tipo
        titulo
        contenido
      }
    }
  }
`;



export default AddComponente;
