import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import { ScatterplotChart } from 'react-easy-chart';
import ToolTip from '../../ToolTip';
//import Legend from '../Legend';
//import { escapeHTML } from '../util';
import Scrollspy from 'react-scrollspy';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';



const config = [
  {
    type: 'One',
    color: '#1e313c',
    stroke: 'black'
  },
  {
    type: 'Two',
    color: '#3f4c55',
    stroke: 'black'
  },
  {
    type: 'Three',
    color: '#667178',
    stroke: 'black'
  },
  {
    type: 'Four',
    color: '#f4e956',
    stroke: 'black'
  },
  {
    type: 'Five',
    color: '#e3a51a',
    stroke: 'black'
  },
  {
    type: 'Six',
    color: '#aaac84',
    stroke: 'black'
  },
  {
    type: 'Seven',
    color: '#dce7c5',
    stroke: 'black'
  },
  {
    type: 'Eight',
    color: '#e4e8ec',
    stroke: 'black'
  }
];

const configGen = [
  {
    type: 'A',
    color: '#1e313c'
  },
  {
    type: 'B',
    color: '#3f4c55'
  },
  {
    type: 'C',
    color: '#667178'
  },
  {
    type: 'D',
    color: '#f4e956'
  },
  {
    type: 'E',
    color: '#e3a51a'
  },
  {
    type: 'F',
    color: '#aaac84'
  },
  {
    type: 'G',
    color: '#dce7c5'
  },
  {
    type: 'H',
    color: '#e4e8ec'
  }
];
export default class ScatterplotContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseOutHandler = this.mouseOutHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
  
    this.state = {
      dataDisplay: '',
      showToolTip: false,
      randomDataIntervalId: null,
      windowWidth: 400,
      componentWidth: 1000
    };
    
  }

  createTooltip() {
    if (this.state.showToolTip) {
      return (
        <ToolTip
          top={this.state.up}
          left={this.state.right}
        >
          Gap {this.state.x} a {this.state.y} cantidad {this.state.c}
          
        </ToolTip>
      );
    }
    return false;
  }


  mouseOverHandler(d, e) {
    this.setState({
      showToolTip: true,
      top: `${e.screenY - 10}px`,
      left: `${e.screenX + 10}px`,
      y: d.y,
      x: d.x,
      c: d.c,
      z: d.z  });
  }

  mouseMoveHandler(e) {
    if (this.state.showToolTip) {
      this.setState({ top: `${e.y - 10}px`, left: `${e.x + 10}px` });
    }
  }

  mouseOutHandler() {
    this.setState({ showToolTip: false });
  }

  

 // clickHandler(d) {
 //   this.setState({ dataDisplay: `The amount selected is ${d.y}` });
 // }
 partidox = [
  this.props.partido,
  this.props.partido1,
  this.props.partido2,
  this.props.partido3,
  this.props.partido4,
  this.props.partido5,
  this.props.partido6,
  this.props.partido7,
  this.props.partido8,
  this.props.partido9,
  this.props.legend
];

gap = [
    'Fase TENSION Perfeccion Adulador <---> Fase REPOSO Perfeccionista Correcto',
    'Fase TENSION Autoexigente Adulador <---> Fase REPOSO Libre Imagen Correcta',
    'Fase TENSION Indeciso Libertario <---> Fase REPOSO Planificador Organizado',
    'Fase TENSION Planifica Manipula autoimagen <---> Fase REPOSO Indolente Libre',
    'Fase TENSION Perfeccion Indeciso <---> Fase REPOSO Indolente Ordenado',
    'Fase TENSION Libertario Perfeccionista <---> Fase REPOSO Planificador Perfecto',
    'Fase TENSION Agresivo Dudoso <---> Fase REPOSO Planificador Agresivo Pasivo',
    'Fase TENSION Planificador Agresivo <---> Fase REPOSO Agresivo Pasivo Negligente',
    'Fase TENSION Perfeccionista Pasivo Perezoso <---> Fase REPOSO Perezoso Indolente',
    'TEMOR: Ser incorrecto,  FIJACION: Perfeccion, PASION: Ira, AUTOIMAGEN: Yo reformo',
  ];
 
  render() {

    const cn = this.state.active ? 'menu active' : 'menu';
    return (
      
      <div>
       <div>
          <section id="dataType">
            <h3 style={{color: "orange"}} >Patrones subtipo {this.partidox[10]}</h3>
            
            <h7 style={{color: "gray"}} >Distribucion de subtipos por <span style={{color: "red"}} > gap emocional</span>  sobre {this.partidox[9]} afiliados</h7>
            {this.createTooltip()}
            <ScatterplotChart
            
              data={[
                {
                  type: 'One',
                  x: 1.23,
                  y: 3.21,
                  z: this.partidox[0],
                  c: this.partidox[0],
                  g: this.gap[0]
                },
                {
                  type: 'Two',
                  x: 1.13,
                  y: 5.3,
                  z: this.partidox[1],
                  c: this.partidox[1],
                  g: this.gap[1]
                },
                {
                  type: 'Three',
                  x: 1.25,
                  y: 7.4,
                  z: this.partidox[2],
                  c: this.partidox[2],
                  g: this.gap[2]
                },
                {
                  type: 'Four',
                  x: 1.37,
                  y: 9,
                  z: this.partidox[3],
                  c: this.partidox[3],
                  g: this.gap[3]
                },
                {
                  type: 'Five',
                  x: 1.62,
                  y: 9,
                  z: this.partidox[4],
                  c: this.partidox[4],
                  g: this.gap[4]
                },
                {
                  type: 'Six',
                  x: 1.52,
                  y: 4.7,
                  z: this.partidox[5],
                  c: this.partidox[5],
                  g: this.gap[5]
                },
                {
                  type: 'Seven',
                  x: 1.64,
                  y: 7,
                  z: this.partidox[6],
                  c: this.partidox[6],
                  g: this.gap[6]
                },
                {
                  type: 'Eight',
                  x: 1.76,
                  y: 8.9,
                  z: this.partidox[7],
                  c: this.partidox[7],
                  g: this.gap[7]
                },
                {
                  type: 'Nine',
                  x: 1.98,
                  y: 8.9,
                  z: this.partidox[8],
                  c: this.partidox[8],
                  g: this.gap[8]
                },
                {
                  type: 'One',
                  x: 0,
                  y: 1,
                  z: this.partidox[9],
                  c: this.partidox[9],
                  g: this.gap[9]
                }
              ]}
              axes
              axisLabels={{ x: 'My x Axis', y: 'My y Axis' }}
              dotRadius={6}
              width={480}
              height={270}
              grid
              verticalGrid
              mouseOverHandler={this.mouseOverHandler}
              mouseOutHandler={this.mouseOutHandler}
              mouseMoveHandler={this.mouseMoveHandler}
              clickHandler ={(d) => this.setState({
                dataDisplay: `Neura ${d.g}`
              })}
            />
           
           
               
            
            <div
              style={{
                display: 'inline-block',
                verticalAlign: 'top',
                paddingLeft: '20px' }}
            >
              {(this.state.dataDisplay)
                ? this.state.dataDisplay
                : 'Click on a circle to show the value'
              }
            </div>
          </section>
          <br />
          <br />

        </div>
      </div>
    );
  }

}

ReactDOM.render(
  <ScatterplotContainer />, document.getElementById('edonme'));