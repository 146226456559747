import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
//import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../context/auth';
import {PieChart, Legend} from 'react-easy-chart';
import { Link } from 'react-router-dom';
//import PiechartCompleto from './PiechartCompleto';
import ScaterCompletoPDC from './ScaterCompletoPDC';
const styles={
    grid:{
      height: "auto",
      width: "360px",
      margin: "0 auto",
      padding: "12em 0em 0em 5em"
    },
  
    grid2:{
        padding: "1em 1em 0em 0em"
    },
    
  }


function PartidoDemocrata(props) {
  const { user } = useContext(AuthContext);
  
  const {
    data: { getPartidoPensamiento1misiones:partido }
  } = useQuery(QUERY_PARTIDOSUBTIPO1);

 
  let postMarkup;
  if (!partido) {
    postMarkup = <p>Loading post..</p>;
  } else {
   
    const ene = [ 
                 parseInt([partido[0]/partido[9]*100]).toFixed(0) +"%", 
                 parseInt([partido[1]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[2]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[3]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[4]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[5]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[6]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[7]/partido[9]*100]).toFixed(0) +"%",
                 parseInt([partido[8]/partido[9]*100]).toFixed(0) +"%"
                ];
    
     const legendCantidad = [ 
       
                    "Neural 1: "+partido[0], 
                    "Neural 2: "+partido[1],
                    "Neural 3: "+partido[2],
                    "Neural 4: "+partido[3],
                    "Neural 5: "+partido[4],
                    "Neural 6: "+partido[5],
                    "Neural 7: "+partido[6],
                    "Neural 8: "+partido[7],
                    "Neural 9: "+partido[8]
                   ];

         const legend = [
                    'Neural 1',
                    'Neural 2',
                    'Neural 3',
                    'Neural 4',
                    'Neural 5',
                    'Neural 6',
                    'Neural 7',
                    'Neural 8',
                    'Neural 9',
                  ];
    const link1 = <Link to={{pathname: `/pdc`}}>{partido[0]}</Link> 
    //const html = `<Link to={{pathname: `/home`}}>{partido[0]}</Link> `;

    postMarkup = (
        <>
        
        <ScaterCompletoPDC 
             partido={partido[0]} 
             partido1={partido[1]} 
             partido2={partido[2]}
             partido3={partido[3]}
             partido4={partido[4]}
             partido5={partido[5]}
             partido6={partido[6]}
             partido7={partido[7]}
             partido8={partido[8]}
             partido9={partido[9]}
             eneatipo={ene[0]}
             eneatipo1={ene[1]}
             eneatipo2={ene[2]}
             eneatipo3={ene[3]}
             eneatipo4={ene[4]}
             eneatipo5={ene[5]}
             eneatipo6={ene[6]}
             eneatipo7={ene[7]}
             eneatipo8={ene[8]}
             legend={legend[0]}
             legend1={legendCantidad[1]}
             legend2={legendCantidad[2]}
             legend3={legendCantidad[3]}
             legend4={legendCantidad[4]}
             legend5={legendCantidad[5]}
             legend6={legendCantidad[6]}
             legend7={legendCantidad[7]}
             legend8={legendCantidad[8]}
             
             />
     
     
     </>
    );
  }
  return postMarkup;
}



const QUERY_PARTIDO = gql`
  {
    getPartidoPensamientoF
  }
`;

const QUERY_PARTIDOSUBTIPO1 = gql`
  {
    getPartidoPensamiento1misiones
  }
`;




export default PartidoDemocrata;