import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Slide from "@material-ui/core/Slide";
import Paquete3 from "../Paquete3";
import LogoFirst from "../../../../util/LogoFirst.svg";
import { Image, Popup } from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#4f8bc6"
  },
  paquete1: {
    margin: "-33em 0em 0em 17em",
    backgroundColor: "#f2f2fc"
    //color: "#4f8bc6"
    // float: "left ",
  },
  barra: {
    backgroundColor: "#f2f2fc"
    // border: "1px solid #f2f2fc"
  },
  boton: {
    color: "#4f8bc6"
    //backgroundColor: "#f2f2fc",
    // border: "1px solid #f2f2fc"
  },
  logo: {
    height: "80%",
    width: "17%",
    margin: "0em 0em 0em 4em",
    float: "right",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Popup
        trigger={
          <Button
            variant="outlined"
            startIcon={<CheckIcon style={{ color: "green" }} />}
            color="disabled"
            onClick={handleClickOpen}
            className={classes.paquete1}
          >
            Desarrollo de wallet crypto
          </Button>
        }
        content="Roadmap Activo"
        inverted
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.barra}>
            <IconButton
              edge="start"
              color="#4f8bc6"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Desarrollo de wallet crypto
            </Typography>
            <Image src={LogoFirst} className={classes.logo} />
          </Toolbar>
        </AppBar>
        <List>
          <Paquete3 />
        </List>
      </Dialog>
    </div>
  );
}
