import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DeleteButton from "../Builder/DeleteButtonComponente";
import {
  Form,
  Loader,
  Button,
  Icon,
  Image,
  Item,
  Label,
  Grid,
  Segment,
  Statistic,
  Header,
  Divider,
  Popup
} from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: 600
  },
  root2: {
    padding: "-3em 0em 0em 0em"
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: "rotate(180deg)"
  },
  avatar: {
    backgroundColor: red[500]
  }
}));

export default function RecipeReviewCard({ info, postId, user }) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={classes.root}>
      <div disableSpacing>
        <Typography paragraph className={classes.root2}>
          {info.titulo}
          <Popup content='Click para ver detalle' trigger={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
          } inverted />
          
        </Typography>
      </div>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Item.Extra>
          <DeleteButton
            //floated="left"
            floated="right"
            postId={postId}
            componenteId={info.id}
            user={user}
          />
          <Label>Detalle</Label>
          <span
            style={{
              padding: "0em 1em 0em 0em",
              color: "blue"
            }}
          >
            {info.contenido}
          </span>
          <Label>Costo (ars)</Label>
          <span
            style={{
              padding: "0em 1em 0em 0em",
              color: "green"
            }}
          >
            {Number(info.tipo).toLocaleString('de-DE')}
            
          </span>
          <Label>Tiempo hs</Label>
          <span
            style={{
              padding: "0em 0em 0em 0em",
              color: "green"
            }}
          >
            {info.tiempo}
          </span>
        </Item.Extra>
      </Collapse>
    </div>
  );
}
