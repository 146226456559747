import React, {  useState } from 'react';
import gql from 'graphql-tag';
import { useMutation} from '@apollo/react-hooks';
import { Button, Confirm, Icon } from 'semantic-ui-react';

import { GET_USER_POSTS } from '../../util/graphql';

function PublicarButton({ _id, username,callback}) {
  
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [publicPost] = useMutation(PUBLIC_POST_MUTATION, {
    update(proxy, result) {
        setConfirmOpen(false);
        const data = proxy.readQuery({
          query: GET_USER_POSTS,variables: {
            username,
             
            }
        });
       
       data.userPosts = [result.data.publicPost, ...data.userPosts.filter((p) => p._id !== _id)];
       
        proxy.writeQuery({ query: GET_USER_POSTS, variables: {
          username: username,
          },data});  
          if (callback) callback();
      },
      
    variables: {
      _id,
    },
  
  });

  function createPostCallback() {
    publicPost();
    
  }

  return (
    <>
        <Button 
          as="div"
          color="red"
          floated="right"
          size="mini"
          onClick={() => setConfirmOpen(true)}
        >
          <Icon name="cloud upload" style={{ margin: 0 }} />
        </Button>
      
      <Confirm
        cancelButton= 'Cancelar'
        confirmButton= 'Confirmar'
        content= 'Esta seguro?...Has completado todo?'
        size= 'small'
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={createPostCallback}
      /> 
    </>
  );
}

const PUBLIC_POST_MUTATION = gql`
  mutation publicPost($_id: ID!) {
    publicPost(_id: $_id){
      _id
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes{
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada{
        _id
        filename
        path
        mimetype
      }
      imagenes{
        _id
        filename
        path
        mimetype
      }
      especifica{
        id
        tipo
        titulo
        contenido
      }
      componentes{
        id
        tipo
        titulo
        contenido
      }
    }
  }
`;



export default PublicarButton;