import React,{Component} from 'react';
import TextField from '@material-ui/core/TextField';
import './Steps.css';


////////////////////////////////IMPORTANDO PARA INFORME DESPLEGABLE////////////
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

//////////////////////////////IMPORTANDO PARA LOS ALERTS//////////////////////
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import Link from '@material-ui/core/Link';
import GeneralButon from '../util/General';


//////////////////////////////////////////////////////////////////////NATURAL////////////

import Bayesv from '../util/gov'
//import Bayesd from '../../../../godesv'
import Bayes from '../util/go'
import InfoDerechaBIO from '../util/infoDerecha/InfoDerechaBIO';
import { AuthContext } from '../context/auth';

/////////////////////////////QUE COMIENCE EL JUEGO////////////////////////////


const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(0),
    },
  },
}));

const classes = useStyles
//const { user } = props.user;
class Steps2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      
      currentStep: -2,
      nombreLetra: '',
      nombreLetra2: '',
      error:  '',
      nombre: this.props.user.username,
      f0001: this.props.user.email,
      matriz:'',
      falta:'',
      psico: this.props.psico
     
    }
  }

///////////////////////////////////////ESTADOS////////////////////////////////////////
//nombre = this.props.user.username;
//f0001 = this.props.user.email;


  handleChange = event => {
    const {name, value} = event.target
    this.setState({
      [name]: value
    })    
  }

////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////CORES////////////////////////////////////////////



calculateString(cadena) {
  if (!cadena || cadena === '') {
      return 'Nada';
  }
  function isCoreValue(n) {
      return (n >= 1 && n <= 9); //|| n ===11 || n === 22;
  }
  function sumCharacters(cadena) {
      return Array.prototype.map.call((cadena || '').toLowerCase(), function (ch) {
          if (ch >= 'a' && ch <= 'z') {
              return Number(ch.charCodeAt(0) - 'a'.charCodeAt(0)) + 1;
          }
          return (ch >= '0' && ch <= '9') ? Number(ch) : 0;
      }).reduce(function (sum, current) { return sum + current; }, 0);
  }
  function calculateCore(cadena) {
      var sumBirth = sumCharacters(cadena);
      if (sumBirth === 0) {
          return -1;
      }
      return isCoreValue(sumBirth) ? sumBirth : calculateCore(String(sumBirth));
  }
  return calculateCore(cadena);
}

calculateString2(cadena2) {
  if (!cadena2 || cadena2 === '') {
      return -1;
  }
  function isCoreValue2(n2) {
      return (n2 >= 1 && n2 <= 9);// || n2 ===11 || n2 === 22;
  }
 
  function sumCharacters2(cadena2) {
      return Array.prototype.map.call((cadena2|| '').toLowerCase(), function (ch2) { // eslint-disable-next-line
          if (ch2 >= 'b' && ch2 <= 'd'|| ch2 >= 'f' && ch2 <= 'h'|| ch2 >= 'j' && ch2 <= 'n'|| ch2 >= 'p' && ch2 <= 't'|| ch2 >= 'v' && ch2 <= 'z') {
              return Number(ch2.charCodeAt(0) - 'a'.charCodeAt(0)) + 1;
          } 
          return (ch2 >= '0' && ch2 <= '9') ? Number(ch2) : 0;
      }).reduce(function (sum, current) { return sum + current; }, 0);
  }
  function calculateCore2(cadena2) {
      var sumBirth2 = sumCharacters2(cadena2);
      if (sumBirth2 === 0) {
          return -1;
      }
      return isCoreValue2(sumBirth2) ? sumBirth2 : calculateCore2(String(sumBirth2));
  }
  return calculateCore2(cadena2);
}

calculateString3(cadena3) {
  if (!cadena3 || cadena3 === '') {
      return -1;
  }
  function isCoreValue3(n3) {
      return (n3 >= 1 && n3 <= 9);// || n3 ===11 || n3 === 22;
  }
  function sumCharacters3(cadena3) {
      return Array.prototype.map.call((cadena3|| '').toLowerCase(), function (ch3) {
          if (ch3 === 'a' ||ch3 === 'e'||ch3 === 'i' || ch3 ==='o' ||ch3 ==='u' ) {
              return Number(ch3.charCodeAt(0) - 'a'.charCodeAt(0)) + 1;
          }
          return (ch3 >= '0' && ch3 <= '9') ? Number(ch3) : 0;
      }).reduce(function (sum, current) { return sum + current; }, 0);
  }
  function calculateCore3(cadena3) {
      var sumBirth3 = sumCharacters3(cadena3);
      if (sumBirth3 === 0) {
          return -1;
      }
      return isCoreValue3(sumBirth3) ? sumBirth3 : calculateCore3(String(sumBirth3));
  }
  return calculateCore3(cadena3);
}

////////////////////////////////// Train /////////////////////////////////////////////////

 

gov (f) {
  var item2 = this.props.psico;
  item2.map((item2)=>{
    Bayesv.train(item2.in_descripcion,item2.descripcion);
});
  var scores = Bayesv.guess(f);
  var winner = Bayesv.extractWinner(scores);
 return winner.label;
  
};


////////////////////////////////////// FALTA  //////////////////////////////////////

Falta = (props) => {
  let nombre= this.calculateString(this.state.nombre);
  let camino = this.calculateString(this.state.f0001);
  let text = this.codificar2([nombre]+[camino]);
  let falta = this.gov(text);
  console.log("Texto:", text);
  
  return falta;
}

codificar2(cadena) {
    var caracteres = [];
    var valor = cadena;
    for (var i = 0; i < valor.length; i++){
      caracteres[i] = valor.charAt(i).charCodeAt(0);
    }console.log(caracteres);
    return caracteres.toString().replace(/[,]/g, ".");
  }


decodificar(d) {
  //var cadena = d;
  var caracteres = d.split('.');
  for (var i = 0; i < caracteres.length; i++){
    caracteres[i] = String.fromCharCode(caracteres[i]);
  }
  return caracteres;
}


render() {    
    return (
    <React.Fragment>
    
        <Encabezado
         respuesta = {this.decodificar(this.Falta(this.state.falta))}
         // respuesta = {this.Falta(this.decodificar(this.state.falta))}
        />
      
      </React.Fragment>
    );
  }
}


////////////////////////////////////////////// VISTAS ////////////////////////////////////


                    ////////////////// INPUT ME /////////////////

function Step1(props) {
  
  if (props.currentStep !== -2) {
    return null
  } 
  return(
    <div className="Steps">
      <InfoDerechaBIO/> 
      
    </div>
  );
}


                   ///////////////////// INPUT TO ////////////////

// eslint-disable-next-line
function Step2(props) {
  const [openl, setOpenl] = React.useState(false);
  const [opene, setOpene] = React.useState(false);
  if (props.currentStep !== -1) {
    return null
  } 
  return(
    <div className="Steps">
      
      Mision cero aca va una explicacion
    
      <Link color="initial" component="button" disabled={openl} variant="overline" onClick={() => {setOpenl(true);}}>
                       Longitudes de onda
              </Link>
                <Collapse  in={openl}>
                    <Alert  variant="outlined" severity="info"  
                     action={
                             <IconButton aria-label="close"color="inherit"size="small"onClick={() => {setOpenl(false);}}>
                             <CloseIcon fontSize="inherit" />
                             </IconButton>
                             }>
                       
                       <Typography className={classes.semanticaAlert}>
                          Fisico: {props.dateF}               
                       </Typography> 
                       <Typography className={classes.semanticaAlert}>
                          Emocional: {props.dateE}              
                       </Typography> 
                       <Typography className={classes.semanticaAlert}>
                          Mental: {props.dateM}   
                       </Typography> 
                       <Typography className={classes.semanticaAlert}>
                          Intuicion: {props.dateINT} 
                       </Typography> 
                      
                    </Alert>
                </Collapse>
      

                <Link color="initial" component="button" disabled={opene} variant="overline" onClick={() => {setOpene(true);}}>
                       Canalizacion de estimulos
              </Link>
                <Collapse  in={opene}>
                    <Alert  variant="outlined" severity="info"  
                     action={
                             <IconButton aria-label="close"color="inherit"size="small"onClick={() => {setOpene(false);}}>
                             <CloseIcon fontSize="inherit" />
                             </IconButton>
                             }>
                       
                       <Typography className={classes.semanticaAlert}>
                          Canalizacion: {props.canalizacion} 
                       </Typography> 
                      
                    </Alert>
                </Collapse>
       
    </div>

  );
  
}

function Ayuda (props) {
  const [openr, setOpenr] = React.useState(false);
  
  return(
    <div className="StepsInfo">       
              <Link color="initial" component="button" disabled={openr} variant="overline" onClick={() => {setOpenr(true);}}>
                       <GeneralButon/>
              </Link>
                <Collapse  in={openr}>
                    <Alert  variant="outlined" severity="info"  
                     action={
                             <IconButton aria-label="close"color="inherit"size="small"onClick={() => {setOpenr(false);}}>
                             <CloseIcon fontSize="inherit" />
                             </IconButton>
                             }>
                       <Typography className={classes.semanticaAlert}>
                       {props.respuesta}
                       
                      </Typography>  
                    </Alert>
                </Collapse>
    </div>
  );
}


function Encabezado(props) {
  const [openr, setOpenr] = React.useState(false);
  const [openx, setOpenx] = React.useState(false);
  
  return(
    <div className="StepsInfo">   
    <InfoDerechaBIO/>     
              <Link color="initial" component="button" disabled={openr} variant="overline" onClick={() => {setOpenr(true);}}>
                       <GeneralButon/>
              </Link>
                <Collapse  in={openr}>
                    <Alert  variant="outlined" severity="info"  
                     action={
                             <IconButton aria-label="close"color="inherit"size="small"onClick={() => {setOpenr(false);}}>
                             <CloseIcon fontSize="inherit" />
                             </IconButton>
                             }>
                       <Typography className={classes.semanticaAlert}>
                       {props.respuesta}
                      </Typography> 
                      
                      
                    </Alert>
                </Collapse>
    </div>
  );
}





export default Steps2;