import React, { Component, createRef } from 'react'
import {  Menu, Segment, Sticky } from 'semantic-ui-react'
import _ from 'lodash'
import Encripta from './encriptar';
import PostTrain from './PostTrain';

export default class Stiki extends Component {
    state = { active: true }
  contextRef = createRef()

  handleToggle = () =>
    this.setState((prevState) => ({ active: !prevState.active }))


  render() {
    const { active } = this.state
    return (
      <div ref={this.contextRef}>
        <Sticky context={this.contextRef}>
          <Menu
            attached='top'
            tabular
            style={{ backgroundColor: '#fff', paddingTop: '1em' }}
          >
             
             <Encripta/>
        
          </Menu>
        </Sticky>
        <Segment attached='bottom'>
        <PostTrain/>
        </Segment>
        </div>
    )
  } 
}