import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';


import DeleteButton from '../DeleteButton';
import PublicarButton from './publiCar';

const options = [
  'Seguir Intencion',
  'Seguir usuario',
  'Bloquear intencion',
  'Bloquear usuario',
  
];

const ITEM_HEIGHT = 48;

export default function MenuMiniSingleCard({postId,username}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
 
  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '25ch',
          },
        }}
      >
       
        {
          <MenuItem  onClick={handleClose}>
            <DeleteButton postId={postId}/>
          </MenuItem>
        }
        {
          <MenuItem  onClick={handleClose}>
            <PublicarButton _id={postId} username={username}/>
          </MenuItem>
        }
      </Menu>
    </div>
  );
}