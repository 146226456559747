import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import '../ComparePareja.css';
import UserInfo from './UserInfo';
import UserInfoPublicados from './UserInfoPublicados';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: 360,
  },
}));

export default function FullWidthTabs({user,postsp}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };
  
  return (
    <div >
      <AppBar position="static" color="primary"className={classes.root}>
        <Tabs
        variant="fullWidth"
          value={value}
          onChange={handleChange}
         
        >
          <Tab label="Publicadas" {...a11yProps(0)} />
          <Tab label="Borradores" {...a11yProps(1)} />
         />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction} className={classes.root} >
        {user  && postsp && ( <UserInfoPublicados username = {user} postsp = {postsp} />) }
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} className={classes.root} >
        {user &&  postsp && (<UserInfo  username = {user} postsp = {postsp} />) }
        </TabPanel>
       
      </SwipeableViews>
    </div>
  );
}