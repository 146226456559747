import React from "react";
import { Divider, Form, Button, Icon, Message, Image } from "semantic-ui-react";

import imagen from "../../util/Procesamiento.svg";

export default ({
  styles,
  handleClick,
  handleSubmit,
  handleChange,
  args,
  errors
}) => {
  return (
    <div>
      <div style={styles.box}>
        <Image src={imagen} alt="Table" style={styles.grid} />
      </div>
    </div>
  );
};
