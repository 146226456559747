import React,{Component} from 'react';
import './Header.css';
import logo from '../../util/logo2.svg';
//import MenuBar from '../../components/MenuBar';

class Header extends Component {
   render(){
     return (
        <div>
        
        <header className="Header">
        
        <img src={logo} alt="Table" />
       
        </header>
        
        </div>
     );
    }


}

export default Header;