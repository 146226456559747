import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import ToolTip from '../../../ToolTip';
//import { escapeHTML } from '../util';
import { PieChart, Legend } from 'react-easy-chart';
//import Scrollspy from 'react-scrollspy';

//import { Link } from 'react-router-dom';
//import gql from 'graphql-tag';
//import { useQuery } from '@apollo/react-hooks';
//import ScatterploCompleto from './homes/ScatterploCompleto';
import Scater1 from './Scater1';



const brandColors = {
  greyBlueOne: '#1e313c',
  greyBlueTwo: '#3f4c55',
  greyBlueThree: '#667178',
  yellow: '#f4e956',
  orange: '#e3a51a',
  green: '#aaac84',
  lightGreen: '#dce7c5',
  lightGrey: '#e4e8ec'
};


export default class PieChartPositivo extends PureComponent {
  constructor(props) {
    
    super(props);
    
    this.data = this.generateData();

    this.mouseOverHandler = this.mouseOverHandler.bind(this);
    this.mouseOutHandler = this.mouseOutHandler.bind(this);
    this.mouseMoveHandler = this.mouseMoveHandler.bind(this);

   

    this.state = {
      showToolTip: false,
      componentWidth: 300,
      partido: this.props.partido
    };
    this.styles = {
      '.pie-chart-lines': {
        stroke: 'rgba(0, 0, 0, 1)',
        strokeWidth: 1
      },
      '.pie-chart-text': {
        fontSize: '10px',
        fill: 'white'
      }
    };
    this.partido = this.props.partido
  }
  

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  generateData() {
    const data = [];
    const keys = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
    const colors = [
      '#1e313c',
      '#3f4c55',
      '#667178',
      '#f4e956',
      '#e3a51a',
      '#aaac84',
      '#dce7c5'
    ];
    keys.forEach((key, index) => {
      data.push({
        key,
        value: this.getRandomArbitrary(1, 1000),
        color: colors[index]
      });
    });

    return data;
  }

  mouseOverHandler(d, e) {
    this.setState({
      showToolTip: true,
      top: `${e.y - 10}px`,
      left: `${e.x + 10}px`,
      value: d.value,
      key: d.data.legend });
  }

  mouseMoveHandler(e) {
    if (this.state.showToolTip) {
      this.setState({ top: `${e.y}px`, left: `${e.x + 10}px` });
    }
  }

  mouseOutHandler() {
    this.setState({ showToolTip: false });
  }

  clickHandler(d) {
    this.setState({ dataDisplay: `The amount selected is ${d.value}` });
  }

  createTooltip() {
    if (this.state.showToolTip) {
      return (
        <ToolTip
          top={this.state.top}
          left={this.state.left}
        >
           Comentarios positivos sobre {this.state.key} 
        </ToolTip>
      );
    }
    return false;
  }
  partidox = [
    this.props.partido,
    this.props.partido1,
    this.props.totalPositivo,
    
  ];

  legend = [
    'Atencion al cliente '+this.props.partido,
    'Limpieza '+this.props.partido1,
    
  ];

ene = [
    this.props.eneatipo,
    this.props.eneatipo1,
    
  ];

  bigx = [
    <Scater1/>,
    <Scater1/>,
    <Scater1/>,
    <Scater1/>,
    <Scater1/>,
    <Scater1/>,
    <Scater1/>,
    <Scater1/>,
    <Scater1/>
  ];
  
  config = [
    {color: '#dce7c5'},
    {color: '#e3a51a'}
  ];
  
  render() {
   
    //this.partido = partido;

    const cn = this.state.active ? 'menu active' : 'menu';
    return (
      <div>
        <div className="content">
          
          <section id="clickHandler">
           

            <br />
            <br />
            <div>
            
              <div style={{ display: 'inline-block' }}>
                {this.createTooltip()}
                <PieChart
                size={230}
                innerHoleSize={130}
                labels
                //width={300}
                //height={150}
                data={[
                  { key: this.ene[0], value: this.partidox[0], legend:this.legend[0] , color: '#dce7c5' },
                  { key: this.ene[1], value: this.partidox[1], legend:this.legend[1] , color: '#e3a51a' }
                  
                 ]}
               
                
                  padding={1}
                  mouseOverHandler={this.mouseOverHandler}
                  mouseOutHandler={this.mouseOutHandler}
                  mouseMoveHandler={this.mouseMoveHandler}
                />
                 <div style={{ display: 'flex' }}><h5>Total comentarios positivos: </h5> <p style={{color: "blue",paddingLeft:10}}>  {this.partidox[2]}</p></div>
               <Legend data={[
                     { key: this.legend[0], value: this.partidox[0], color: '#dce7c5'},
                     { key: this.legend[1], value: this.partidox[1] , color: '#e3a51a' },
                     
                 ]} 
                 dataId={'key'} 
                 config={[
                    { color: '#dce7c5'},
                    { color: '#e3a51a' },
                    
                ]} />
              
             
              </div>
                 
              </div>
          </section>

         
        </div>
      </div>
    );
  }
}

ReactDOM.render(
  <PieChartPositivo />, document.getElementById('edonme'));