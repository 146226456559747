import React from 'react';
import StikiApp from './StikiApp';
import ApolloClient from 'apollo-client';
import { ApolloLink, split } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { InMemoryCache } from 'apollo-cache-inmemory';
//import { createHttpLink } from 'apollo-link-http';
import { ApolloProvider } from '@apollo/react-hooks';
import { setContext } from 'apollo-link-context';
//import { SubscriptionClient } from 'subscriptions-transport-ws'
import { getMainDefinition } from "apollo-utilities";
import { createUploadLink } from 'apollo-upload-client';



const httpLink = createUploadLink({
  uri: 'http://localhost:4000/graphql',
});

const wsLink = new WebSocketLink({
  uri: `wss://localhost:4000/graphql`,
  options: {
    reconnect: false
    
  }
});


const authLink = setContext(() => {
  const token = localStorage.getItem('jwtToken');
  return {
    headers: {
      Authorization: token ? `Bearer ${token}` : ''
    }
  };
});


const terminatingLink = new split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink),
);

const link = ApolloLink.from([terminatingLink]);


const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  connectToDevTools: true
});

export default (
  <ApolloProvider client={client}>
    <StikiApp />
  </ApolloProvider>
);
