import React, { useContext } from 'react';
import { Button, Card, Icon, Label, Image } from 'semantic-ui-react';
//import { Link } from 'react-router-dom';
import moment from 'moment';
import './Steps.css';
import { AuthContext} from '../context/auth';
import Modal from './Modal';
//import DeleteButton from './DeleteButton';
//import MyPopup from '../util/MyPopup';




function PsicoCard({
  psico: { descripcion,
    in_descripcion,
    explicacion,
    in_explicacion,
    username,
    createdAt }, psico
}) {
  const { user } = useContext(AuthContext);
  function decodificar(d) {
    //var cadena = d;
    var caracteres = d.split('.');
    for (var i = 0; i < caracteres.length; i++){
      caracteres[i] = String.fromCharCode(caracteres[i]);
    }
    return caracteres;
  }

  return (
    <div className ="Psicocard">
    {user  &&(
    <Card fluid>
      <Card.Content>
        <Card.Header>{username}</Card.Header>
        <Card.Meta >
          {moment.utc(createdAt).fromNow(true)}
        </Card.Meta>
        <Card.Description >Eneatipo: {decodificar(in_descripcion)}</Card.Description>
        <Card.Description >Descripcion: {decodificar(descripcion)}</Card.Description>
        {psico && <Modal psico={psico}/>}
      </Card.Content>
      
    </Card>)}
    </div>
  );
}

export default PsicoCard;
