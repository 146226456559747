import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Transition } from 'semantic-ui-react';
import gql from 'graphql-tag';
import { AuthContext } from '../../context/auth';

import Steps2 from '../../components/Steps2';

const styles={
  grid:{
    height: "auto",
    width: "360px",
    margin: "0 auto",
  },

  grid2:{
      padding: "1em 1em 0em 0em"
  },
}

export const FETCH_PSICO_QUERY = gql`
  {
    getPsicoPosts {
      id
      user
      descripcion
      in_descripcion
      explicacion
      in_explicacion
      createdAt
      username
    }
  }
`;

function NeuraUsuario() {
  const { user } = useContext(AuthContext);
  
  const {
    loading,
    data: { getPsicoPosts: psicoposts }
  } = useQuery(FETCH_PSICO_QUERY);

 

  return (
    <Grid columns={1} style={styles.grid}>
      
      <Grid.Row >
          {user && psicoposts &&(<Steps2 user={user}  psico={psicoposts}/>)}
      </Grid.Row>
    </Grid>
  );
}

export default NeuraUsuario;