import React, { useContext, useEffect } from 'react';
import { AuthContext, AuthProvider } from '../../context/auth';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import MenuIcon from '@material-ui/icons/Menu';
import AddIcon from '@material-ui/icons/Add';

import SearchIcon from '@material-ui/icons/Search';
import MoreIcon from '@material-ui/icons/MoreVert';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { GET_USER_POSTS, CURRENT_POST2 } from '../../util/graphql'
import { useQuery } from '@apollo/react-hooks';
import { Link } from 'react-router-dom';
import PestaniasUI from './PestaniasUI';



const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#fafafa',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#ffb74d',
      main: 'rgba(0, 0, 0, 0.70)',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});



const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(1, 1, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(0),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
}));

export default function BarUp() {
  const { user } = useContext(AuthContext);
  //const user = user;
  const classes = useStyles();
  const demo = "demo";
  const queryPostInfo = useQuery(CURRENT_POST2,{notifyOnNetworkStatusChange: true,variables: {
    //username:user.username
    username:user.username
   //username:'Martin'
     
}});

const {
   data: { userPostPrev: post}
 } = queryPostInfo;

 
 useEffect(() => {
    queryPostInfo.startPolling(500)
  return () => queryPostInfo.stopPolling();
}, [queryPostInfo]);



const queryUserPosts = useQuery(GET_USER_POSTS,{notifyOnNetworkStatusChange: true,variables: {
   username:user.username
    //username:'Martin'
     
}});

const {
data: { userPosts: postsp }
} = queryUserPosts;


useEffect(() => {
   queryUserPosts.startPolling(2500)
 return () => queryUserPosts.stopPolling();
}, [queryUserPosts]);




  return (
    <ThemeProvider theme={theme}>
    <div>
      <CssBaseline /> 
      <div> 
           {user && user.categoria !== 'demo' && postsp &&  (<PestaniasUI color="secondary" user = {user.username} postsp = {postsp} />)}
      </div>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="open drawer">
            <MenuIcon />
          </IconButton>
          <Link to={{pathname: `/postprev`,post:post}}>
          <Fab color="secondary" aria-label="add" className={classes.fabButton}>
             <AddIcon />
          </Fab>
          </Link>
          <div className={classes.grow} />
          <IconButton color="inherit">
            <SearchIcon />
          </IconButton>
          <IconButton edge="end" color="inherit">
            <MoreIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
    </ThemeProvider> 
  );
}