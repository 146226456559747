import React, { Component } from 'react'
import { Form ,Message, Segment,  TextArea} from 'semantic-ui-react'

class Encripta extends Component {
  state = { name: '', valor: '' }
  codificar(cadena) {
    var caracteres = [];
    var valor = cadena;
    for (var i = 0; i < valor.length; i++){
      caracteres[i] = valor.charAt(i).charCodeAt(0);
    }console.log(caracteres);
    return caracteres.toString().replace(/[,]/g, ".");
  }
  handleChange = (e, { name, value }) => this.setState({ [name]: value })

  handleSubmit = () => {
    const { name} = this.state

    this.setState({ valor: this.codificar(name) })
  }
 
  render() {
    const { name, valor } = this.state

    return (
     
      <Segment>
         <Form onSubmit={this.handleSubmit}>
         <h4 className="registroUsuario">Entrenamiento en Psicologia</h4>
          <Form.Group>
            <TextArea style={{width: "850px"}}
              placeholder='Ingrese aqui el texto a encriptar'
              name='name'
              value={name}
              onChange={this.handleChange}
            />
           
            <Form.Button fluid content='Submit' color="linkedin" />
          </Form.Group>
        </Form>
        <strong>Encriptado:</strong>
        <Message info>
           <TextArea style={{width: "850px"}} 
             value={valor}
             placeholder='Resultado encriptado'
             />
             
       </Message>
       
    </Segment>
    
       
      
    )
  }
}

export default Encripta
