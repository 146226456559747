import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import Slide from "@material-ui/core/Slide";
import { Alert, AlertTitle } from '@material-ui/lab';
import Paquete1 from "../Paquete1";
import LogoFirst from "../../../../util/LogoFirst.svg";
import { Image, Popup, Icon, Message } from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
    root: {
    width: '100%',
    //maxWidth: '36ch',
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#4f8bc6",
    
  },
  paquete1: {
    margin: "0em 0em 0em 1em",
    backgroundColor: "#f2f2fc"
    //color: "#4f8bc6"
    // float: "left ",
  },
   barra: {
   
       backgroundColor: "#f2f2fc",
   // border: "1px solid #f2f2fc"
  },
  boton: {
       color: "#4f8bc6"
       //backgroundColor: "#f2f2fc",
   // border: "1px solid #f2f2fc"
  },
  logo: {
    height: "80%",
    width: "17%",
    margin: "0em 0em 0em 4em",
    float: "right",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
       <Popup
      trigger={
        <Icon
        color="blue"
        link
        name="tag"
        content="Open Portal"
        disabled={open}
        positive
        //onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "inverted" })}
        onClick={handleClickOpen}
        style={{
          padding: "0em 0em 0em 1em"
        }}
      />  
      
      }
      content='Información...'
      inverted
    />
      
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
            
          <Toolbar className={classes.barra}>
        <Popup
         trigger={
           <IconButton
              edge="start"
              color="#4f8bc6"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>     
        }
        content='cerrar info'
        inverted
        />  
      
            <Typography variant="h6" className={classes.title}>
              Términos y condiciones generales del uso de roadmap
            </Typography>
            <Image
                  src={LogoFirst}
                  className={classes.logo}
                  
                />
          </Toolbar>
        </AppBar>
        <List className={classes.root}>
            <Alert severity="warning">
            <AlertTitle>Términos y condiciones</AlertTitle>
            <ListItem alignItems="center">
             <Message info size="big"compact>
            <strong>  Roadmap: </strong> Es una herramienta para que los usuarios puedan tener una
            estimación preliminar de costos previa a la gestión comercial de
            FirstCripto.
    
            </Message>
            </ListItem>
            <ListItem alignItems="center">
             <Message info size="big"compact>
            El presente servicio de cotizacion on-line, representa un ejercicio preliminar a la fase
            contractual que eventuelmente podrá acordarse. En ningún caso, el uso de Roadmap, representa
            una oblogación comercial de FirstCripto con el usuario que hace uso de la plataforma. 
            </Message>
            
            </ListItem>
            <ListItem alignItems="center">
             <Message negative size="big"compact>
            <strong>  Que implica enviar un Roadmap a FirstCripto?: </strong> El envío de Roadmap implica,
            para el área comercial de FirstCripto, una idea preliminar de lo que el usuario necesita, y que oportunamente tiene la intecion de contratar los servicios de FirstCripto
            según los valores y tiempos de referencia que el Roadmap a calculado, Este proceso preliminar puede ser convenido en un
            contrato futuro tal cual se ha estimado, o puede sufrir variaciones con acuerdo de las partes que intervienen.
            </Message>
            </ListItem>

            <ListItem alignItems="center">
             <Message negative size="big"compact>
            La vlidez de los valores calculados por<strong>  Roadmap </strong> es de 15 días corridos a partir
            del envío del Roadmap. Pasado el tiempo referido sin mediar contrato comercial, los valores expresados no son válidos, y FirstCripto podrá actualizarlos
            sin consentimiento del usuario. 
            </Message>
            </ListItem>

            
            
          By — <strong>FirstCripto</strong>
          </Alert>
            
          
        </List>
      </Dialog>
    </span>
  );
}
