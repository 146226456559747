import React, { useContext, useState } from 'react';
import gql from 'graphql-tag';
//import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../../context/auth';
import {PieChart, Legend} from 'react-easy-chart';
import { Link } from 'react-router-dom';
//import PiechartCompleto from './PiechartCompleto';
import PiechartNegativo from './PiechartNegativo';
const styles={
    grid:{
      height: "auto",
      width: "360px",
      margin: "0 auto",
      padding: "12em 0em 0em 5em"
    },
  
    grid2:{
        padding: "1em 1em 0em 0em"
    },
    
  }


function CommentsAreas(props) {
  const { user } = useContext(AuthContext);
  
  const {
    data: { getCommentsM2:partido }
  } = useQuery(QUERY_PARTIDOSUBTIPO1);

 
  let postMarkup;
  if (!partido) {
    postMarkup = <p>Loading post..</p>;
  } else {
   
    const ene = [ 
                 parseInt([partido[5]/partido[10]*100]).toFixed(0) +"%", 
                 parseInt([partido[9]/partido[10]*100]).toFixed(0) +"%",
                
                ];
    
     const legendCantidad = [ 
       
                    "Atencion Al cliente: "+partido[5], 
                    "Limpieza: "+partido[9],
                   
                   ];

         const legend = [
                    'Atencion al cliente',
                    'Limpieza',
                   
                  ];
   
    postMarkup = (
        <>
        
        <PiechartNegativo 
             partido={partido[5]} 
             partido1={partido[9]} 
             totalNegativo={partido[1]} 

             eneatipo={ene[0]}
             eneatipo1={ene[1]}
             
             legend={legendCantidad[0]}
             legend1={legendCantidad[1]}
             
             
             />
     
     
     </>
    );
  }
  return postMarkup;
}



const QUERY_PARTIDO = gql`
  {
    getPartidoPensamientoF
  }
`;

const QUERY_PARTIDOSUBTIPO1 = gql`
  {
    getCommentsM2(matchId:"603a73b94971fc0560a4db5b")
  }
`;




export default CommentsAreas;