import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Slide from "@material-ui/core/Slide";
import { Alert, AlertTitle } from "@material-ui/lab";
import Paquete1 from "../Paquete1";
import LogoFirst from "../../../../util/LogoFirst.svg";
import { Image, Popup, Icon, Message, Grid } from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
    //maxWidth: '36ch',
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  grid: {
    width: "96%"
    // maxWidth: "300px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid2: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid3: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#4f8bc6"
  },
  paquete1: {
    margin: "0em 0em 0em 1em",
    backgroundColor: "#f2f2fc"
    //color: "#4f8bc6"
    // float: "left ",
  },
  barra: {
    backgroundColor: "#f2f2fc"
    // border: "1px solid #f2f2fc"
  },
  boton: {
    color: "#4f8bc6"
    //backgroundColor: "#f2f2fc",
    // border: "1px solid #f2f2fc"
  },
  logo: {
    height: "80%",
    width: "17%",
    margin: "0em 0em 0em 4em",
    float: "right",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Popup
        trigger={
          <Icon
            color="blue"
            link
            name="tag"
            content="Open Portal"
            disabled={open}
            positive
            //onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "inverted" })}
            onClick={handleClickOpen}
            style={{
              padding: "0em 0em 0em 1em"
            }}
          />
        }
        content="Información..."
        inverted
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.barra}>
            <Popup
              trigger={
                <IconButton
                  edge="start"
                  color="#4f8bc6"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              }
              content="cerrar info"
              inverted
            />

            <Typography variant="h6" className={classes.title}>
              Diseño y desarrollo Autenticación wallet cripto.
            </Typography>
            <Image src={LogoFirst} className={classes.logo} />
          </Toolbar>
        </AppBar>

        <List className={classes.root}>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <ListItem alignItems="center">
              <Message info size="big" className={classes.grid}>
                El desarrollo del proceso de autenticación de wallet cripto,
                esta definido en Firstcripto como un desarrollo aparte y
                complementeario de una wallet. En el presente, la autenticación
                de wallets o cuentas digitales cliente, se ha vuelto cada ves
                mas compleja. Lo que requiere un apartado especial en cuanto a
                diseño y desarrollo, dado que involucra tecnologías variadas y
                que en ocaciones dista del simple hecho de desarrollar un
                loguín.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message success size="big" className={classes.grid}>
                El desarrollo Autenticación wallet cripto,{" "}
                <strong>va dirigido a empresas </strong>
                que además de brindar acceso seguro a sus clientes, ésta pueda
                verificar que el dispositivo desde donde se está ejecutando la
                aplicación cliente, sea el dispositivo del cliente, por medio de
                un segundo factor de identificación. Y, además de lo descripto,
                Firstcripto propone la capacidad de identifacaión fehaciente de
                la persona, y corroborar que sea quién dice ser.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <strong> Los tres tipos de autenticación: </strong>
            </ListItem>
            <ListItem alignItems="center">
              <Message info size="big" className={classes.minigrid}>
                <strong> Simple </strong>
                Atenticación encriptada, y datos sensibles personales
                encriptados en base de dato. Tokenización de la sesión de cada
                usuario. Para la recuperación de cuenta, la empresa deberá
                implementar mecanismos propios. Esta versión incluye solo la
                recuperación vía email.
              </Message>
              <Message info size="big" className={classes.minigrid2}>
                <strong> Doble Factor </strong>
                Además de la autenticación simple, se incorpora la integración
                de google autenticator, que es un sistema de tokens temporales
                que duran no mas de 60 segundos, y que llegan al usuario por
                medio de una app llamada Autenticator que debe instalarse en el
                smartphone del usuario. De este modo se identifica que el
                usuario es él quién opera la wallet. Para la recuperación de
                cuenta, la empresa deberá implementar mecanismos propios. Esta
                versión incluye solo la recuperación vía email, y el factor
                doble de autenticator. Otra alternativa es enviar al usuario
                coordenadas o token via whatsApp.
              </Message>
              <Message info size="big" className={classes.minigrid3}>
                <strong> Doble Factor e identificación </strong>
                Además de la autenticación simple y doble factor, se incorpora
                la integración un proceso de identificación fehaciente de la
                persona para corroborar que los datos personales corresponden
                con la persona que díce ser. Mas allá de los datos personales,
                este proceso puede involucrar tecnología de gestos faciales
                movimientos y captura de imagen fotográfica para asegurarse de
                que es una persona la que opera, y no una terminal automatizada
                que conocemos como bot. En general éste tipo de procesos se
                utilizan para ALTA DE USUARIO, y RECUPERACION DE CUENTA.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message negative size="big" className={classes.grid}>
                Antes de decidir cual es la estrategia de autenticación mas
                adecuada para sus sistemas, recomendamos que haga un ejercicio
                conceptual del proceso que más se ajusta a sus intereses.
              </Message>
            </ListItem>
            By — <strong>FirstCripto</strong>
          </Alert>
        </List>
      </Dialog>
    </span>
  );
}
