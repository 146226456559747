import React from "react";
import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { layoutGenerator } from "react-break";
import imagen from "../../util/PapeprBurns2.svg";
import responsive from "../../util/MobilePaper.svg";
const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 992
});

const OnMobile = layout.is("mobile");
//const OnAtLeastTablet = layout.isAtLeast("tablet");
//const OnAtMostPhablet = layout.isAtMost("phablet");
const OnDesktop = layout.is("desktop");

var sectionStyle = {
  //width: "100%",
  //height: "600px",
  width: "100vw",
  height: "80vh",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundImage: url(${AsyncImageInicio}),
  //backgroundImage: `${Background}`,
  backgroundImage: `url(${imagen})`
};

var sectionMobile = {
  //width: "100%",
  //height: "600px",
  width: "100vw",
  height: "160vw",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundImage: url(${AsyncImageInicio}),
  //backgroundImage: `${Background}`,
  backgroundImage: `url(${responsive})`
};

const AsyncImageInicioDesktop = props => {
  const [loadedSrc, setLoadedSrc] = React.useState(
    <Loader active size="massive" />
  );
  React.useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <>
        <div style={sectionStyle}>
          <Grid columns={3} style={props.styles.gridEmbeding}>
            <Grid.Column width={8}>
              <Segment vertical>
                <span className="container-segment-titulo">FirstCripto</span>

                <p>
                  Es la primera compañia en desarrollar y patentar una
                  tecnologia híbrida BlockChain-IA.
                </p>
                <span className="container-segment-titulo">
                  Custodia FirstCripto
                </span>

                <p>
                  Con PaperBurns la experiencia BlockChain es simple. Es nuestro
                  sistema de custodia descentralizada de claves cripto. Se trata
                  de una tecnología de redes neuronales que administra millones
                  de wallets automatizando el proceso de firma dígital en cada
                  transaccion. Las claves jamás viajan por internet ni estan
                  alojadas en ningún tipo de base de datos.
                </p>

                <Button
                  basic
                  color="blue"
                  floated="left"
                  onClick={props.handleClick}
                >
                  Contacto
                </Button>
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Segment vertical></Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment vertical></Segment>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
  return <Loader active size="massive" />;
};

const AsyncImageInicioMobile = props => {
  const [loadedSrc, setLoadedSrc] = React.useState(
    <Loader active size="massive" />
  );
  React.useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <>
        <div style={sectionMobile}>
          <Grid style={props.styles.gridEmbedingMobile}>
            <Grid.Column width={16}>
              <Segment vertical>
                <span className="container-segment-titulo">FirstCripto</span>

                <p>
                  Es la primera compañia en desarrollar y patentar una
                  tecnologia híbrida BlockChain-IA.
                </p>
                <span className="container-segment-titulo">
                  Custodia FirstCripto
                </span>

                <p>
                  Con PaperBurns la experiencia BlockChain es simple. Es nuestro
                  sistema de custodia descentralizada de claves cripto. Se trata
                  de una tecnología de redes neuronales que administra millones
                  de wallets automatizando el proceso de firma digital en cada
                  transaccion. Las claves jamás viajan por internet ni estan
                  alojadas en ningún tipo de base de datos.
                </p>

                <Button
                  basic
                  color="blue"
                  floated="left"
                  onClick={props.handleClick}
                >
                  Contacto
                </Button>
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
  return <Loader active size="massive" />;
};

export default ({
  styles,
  handleClick,
  handleSubmit,
  handleChange,
  onClick,
  args,
  errors
}) => {
  return (
    <>
      <OnDesktop>
        <AsyncImageInicioDesktop
          src={imagen}
          handleClick={handleClick}
          styles={styles}
        />
      </OnDesktop>
      <OnMobile>
        <AsyncImageInicioMobile
          src={responsive}
          handleClick={handleClick}
          styles={styles}
        />
      </OnMobile>
    </>
  );
};
