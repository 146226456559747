import React, { Component, createRef } from "react";
import { Image, Sticky } from "semantic-ui-react";
//import _ from 'lodash'
import MenuBar from "./components/MenuBar";

//////////////////Iport todo lo de app
import { BrowserRouter as Router, Route } from "react-router-dom";
//import { Container } from 'semantic-ui-react';
import "semantic-ui-css/semantic.min.css";
import "./App.css";
import { AuthProvider } from "./context/auth";
import AuthRoute from "./util/AuthRoute";
import Home from "./pages/Home3";
import Inicio from "./pages/Home5";
import Login from "./pages/Login";
import Register from "./pages/Register";
import SingleIntencion from "./components/edon.me/singleIntencion/SingleIntencion";
import Match2 from "./components/edon.me/Builder/Contrapartida/DemoComment";
import ModalCardMatch from "./components/edon.me/Builder/Contrapartida/ModalCardMatch";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PsicoTrain from "./pages/PsicoTrain";
import IntencionTrain from "./pages/IntencionTrain";
import NeuraUsuario from "./components/Train/NeuraUsuario";
import CurrentPost from "./components/edon.me/CurrentPost";
import PostPrev from "./components/edon.me/Intencion/PostPrev";
import PartidoDemocrata from "./pages/PartidoDemocrata";
import ScatterplotCompleto from "./pages/homes/ScatterploCompleto";
import CharComments from "./pages/moderadores/CharComments";
import LogoFirst from "./util/LogoFirst.svg";

export default class StikiMenu extends Component {
  state = { active: true };
  contextRef = createRef();

  handleToggle = () =>
    this.setState(prevState => ({ active: !prevState.active }));

  render() {
    // const { active } = this.state
    return (
      <div ref={this.contextRef}>
        <AuthProvider>
          <Router>
            <Sticky context={this.contextRef}>
              <div
                attached="top"
                tabular
                style={{
                  backgroundColor: "#f2f2fc",
                  paddingTop: "0em"
                  //height: "14em"
                }}
              >
                <MenuBar attached="top" />
              </div>
            </Sticky>

            <div className="homes">
              <Route exact path="/" component={Inicio} />
              <Route exact path="/home" component={Home} />
              <AuthRoute exact path="/login" component={Login} />
              <Route exact path="/psicoTrain" component={PsicoTrain} />
              <Route exact path="/neura" component={NeuraUsuario} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/posts/:postId2" component={SingleIntencion} />
              <Route exact path="/post/:postId3" component={CurrentPost} />
              <Route exact path="/match/:matchId" component={ModalCardMatch} />
              <Route exact path="/postprev" component={PostPrev} />
              <Route exact path="/postTrain" component={IntencionTrain} />
              <Route exact path="/pdc" component={PartidoDemocrata} />
              <Route exact path="/scatter" component={ScatterplotCompleto} />
              <Route exact path="/moderar" component={Match2} />
              <Route exact path="/charcomments" component={CharComments} />
            </div>
            <Footer />
          </Router>
        </AuthProvider>
      </div>
    );
  }
}
