import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { Alert, AlertTitle } from "@material-ui/lab";
import Typography from "@material-ui/core/Typography";
import Variable1 from "../../../components/edon.me/Step01";
//import Variable1 from "../../../components/edon.me/Step01";
//import Variable2 from "../../../components/edon.me/Builder/AddTareaBackOffice";
//import Variable2 from "../../../components/edon.me/Builder/AddComponente2";
import Variable2 from "../../../components/edon.me/Builder/AddGenCustodiaWallet";
import Variable3 from "../../../components/edon.me/Builder/AddSmart";
import Variable4 from "../../../components/edon.me/Builder/AddDesaWallet";
import Variable5 from "../../../components/edon.me/Builder/AddDesaAuth";
import Variable6 from "../../../components/edon.me/Builder/AddDeploy";
import Enviar from "../../../components/edon.me/Builder/Enviar";
//import Publicar from "../../../components/edon.me/Intencion/PublicarButton";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f2f2fc"
  },

  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  }
}));

function getSteps() {
  return [
    "Ingrese email para usar Roadmap to BlockChain",
    "Generacion y custodia de Wallets ERC20",
    "Desarrollo Smart Contract ERC20",
    "Diseño y desarrollo Wallet cripto",
    "Diseño y desarrollo Autenticación",
    "Despliegue",
    "Revisar y enviar solicitud"
  ];
}

function getStepContent(
  step,
  handleNext,
  activeUser1,
  handleUser,
  presupuesto
) {
  switch (step) {
    case 0:
      return <Variable1 handleNext={handleNext} />;
    case 1:
      return <Variable2 handleNext={handleNext} />;
    case 2:
      return <Variable3 handleNext={handleNext} />;
    case 3:
      return <Variable4 handleNext={handleNext} />;
    case 4:
      return <Variable5 handleNext={handleNext} />;
    case 5:
      return <Variable6 handleNext={handleNext} />;
    case 6:
      return <Enviar handleNext={handleNext} />;
    default:
      return "Unknown step";
  }
}

export default function VerticalLinearStepper(props, { presupuesto }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeUser, setActiveUser] = React.useState("Martin");
  const steps = getSteps();
  var nombre = "Martin";
  const activeUser1 = activeUser;

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleUser = () => {
    setActiveUser("Marco");
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent>
              <Typography>
                {getStepContent(
                  index,
                  handleNext,
                  activeUser,
                  handleUser,
                  presupuesto
                )}
              </Typography>
              <div className={classes.actionsContainer}>
                <div>
                  {(activeStep === 2 ||
                    activeStep === 3 ||
                    activeStep === 4 ||
                    activeStep === 5 ||
                    activeStep === 6) && (
                    <Button
                      disabled={activeStep === 1}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Atras
                    </Button>
                  )}
                  {(activeStep === 1 ||
                    activeStep === 2 ||
                    activeStep === 3 ||
                    activeStep === 4 ||
                    activeStep === 5 ||
                    activeStep === 6) && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Guardar sin enviar"
                        : "Siguiente"}
                    </Button>
                  )}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>
            <Alert severity="success">
              <AlertTitle>Finalizó todos los pasos</AlertTitle>
              Todos los pasos estan completos. —{" "}
              <strong>Si presionó el botón "ENVIAR"</strong> el Roadmap ha sido
              recibido por el área comercial de FirstCripto.
            </Alert>
            <Alert severity="warning">
              si presionó el botón <strong>"GUARDAR SIN ENVIAR" </strong> el
              Roadmap queda en estado pendiente...Puede regresar a{" "}
              <strong> Inicio </strong> , revisar el Roadmap y enviarlo cuando
              usted lo disponga.
            </Alert>
            <Alert severity="error">
              Si ha dejado el Roadmap en <strong>pendiente... </strong> el
              Roadmap queda en estado pendiente para todos los Roadmaps, si
              desea iniciar un Roadmap nuevo de otro nivel al que inició,
              descárte el Roadmap, o continúe desde ahí.
            </Alert>
          </Typography>
          <Button onClick={handleReset} className={classes.button}>
            Inicio
          </Button>
        </Paper>
      )}
    </div>
  );
}
