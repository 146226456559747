import React, { useContext} from 'react';
import { Grid, Transition } from 'semantic-ui-react';
import { AuthContext } from '../../context/auth';
import CardIntencion from '../CardIntencion';

const styles={
    grid:{
      height: "auto",
      width: "367px",
      margin: "0 auto", 
    },
    grid2:{
      minWidth: 355,
        padding: "1em 0em 0em 0em"
    },
  }

function UserInfoPublicados({postsp}) {
 
  const { user } = useContext(AuthContext);
 
  let postMarkup;
  
    postMarkup = (
      <div  fluid = "true">
        {user && (  
        <Grid columns={1} >
             <Grid.Row >    
                     {<Transition.Group >
                        <Grid.Row >
                          {user && (<p className="registroUsuario" >Inteciones publicadas</p> )}
                         </Grid.Row>
                         <Grid.Row>
                          {postsp &&
                             postsp.map((postp) => (
                                <div key={postp._id} style={styles.grid2}>
                                   {postp.estadoPost ==='publicado' && (<CardIntencion user ={user} post={postp}/>) }
                                </div>
                           ))} 
                        </Grid.Row>
                     </Transition.Group> }
                </Grid.Row>
        </Grid>    
        )}     
      </div>
     
    );
 
  return postMarkup;

}


export default UserInfoPublicados;