import React, { useContext, useState, useRef, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
//import moment from "moment";
//import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { Form, Loader, Button, Icon, Image, Item, Label, Grid, Popup, Header } from "semantic-ui-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import IconButton from "@material-ui/core/IconButton";
//import { AuthContext } from "../../../context/auth";
//import LikeButton from '../components/LikeButton';
//import DeleteButton from "../Builder/DeleteButtonComponente";
//import MyPopup from '../util/MyPopup';
import "../../../App.css";

import { makeStyles } from "@material-ui/core/styles";
//import Card from "@material-ui/core/Card";
//import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CURRENT_POST2 } from "../../../util/graphql";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import Alert from "@material-ui/lab/Alert";
import EjemploDecision from "../../../pages/login/steps/Variables/DialogEjemploDecision.js";
import EjemploDecision2 from "../../../pages/login/steps/Variables/DialogEjemploDecision2.js";
//import Terminos from "../../../pages/login/steps/Variables/DialogTerminos.js";
//import Info60k from "./Cantidad60kW";
//import Info90k from "./Cantidad90kW";
//import Collapse from "./ColapseItem";
//import ImagenCustodia from "../../../util/GenYcustodia.svg";
import clsx from "clsx";
import Collapse from "@material-ui/core/Collapse";



const useStyles = makeStyles(theme => ({
    root: {
        // minWidth: 350,
        // maxWidth: 355,

        width: 600,
        margin: theme.spacing(0.3)
    },
    rootCollapse: {
    maxWidth: 400
    //width: 800,
  },
    bullet: {
        display: "inline-block",
        margin: "1 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    },
    root2: {
        padding: "-3em 0em 0em 0em"
    },
    media: {
        height: 0,
        paddingTop: "56.25%" // 16:9
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest
        })
    },
    expandOpen: {
        transform: "rotate(180deg)"
    },
}));

const styles = {
    grid: {
        height: "auto",
        //width: 400,
        //maxWidth: 360,
        margin: "0 auto",
        padding: "3em 0em 0em 0em"
    },
    grid2: {
        // width: 360,
        padding: "3em 0em 10em 17em"
    },
    img: {
        height: 370,
        maxWidth: 500,
        //minWidth: 300,
        // overflow: 'hidden',
        // display: 'block',
        width: "100%"
    }
};

const useStyles2 = makeStyles({
    root2: {
        height: 50,
        flexGrow: 1,
        maxWidth: 700,
    },
});


function AddComponente() {
    //const postId = props.match.params.postId;
    //const { user } = useContext(AuthContext);
    localStorage.setItem("userVacio", " ");
    const userSecundario = localStorage.getItem("userLocal");
    const primerUsuario = localStorage.getItem("userVacio");
    let usuario = () => {
        if (!userSecundario) {
            usuario = primerUsuario;
        } else {
            usuario = userSecundario;
        }
        return usuario;
    };
    const classes2 = useStyles2();
    const user = usuario();

    const queryPostInfo = useQuery(CURRENT_POST2, {
        notifyOnNetworkStatusChange: true,
        variables: {
            username: user
        }
    });
    const {
        data: { userPostPrev: postc }
    } = queryPostInfo;

    useEffect(() => {
        queryPostInfo.startPolling(1500);
        return () => queryPostInfo.stopPolling();
    }, [queryPostInfo]);

    const {
        tituloInputRef,
        contenidoInputRef,
        tipoInputRef,
        tiempoInputRef
    } = useRef(null);

    const [titulo, setTitulo] = useState("");
    const [contenido, setContenido] = useState("");
    const [wallets, setWallets] = useState("");
    const [tipo, setTipo] = useState("");
    const [tiempo, setTiempo] = useState("");
    const [age, setAge] = React.useState("0");
    const [age2, setAge2] = React.useState("0");
    const [age3, setAge3] = React.useState("0");
    const [read, setRead] = React.useState(true);
    const [read2, setRead2] = React.useState(true);
    const [read3, setRead3] = React.useState(true);
    const [checked, setChecked] = React.useState(false);
    const [checkeds, setCheckeds] = React.useState(false);
    const [checked3, setChecked3] = React.useState(false);

    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    let post = () => {
        if (!postc) {
            post = " ";
        } else {
            post = postc._id;
        }
        return post;
    };

const precioWallet= "3";
let multi = (a,b)=>{
  var  result = a*b;
 return result;
}

    let price = (cantidad) => {
        if (cantidad === "50000") {
           return price = multi(cantidad,precioWallet).toString();
        } else if (cantidad === "100000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "150000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "200000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "250000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "300000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "350000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "400000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "450000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "500000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "550000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "600000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "650000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "700000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "750000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "800000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "850000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "900000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "950000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1000000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1050000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1100000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1150000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1200000"){
           return price = multi(cantidad,precioWallet).toString();
        } else if (cantidad === "2000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "2500000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "3000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "3500000"){
           return price = multi(cantidad,precioWallet).toString();
        } else if (cantidad === "4000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "4500000"){
           return price = multi(cantidad,precioWallet).toString();
        } else if (cantidad === "5000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "5500000"){
           return price = multi(cantidad,precioWallet).toString();
        } else if (cantidad === "6000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "7000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "8000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "9000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "10000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "11000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else if (cantidad === "12000000") {
            return price = multi(cantidad, precioWallet).toString();
        } else
            return price === "0";
    };

///////////////////////////HORAS //////////////////////////////////

const ponderacionHora= "0.00024";
let multihora = (a,b)=>{
  var  result = a*b;
 return result;
}

    let horas = (cantidad) => {
        if (cantidad === "50000") {
           return horas = multihora(cantidad,ponderacionHora *3).toString();
        } else if (cantidad === "100000"){
           return horas = multihora(cantidad,ponderacionHora *2).toString();
        }else if (cantidad === "150000"){
           return horas = multihora(cantidad,ponderacionHora *1.5).toString();
        }else if (cantidad === "200000"){
           return horas = multihora(cantidad,ponderacionHora *1.2).toString();
        }else if (cantidad === "250000"){
           return horas = multihora(cantidad,ponderacionHora *1.2).toString();
        }else if (cantidad === "300000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "350000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "400000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "450000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "500000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "550000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "600000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "650000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "700000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "750000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "800000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "850000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "900000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "950000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1050000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1100000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1150000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1200000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "2000000") {
            return horas = multihora(cantidad, ponderacionHora).toString();
        } else if (cantidad === "2500000") {
            return horas = multihora(cantidad, ponderacionHora).toString();
        } else if (cantidad === "3000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "3500000") {
            return horas = multihora(cantidad, ponderacionHora).toString();
        } else if (cantidad === "4000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "4500000") {
            return horas = multihora(cantidad, ponderacionHora).toString();
        } else if (cantidad === "5000000") {
            return horas = multihora(cantidad, ponderacionHora).toString();
        } else if (cantidad === "5500000") {
            return horas = multihora(cantidad, ponderacionHora).toString();
        } else if (cantidad === "6000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "7000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "8000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "9000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "10000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        } else if (cantidad === "11000000") {
            return horas = multihora(cantidad, ponderacionHora).toString();
        } else if (cantidad === "12000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else
        return horas === "0";
    };







    const idpost = post();

    const handleSelect = (event) => {
        setAge(event.target.value);
        setTipo(price(event.target.value));
        setWallets(event.target.value);
        setTiempo(horas(event.target.value));
        setContenido(Number(event.target.value).toLocaleString('de-DE') + " Wallets");
    };

    const handleSelect2 = (event) => {
        setAge2(event.target.value);
        setTipo(price(event.target.value));
        setWallets(event.target.value);
        setTiempo(horas(event.target.value));
        setContenido(Number(event.target.value).toLocaleString('de-DE') + " Wallets");
    };

    const handleSelect3 = (event) => {
        setAge3(event.target.value);
        setTipo(price(event.target.value));
        setWallets(event.target.value);
        setTiempo(horas(event.target.value));
        setContenido(Number(event.target.value).toLocaleString('de-DE') + " Wallets");
    };

    const handleCheck = event => {
        if (checkeds === true || checkeds === false && checked3 === true || checked3 === false && checked === false) {
            setCheckeds(false);
            setChecked3(false);
            setChecked(event.target.checked);
            setTiempo(horas(event.target.value));
            setTipo("150000");
            setTitulo("Generación y custodia de wallets ERC20");
            setContenido("50.000 Wallets");
            setAge("50000");
            setAge2("0");
            setAge3("0");
            setRead(false);
            setRead2(true);
            setRead3(true);
            setWallets("50000");

        } else {
            setChecked(event.target.checked);
            setTiempo("");
            setTipo("");
            setTitulo("");
            setContenido("");
            setAge("0");
            setAge2("0");
            setAge3("0");
            setRead(true);
            setRead2(true);
            setRead3(true);
            setWallets("");

        }
    };

    const handleCheck2 = event => {
        if (checked === true || checked === false && checked3 === true || checked3 === false && checkeds === false) {
            setChecked(false);
            setChecked3(false);
            setCheckeds(event.target.checked);
            setTiempo(horas(event.target.value));
            setTipo("300000");
            setTitulo("Generación y custodia de wallets ERC20");
            setContenido("100.000 Wallets");
            setAge2("100000");
            setAge("0");
            setAge3("0");
            setRead2(false);
            setRead(true);
            setRead3(true);
            setWallets("100000");

        } else {
            setCheckeds(event.target.checked);
            setTiempo("");
            setTipo("");
            setTitulo("");
            setContenido("");
            setAge("0");
            setAge2("0");
            setRead(true);
            setRead2(true);
            setWallets("");


        }
    };

    const handleCheck3 = event => {
        if (checked === true || checked === false && checkeds === true || checkeds === false && checked3 === false) {
            setChecked(false);
            setCheckeds(false);
            setChecked3(event.target.checked);
            setTiempo(horas(event.target.value));
            setTipo("3000000");
            setTitulo("Generación y custodia de wallets ERC20");
            setContenido("1.000.000 Wallets");
            setAge3("1000000");
            setAge2("0");
            setAge("0");
            setRead3(false);
            setRead2(true);
            setRead(true);
            setWallets("1000000");

        } else {
            setChecked3(event.target.checked);
            setTiempo("");
            setTipo("");
            setTitulo("");
            setContenido("");
            setAge("0");
            setAge2("0");
            setAge3("0");
            setRead(true);
            setRead2(true);
            setRead3(true);
            setWallets("");


        }
    };


    

    const [submitComponente, { error }] = useMutation(
        SUBMIT_COMPONENTE_MUTATION,
        {
            update() {
                setTitulo("");
                setContenido("");
                setTipo("");
                setTiempo("");
                setCheckeds(false);
                setChecked(false);
                setChecked3(false);
                setAge("0");
                setAge2("0");
                setAge3("0");
                setRead(true);
                setRead2(true);
                setRead3(true);
                setWallets("");
                tituloInputRef.current.blur();
                contenidoInputRef.current.blur();
                tipoInputRef.current.blur();
                tiempoInputRef.current.blur();
            },
            variables: {
                postId: idpost,
                titulo: titulo,
                contenido: contenido,
                tipo: tipo,
                tiempo: tiempo,
                username: user
            }
        }
    );

    const classes = useStyles();
    
    
    let postMarkup;
    if (postc) {
        const {
            _id,
            body,
            body2,
            createdAt,
            username,
            especifica,
            componentes
        } = postc;
       
        const subtotal = especifica[0];
        const total = subtotal.contenido;
        const totalHs = subtotal.tipo;
    
        postMarkup = (
            <>
                <div className={classes.rootCollapse}>
                    <div disableSpacing>
                        <Typography paragraph className={classes.root2}>
                            Tareas tipo: Decisión y condición
                            <Popup content='Click para agregar opciones' trigger={
                                <IconButton
                                    className={clsx(classes.expand, {
                                        [classes.expandOpen]: expanded
                                    })}
                                    onClick={handleExpandClick}
                                    aria-expanded={expanded}
                                    aria-label="show more"
                                >
                                    <ExpandMoreIcon />
                                </IconButton>
                            } inverted />

                        </Typography>
                    </div>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>

                        <Item>

                            <Item.Content>
                                <Item.Description>
                                    <CardContent>
                                        <Form>
                                            <div>
                                                <Typography
                                                    className={classes.title}
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    <Checkbox
                                                        name="tiempo"
                                                        checked={checked}
                                                        value="50000"
                                                        onChange={handleCheck}
                                                        //inputProps={{ "aria-label": "primary checkbox" }}
                                                        color="primary"
                                                        ref={tipoInputRef}
                                                    />
                                                    <span style={{
                                                        padding: "0em 1em 0em 0em",
                                                        // color: "blue" 
                                                    }}><FormControl className={classes.formControl} style={{padding: "1em 0em 0em 0em"}}>
                                                         <Popup content='Decisiones del tipo si tal cosa, entonces esto... En general
                                                         se usa para que el sistema reaccione a estados.' trigger={
                                                            <InputLabel id="demo-simple-select-label" style={{
                                                        padding: "1em 0em 0em 0em",
                                                        //float:'right'
                                                        color: "blue" 
                                                         }}> DECISION CONDICIONAL</InputLabel>  } inverted />
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={age}
                                                                onChange={handleSelect}
                                                                inputProps={{ readOnly: read }}
                                                            >
                                                                <MenuItem value={"50000"}>1</MenuItem>
                                                                <MenuItem value={"150000"}>3</MenuItem>
                                                                <MenuItem value={"250000"}>5</MenuItem>
                                                                <MenuItem value={"350000"}>7</MenuItem>
                                                                <MenuItem value={"450000"}>9</MenuItem>
                                                                <MenuItem value={"550000"}>11</MenuItem>
                                                                <MenuItem value={"650000"}>13</MenuItem>
                                                                <MenuItem value={"750000"}>15</MenuItem>
                                                                <MenuItem value={"850000"}>17</MenuItem>
                                                                <MenuItem value={"950000"}>19</MenuItem>
                                                                <MenuItem value={"1050000"}>21</MenuItem>
                                                                <MenuItem value={"1150000"}>23</MenuItem>
                                                            </Select>
                                                            <FormHelperText> Cantidad estimada</FormHelperText>
                                                            
                                                            
                                                        </FormControl>
                                                    </span>
                                                </Typography>

                                                <Typography
                                                    className={classes.title}
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    <Checkbox
                                                        name="tiempo2"
                                                        checked={checkeds}
                                                        value="100000"
                                                        onChange={handleCheck2}
                                                        //inputProps={{ "aria-label": "primary checkbox" }}
                                                        color="primary"
                                                        ref={tipoInputRef}
                                                    />
                                                    <span style={{
                                                        padding: "1em 1em 0em 0em",
                                                        // color: "blue" 
                                                    }}><FormControl className={classes.formControl} style={{padding: "1em 0em 0em 0em"}}>
                                                        <Popup on='click' pinned trigger={
                                                            <InputLabel id="demo-simple-select-label" style={{
                                                        padding: "1em 0em 0em 0em",
                                                        //float:'right'
                                                        color: "blue" 
                                                         }}>DECISION COINCIDIR</InputLabel>}  position='bottom left' flowing >
                                                         <Grid centered divided columns={3}>
      <Grid.Column textAlign='center'>
        <Header as='h4'>Basic Plan</Header>
        <p>
          <b>2</b> projects, $10 a month
        </p>
        <EjemploDecision/>
       
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Header as='h4'>Business Plan</Header>
        <p>
          <b>5</b> projects, $20 a month
        </p>
        <EjemploDecision2/>
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Header as='h4'>Premium Plan</Header>
        <p>
          <b>8</b> projects, $25 a month
        </p>
        <Button>Choose</Button>
      </Grid.Column>
    </Grid>
  </Popup>

                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={age2}
                                                                onChange={handleSelect2}
                                                                inputProps={{ readOnly: read2 }}
                                                            >
                                                                <MenuItem value={"100000"}>1</MenuItem>
                                                                <MenuItem value={"200000"}>2</MenuItem>
                                                                <MenuItem value={"300000"}>3</MenuItem>
                                                                <MenuItem value={"400000"}>4</MenuItem>
                                                                <MenuItem value={"500000"}>5</MenuItem>
                                                                <MenuItem value={"600000"}>6</MenuItem>
                                                                <MenuItem value={"700000"}>7</MenuItem>
                                                                <MenuItem value={"800000"}>8</MenuItem>
                                                                <MenuItem value={"900000"}>9</MenuItem>
                                                                <MenuItem value={"1000000"}>10</MenuItem>
                                                                <MenuItem value={"1100000"}>11</MenuItem>
                                                                <MenuItem value={"1200000"}>12</MenuItem>
                                                            </Select>
                                                            <FormHelperText> Cantidad estimada</FormHelperText>
                                                        </FormControl>
                                                    </span>
                                                </Typography>

                                                <Typography
                                                    className={classes.title}
                                                    color="textSecondary"
                                                    gutterBottom
                                                >
                                                    <Checkbox
                                                        name="tiempo2"
                                                        checked={checked3}
                                                        value="1000000"
                                                        onChange={handleCheck3}
                                                        //inputProps={{ "aria-label": "primary checkbox" }}
                                                        color="primary"
                                                        ref={tipoInputRef}
                                                    />
                                                     <span style={{
                                                        padding: "0em 0em 0em 0em",
                                                        //float:'right'
                                                        // color: "blue" 
                                                    }}><FormControl className={classes.formControl} style={{padding: "1em 0em 0em 0em"}} >
                                                        <Popup content='Decisiones que el sistema toma a partir de la interpretación del lenguaje natural; 
                                                        el usuario ingresa una palabra o frase, y el sistema interpreta por medio de redes neuronales
                                                        . Luego de la interpretación, el sistma ejecuta una acción' trigger={
                                                            <InputLabel id="demo-simple-select-label" style={{
                                                        padding: "1em 0em 0em 0em",
                                                        //float:'right'
                                                        color: "blue" 
                                                         }}>LENGUAJE NATURAL</InputLabel >} inverted />
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                value={age3}
                                                                onChange={handleSelect3}
                                                                inputProps={{ readOnly: read3 }}
                                                               
                                                            >
                                                                <MenuItem value={"1000000"}>1</MenuItem>
                                                                <MenuItem value={"2000000"}>2</MenuItem>
                                                                <MenuItem value={"3000000"}>3</MenuItem>
                                                                <MenuItem value={"4000000"}>4</MenuItem>
                                                                <MenuItem value={"5000000"}>5</MenuItem>
                                                                <MenuItem value={"6000000"}>6</MenuItem>
                                                                <MenuItem value={"7000000"}>7</MenuItem>
                                                                <MenuItem value={"8000000"}>8</MenuItem>
                                                                <MenuItem value={"9000000"}>9</MenuItem>
                                                                <MenuItem value={"10000000"}>10</MenuItem>
                                                                <MenuItem value={"11000000"}>11</MenuItem>
                                                                <MenuItem value={"12000000"}>12</MenuItem>
                                                            </Select>
                                                            <FormHelperText> <strong>Cantidad estimada</strong></FormHelperText>
                                                        </FormControl>
                                                    </span>
                                                </Typography>

                                            </div>
                                        </Form>
                                        {error && (
                                            <div
                                                className="ui error message"
                                                style={{ marginBottom: 20 }}
                                            >
                                                <ul className="list">
                                                    <li>{error.graphQLErrors[0].message}</li>
                                                </ul>
                                            </div>
                                        )}
                                    </CardContent>

                                </Item.Description>
                                 <Item.Extra>
                                     <Popup basic on='click' pinned trigger={
                                         <p style={{
                                                        padding: "1em 0em 0em 0em",
                                                        //float:'right'
                                                        color: "blue" 
                                                         }} >Ver ejemplos de decisiones y condiciones</p>
                                                            }  position='bottom left' flowing >
                                                         <Grid centered divided columns={3}>
      <Grid.Column textAlign='center'>
        <Header as='h4'>Condicional</Header>
        
        <EjemploDecision/>
       
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Header as='h4'>Coincidir</Header>
        
        <EjemploDecision2/>
      </Grid.Column>
      <Grid.Column textAlign='center'>
        <Header as='h4'>Lenguaje natural</Header>
        
        <Button>Choose</Button>
      </Grid.Column>
    </Grid>
  </Popup>
                                  
                                </Item.Extra>
                                <Item.Extra>
                                    
                                    <Label>Costo (ars)</Label>
                                    <span style={{
                                        padding: "0em 1em 0em 0em",
                                        color: "blue"
                                    }}>{Number(tipo).toLocaleString('de-DE')}</span>
                                    <Label>Tiempo hs</Label>
                                    <span style={{
                                        padding: "0em 0em 0em 0em",
                                        //color: "green"
                                        color: 'orange'
                                    }}>{Number(tiempo).toLocaleString('de-DE')}</span>
                                </Item.Extra>
                                <Item.Extra>
                                    <Button primary floated='right'
                                        type="submit"
                                        className="ui button teal"
                                        disabled={
                                            titulo.trim() === "" || contenido.trim() === ""
                                        }
                                        onClick={submitComponente}
                                    >
                                        Agregar
                                                        <Icon name='right chevron' />
                                    </Button>
                                    
                                </Item.Extra>
                            </Item.Content>
                        </Item>





                    </Collapse>
                </div>
            </>
        );
    } else {
        postMarkup = (
            <>
                <Grid columns={1} style={styles.grid}>
                    <Grid.Row>
                        <Alert severity="warning">
                            No se ha completado el paso anterior
            </Alert>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
    return postMarkup;
}

const SUBMIT_COMPONENTE_MUTATION = gql`
  mutation(
    $postId: ID
    $titulo: String
    $contenido: String
    $tipo: String
    $tiempo: String
    $username: String
  ) {
    createComponente(
      postId: $postId
      titulo: $titulo
      contenido: $contenido
      tipo: $tipo
      tiempo: $tiempo
      username: $username
    ) {
      _id
      componentes {
        id
        titulo
        contenido
        tipo
        tiempo
        username
      }
    }
  }
`;

export default AddComponente;