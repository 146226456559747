import React, { useEffect, useState, useContext} from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button, Label, Icon, Input} from 'semantic-ui-react';
//import LikeButton from './LikeButton';
import { AuthContext } from '../context/auth';
import MyPopup from '../util/MyPopup';

//import Bayesv from '../util/gov'



function LikeButton2({ postIdLike, tile: { _id, likeCount,username, likes} }) {
  const [liked, setLiked] = useState(false);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    if (user && likes.find((like) => like.username === user.username)) {
      setLiked(true);
    } else setLiked(false);
  }, [user, likes]);
  
  const styles={
    grid:{
      height: "auto",
      width: "370px",
      margin: "0 auto",
   //   padding: "1em 1em 0em 0em"
    },
  
    grid2:{
      padding: "4em 4em 4em 4em"
    },
    
  }

 var tipoquery = LIKE_POST_MUTATION;
 
 
 
 
 const [likePost] = useMutation(tipoquery, {
   variables: { postId: _id, 
                userPost: username, 
                postIdLike : postIdLike }
  });

  const likeButton = user ? (
    liked ? (
      <Button disabled color="linkedin">
        <Icon disabled name="exchange" />
      </Button>
    ) : (
      <Button color="linkedin" basic onClick={onClick}>
        <Icon name="exchange" />
      </Button>
    )
  ) : (
    <Button as={Link} to="/login" color="linkedin" basic>
      <Icon name="exchange" />
    </Button>
  );

  function onClick (){
    likePost();
  };


  return (
    <>
    <Button as="div" labelPosition="right"  color="linkedin" >
      <MyPopup content={liked ? 'Dejar accion' : 'Etikar'}>{likeButton}</MyPopup>
      <Label basic color="blue" pointing='left'>
        Acciones {likeCount}
      </Label>
    </Button>
    </>
  );
}


const LIKE_POST_MUTATION = gql`
  mutation likePost($postId: ID!, $userPost: String!, $postIdLike: ID!) {
    likePost(postId: $postId, userPost: $userPost, postIdLike: $postIdLike) {
      _id
      likes {
        id
        username
        postIdLike
        postId
        userPost
      }
      likeCount
    }
  }
`;



export default LikeButton2;
