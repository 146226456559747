import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { useMutation } from '@apollo/react-hooks';
//import { UploadMutation } from './upload';
import { FileQuery } from './Uoloads';
import gql from 'graphql-tag';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

const UploadMutation2 = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      path
      id
      filename
      mimetype
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));



export default function WithPreviews(props) {
  const classes = useStyles();
  const [file, setFile] = useState({});
  const [uploadFile] = useMutation(UploadMutation2);


    const handleUpload = async () => {
      if(file){
          uploadFile({
            variables: { file },
            refetchQueries: [{query: FileQuery, variables: file}]
          });
          setFile({})
          console.log("Uploaded successfully: ", file)
      }
      else{
        console.log("No files to upload")
      }
    };

  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFile => {
    setFile(
      Object.assign(acceptedFile[0], {
        preview: URL.createObjectURL(acceptedFile[0]),
      })
    );
    console.log(URL);
    }
  });
  

  const thumbs = 
    <div className='thumb' key={file.name}>
      <div className='thumb-inner'>
        <img src={file.preview} className='img' alt={file.length && "img"} />
      </div>
    </div>

    useEffect(() => () => {
        URL.revokeObjectURL(file.preview)
    }, [file]);


  return (
    <section className='container'>
      <div {...getRootProps()}>
      <input  {...getInputProps()} />
      <label htmlFor="icon-button-file">
        <IconButton color="primary" aria-label="upload picture" component="span">
          <PhotoCamera />
        </IconButton>
      </label>
      </div>
      <aside className='thumb-container'>
        {thumbs}
        <button
          type='submit'
          className={`buttonImage`}
          style={{ display: file && !Object.keys(file).length && 'none' }}
          onClick={handleUpload}
        >
          Postea..!
        </button>
      </aside>
    </section>
  );
}

