import React, { useContext, useState } from "react";
import { AuthContext } from "../../../context/auth";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Button, Confirm, Icon,Popup } from "semantic-ui-react";

import { GET_USER_POSTS, CURRENT_POST2 } from "../../../util/graphql";
//import MyPopup from '../util/MyPopup';

function DeleteButton({ postId, componenteId, user, callback }) {
  //const { user } = useContext(AuthContext);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const mutation = componenteId
    ? DELETE_COMPONENTE_MUTATION
    : DELETE_POST_MUTATION;

  const [deletePostOrMutation] = useMutation(mutation, {
    update(proxy) {
      setConfirmOpen(false);
      if (!componenteId) {
        const data = proxy.readQuery({
          query: CURRENT_POST2,
          variables: {
            username: user
          }
        });
        data.userPostPrev = data.userPostPrev.filter(p => p._id !== postId);
        proxy.writeQuery({
          query: CURRENT_POST2,
          variables: {
            username: user
          },
          data
        });
      }
      if (callback) callback();
    },
    variables: {
      postId,
      componenteId,
      user
    }
  });
  return (
    <>
    <Popup content='Eliminar' 
    trigger={
    <Button
        content={componenteId ? "Borrar componente" : "Borrar Intencion"}
        as="div"
        color="red"
        floated="right"
        size="mini"
        onClick={deletePostOrMutation}
      >
        <Icon name="trash" style={{ margin: 0 }} />
      </Button>
    
    } inverted
    />
      

      <Confirm
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        content="Esta seguro?"
        size="small"
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deletePostOrMutation}
      />
    </>
  );
}

const DELETE_POST_MUTATION = gql`
  mutation deletePost($postId: ID!) {
    deletePost(postId: $postId)
  }
`;

const DELETE_COMPONENTE_MUTATION = gql`
  mutation deleteComponente($postId: ID!, $componenteId: ID!, $user: String!) {
    deleteComponente(
      postId: $postId
      componenteId: $componenteId
      user: $user
    ) {
      _id
      componentes {
        id
        titulo
        contenido
        tipo
        tiempo
        username
      }
    }
  }
`;

export default DeleteButton;
