import React, { useContext, useState } from "react";
import { Menu, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/auth";
import LogoFirst from "../util/LogoFirst.svg";
import { MenuItem } from "@material-ui/core";
import { layoutGenerator } from "react-break";

const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 992
});

const OnMobile = layout.is("mobile");
//const OnAtLeastTablet = layout.isAtLeast("tablet");
//const OnAtMostPhablet = layout.isAtMost("phablet");
const OnDesktop = layout.is("desktop");

const styles = {
  grid: {
    height: "100%",
    width: "100%",
    margin: "0 auto"
  },
  gridRoadmap: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    //backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    padding: "1.5em 0em 2em 0em"
  },
  gridMiniSegment: {
    width: "60%",
    backgroundColor: "#f2f2fc"
  },
  gridRoadmapDiv: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    // backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    padding: "2em 2em 2em 2em"
  },
  gridRoadmap2: {
    height: "100%",
    width: "100%",
    margin: "0 auto"
  },
  gridEmbeding: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    padding: "5em"
  },

  gridEmbedingMobile: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    padding: "0.5em"
  },
  logo: {
    height: "25%",
    width: "20%",
    margin: "1em -27em 0em 4em",
    float: "left ",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  },
  logoResponsive: {
    height: "50%",
    width: "50%",
    margin: "1em 1em 0em 1em",

    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc"
  },
  grid3: {
    height: "100%",
    width: "100%",
    margin: "0 auto"
  },
  box: {
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    textAlign: "center",
    marginBottom: "0em",
    padding: "1em"
  },
  box2: {
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    textAlign: "center",
    marginBottom: "0em",
    padding: "0em"
  },
  link: {
    backgroundColor: "grey"
  }
};

function MenuBar() {
  const { user, logout } = useContext(AuthContext);
  const pathname = window.location.pathname;
  const admin = "admin";
  const demo = "demo";
  const path = pathname === "/" ? "Inicio" : pathname.substr(1);
  const [activeItem, setActiveItem] = useState(path);

  const handleItemClick = (e, { name }) => setActiveItem(name);

  const menuBar = user ? (
    <Menu secondary>
      <OnDesktop>
        <Image src={LogoFirst} style={styles.logo} as={Link} to="/" />
      </OnDesktop>

      <OnMobile>
        <Image src={LogoFirst} style={styles.logoResponsive} as={Link} to="/" />
      </OnMobile>

      <Menu.Menu position="right">
        <Menu.Item
          name="Salir"
          active={activeItem === "Salir"}
          onClick={logout}
          to="/"
        />
        {user && user.categoria === admin && (
          <Menu.Item
            name="Pdc"
            active={activeItem === "Pdc"}
            onClick={handleItemClick}
            as={Link}
            to="/pdc"
          />
        )}
        {user && user.categoria === admin && (
          <Menu.Item
            name="Moderar"
            active={activeItem === "Moderar"}
            onClick={handleItemClick}
            as={Link}
            to="/moderar"
          />
        )}
        {user && user.categoria === admin && (
          <Menu.Item
            name="Reg"
            active={activeItem === "Reg"}
            onClick={handleItemClick}
            as={Link}
            to="/register"
          />
        )}
        {user && user.categoria === admin && (
          <Menu.Item
            name="Psic"
            active={activeItem === "Psic"}
            onClick={handleItemClick}
            as={Link}
            to="/psicoTrain"
          />
        )}

        {user && user.categoria === admin && (
          <Menu.Item
            name="Train"
            active={activeItem === "Train"}
            onClick={handleItemClick}
            as={Link}
            to="/postTrain"
          />
        )}

        {user && user.categoria === admin && (
          <Menu.Item
            name="Tu"
            active={activeItem === "Tu"}
            onClick={handleItemClick}
            as={Link}
            to="/neura"
          />
        )}
      </Menu.Menu>
    </Menu>
  ) : (
    <Menu secondary>
      <OnDesktop>
        <Image src={LogoFirst} style={styles.logo} as={Link} to="/" />
      </OnDesktop>

      <OnMobile>
        <Image src={LogoFirst} style={styles.logoResponsive} as={Link} to="/" />
      </OnMobile>
      <Menu.Menu position="right">
        <Menu.Item
          name="Ingresar"
          active={activeItem === "Ingresar"}
          onClick={handleItemClick}
          as={Link}
          to="/login"
        />
      </Menu.Menu>
    </Menu>
  );

  return menuBar;
}

export default MenuBar;
