import React from "react";
import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { layoutGenerator } from "react-break";
import imagen from "../../util/SmartC2.svg";
import responsive from "../../util/MobileSmart.svg";
const layout = layoutGenerator({
  mobile: 0,
  phablet: 550,
  tablet: 768,
  desktop: 992
});

const OnMobile = layout.is("mobile");
//const OnAtLeastTablet = layout.isAtLeast("tablet");
//const OnAtMostPhablet = layout.isAtMost("phablet");
const OnDesktop = layout.is("desktop");

var sectionStyle = {
  //width: "100%",
  //height: "600px",
  width: "100vw",
  height: "80vh",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundImage: url(${AsyncImageInicio}),
  //backgroundImage: `${Background}`,
  backgroundImage: `url(${imagen})`
};

var sectionMobile = {
  //width: "100%",
  //height: "600px",
  width: "100vw",
  height: "160vw",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  //backgroundImage: url(${AsyncImageInicio}),
  //backgroundImage: `${Background}`,
  backgroundImage: `url(${responsive})`
};

const AsyncImageInicioDesktop = props => {
  const [loadedSrc, setLoadedSrc] = React.useState(
    <Loader active size="massive" />
  );
  React.useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <>
        <div style={sectionStyle}>
          <Grid columns={3} style={props.styles.gridEmbeding}>
            <Grid.Column width={8}>
              <Segment vertical>
                <span className="container-segment-titulo">Smart Contract</span>

                <p>
                  Con los Smart Contract lleve su negocio a otro nivel. Los
                  Smart Contract sobre BlockChain permite a cualquier
                  organización escalar fácilmente hacia la automatización y
                  digitalización de cualquier proceso.
                </p>
                <span className="container-segment-titulo">Escalabilidad</span>

                <p>
                  Podrá implementar de a poco la digitalización de sus
                  operaciones; bastará agregar smart contracts como piezas de
                  lego para ir automatizando sus procesos. Olvídese de las
                  experiencias costosas e interminables del desarrollo de
                  sistemas tradicionales. No invierta más en costosas
                  infraestructuras, adopte Smart Contract BlockChain y ocupe su
                  tiempo en su principal activo,... Su negocio.
                </p>

                <Button
                  basic
                  color="blue"
                  floated="left"
                  onClick={props.handleClick}
                >
                  Contacto
                </Button>
              </Segment>
            </Grid.Column>

            <Grid.Column>
              <Segment vertical></Segment>
            </Grid.Column>
            <Grid.Column>
              <Segment vertical></Segment>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
  return <Loader active size="massive" />;
};

const AsyncImageInicioMobile = props => {
  const [loadedSrc, setLoadedSrc] = React.useState(
    <Loader active size="massive" />
  );
  React.useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
      };
    }
  }, [props.src]);
  if (loadedSrc === props.src) {
    return (
      <>
        <div style={sectionMobile}>
          <Grid style={props.styles.gridEmbedingMobile}>
            <Grid.Column width={16}>
              <Segment vertical>
                <span className="container-segment-titulo">Smart Contract</span>

                <p>
                  Con los Smart Contract lleve su negocio a otro nivel. Los
                  Smart Contract sobre BlockChain permite a cualquier
                  organización escalar fácilmente hacia la automatización y
                  digitalización de cualquier proceso.
                </p>
                <span className="container-segment-titulo">Escalabilidad</span>

                <p>
                  Podrá implementar de a poco la digitalización de sus
                  operaciones; bastará agregar smart contracts como piezas de
                  lego para ir automatizando sus procesos. Olvídese de las
                  experiencias costosas e interminables del desarrollo de
                  sistemas tradicionales. No invierta más en costosas
                  infraestructuras, adopte Smart Contract BlockChain y ocupe su
                  tiempo en su principal activo... Su negocio.
                </p>

                <Button
                  basic
                  color="blue"
                  floated="left"
                  onClick={props.handleClick}
                >
                  Contacto
                </Button>
              </Segment>
            </Grid.Column>
          </Grid>
        </div>
      </>
    );
  }
  return <Loader active size="massive" />;
};

export default ({
  styles,
  handleClick,
  handleSubmit,
  handleChange,
  onClick,
  args,
  errors
}) => {
  return (
    <>
      <OnDesktop>
        <AsyncImageInicioDesktop
          src={imagen}
          handleClick={handleClick}
          styles={styles}
        />
      </OnDesktop>
      <OnMobile>
        <AsyncImageInicioMobile
          src={responsive}
          handleClick={handleClick}
          styles={styles}
        />
      </OnMobile>
    </>
  );
};
