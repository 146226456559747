import React, { useContext, useEffect } from 'react';
//import { useQuery } from '@apollo/react-hooks';
//import { CURRENT_POST } from '../../util/graphql'
//import UploadPortada from './uploadPortada'
//import Imagenes from './uploadImagenes'
import Carrousel from '../tesauri/Carrousel';

import { makeStyles } from '@material-ui/core/styles';
//import Button from '@material-ui/core/Button';
//import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
//import Grid from '@material-ui/core/Grid';
import { Grid } from 'semantic-ui-react';
//import AddIcon from '@material-ui/icons/Add';
//import InfoAlert from './singleIntencion/infoAlert';
import AcordionInformacion from './Builder/Contrapartida/AcordionInformacionContra';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
//import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { AuthContext } from '../../context/auth';
import SuperButton from './SuperButton';

import Likebutton from '../LikeButton2'

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#a2afad',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: 'rgba(0, 0, 0, 0.60)',
    },
    camara: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: 'rgba(0, 0, 0, 0.90)',
    },
   
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  grid:{
    height: "auto",
    width: "360px",
    margin: "0 auto",
 //   padding: "1em 1em 0em 0em"
  },
}));

const styles={
    grid:{
      height: "auto",
      width: "370px",
      margin: "0 auto",
   //   padding: "1em 1em 0em 0em"
    },
  
    grid2:{
      padding: "2em 2em 0em 2em"
    },
    
  }

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ModalCardPropia({postIdLike, 
  tile:{_id, body,body2,createdAt,username,portada, imagenes,componentes}, tile}) {
  //const _id = postId;
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeProvider theme={theme}>
    <div >
    <IconButton  color="primary" onClick={handleClickOpen} >
    <InfoIcon />
      </IconButton>
    
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            
            {user  &&<Typography variant="h6" className={classes.title}>
                           {body}
                          </Typography>}
           
          </Toolbar>
        </AppBar>
        <List>
          <ListItem button>
            <ListItemText primary="Descripcion" secondary={body2} />
          </ListItem>
          <Divider /> 
        </List>
        <div>
         <Grid columns={1} style={styles.grid}>
         {user &&componentes && <AcordionInformacion componentes={componentes}/>}
         {user  && <Carrousel file={imagenes}/>} 
         <br/>
         <Grid columns={1} style={styles.grid2}>
         {user  && <Likebutton postIdLike={postIdLike}  tile={tile}/>}
         
         </Grid>
         
        
       
        
        </Grid>
        </div>
      </Dialog >
    </div>
    </ThemeProvider>
  );
}

export default ModalCardPropia;