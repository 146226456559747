import React, { useContext } from 'react';
import { Grid, Transition } from 'semantic-ui-react';
import { AuthContext } from '../../context/auth';
//import CardPostPropia from './CardPostPropia';
import BorradorCard from './BorradorCard';

const styles={
    grid:{
      height: "auto",
    // width: "367px",
      margin: "0 auto",
    },
    grid2:{
      minWidth: 355,
        padding: "1em 0em 0em 0em"
    },
  }

function UserInfo({postsp}) {
 
  const { user } = useContext(AuthContext);
 
  let postMarkup;
  
    postMarkup = (
      <div fluid = "true">
        {user && (  
        <Grid columns={1} >
             <Grid.Row >    
                     {<Transition.Group>
                        <Grid.Row >
                          {user && (<p className="registroUsuario" >Inteciones borradores</p> )}
                         </Grid.Row>
                         <Grid.Row>
                          {postsp &&
                             postsp.map((post) => (
                                <div key={post._id}style={styles.grid2}>
                                   {post.estadoPost ==='borrador' && (<BorradorCard user ={user} post={post}/>) }
                                </div>
                           ))} 
                        </Grid.Row>
                     </Transition.Group> }
        
                </Grid.Row>
        </Grid>    
        )}     
      </div>
     
    );
 
  return postMarkup;

}


export default UserInfo;