import React, { useContext ,useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Transition } from 'semantic-ui-react';
//import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/auth';
//import CardPostPropia from './CardPostPropia';
import Carrousel from '../tesauri/Carrousel';
import { CURRENT_POST } from '../../util/graphql'
//import UserInfoPublicados from './UserInfoPublicados';
import UploadPortada from './uploadPortada'
import Imagenes from './uploadImagenes'
import Componentes from './Builder/AddComponente'
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import PublicarButton from './Intencion/PublicarButton'
import BorradorButton from './Intencion/BorradorButton'
import DescartarButton from './Intencion/DescartarButton'
import Loading from './Loading'


const styles={
    grid:{
      height: "auto",
      width: "360px",
      margin: "0 auto",
    },
    grid2:{
       // width: 360,
        padding: "1em 0em 0em 0em"
    },
    grid3:{
      // width: 360,
       padding: "5em 0em 10em 2em"
   },
    paper:{
      width: 175,
      padding: "1em 0em 0em 0em",
     // display: 'flex',
     // flexWrap: 'wrap',
  },
    img: {
        height: 370,
        maxWidth: 500,
        //minWidth: 300,
       // overflow: 'hidden',
       // display: 'block',
        width: '100%',
      },
  }

function UserInfo(props) {
  const _id = props.match.params.postId3;
  const { user } = useContext(AuthContext);
 
    const queryPostInfo = useQuery(CURRENT_POST,{notifyOnNetworkStatusChange: true,variables: {
        _id
         
    }});
    
    const {
       data: { currentPost: post}
     } = queryPostInfo;
    
     useEffect(() => {
        queryPostInfo.startPolling(5000)
      return () => queryPostInfo.stopPolling();
    }, [queryPostInfo]);


  
  let postMarkup;
  if (!post) {
    postMarkup = <Loading/>;
  } else {
    //const {body,body2,createdAt,username,portada, imagenes} = currentPost;
  
    postMarkup = (
      <div style={styles.grid} fluid = "true">
        {user && (  
        <Grid columns={1} style={styles.grid}>
             <Grid.Column style={styles.grid2}>
                  <Componentes post = {post}/>
             </Grid.Column>
             <Grid.Column style={styles.paper}>
                 <Grid.Row alignItems="center" >
                   <Paper > <UploadPortada postId = {_id}/> </Paper>
                 </Grid.Row>
             </Grid.Column>
             <Grid.Column style={styles.paper}>
                 <Grid.Row alignItems="center" >
                   <Paper > <Imagenes postId = {_id}/></Paper>
                 </Grid.Row>
             </Grid.Column>
             

             <Grid.Row >    
                     {<Transition.Group style={styles.grid2}>
                         <Grid.Row >
                          {user && (<p className="registroUsuario" >Portada</p> )}
                         </Grid.Row>
                         <Grid.Column>
                          { post &&
                              <div >
                                  <img style={styles.img} src={post.portada.path} alt={'portada'} />
                              </div>
                            }
                            
                        </Grid.Column>
                        <Grid.Row >
                          {user && (<p className="registroUsuario" >Imagenes</p> )}
                         </Grid.Row>
                         <Grid.Column>
                          {post &&
                          <Carrousel file={post.imagenes}style={styles.grid}/>
                           } 
                        </Grid.Column>
                     </Transition.Group> }
                     {user && post && (<div style={styles.grid3}>
                       <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                         <PublicarButton _id={post._id} username={post.username}/>
                         <BorradorButton _id={post._id} username={post.username} />
                         <DescartarButton postId={post._id} username={post.username}/>  
                       </ButtonGroup>
                     </div>
                     )}    
                </Grid.Row>
              
        </Grid>    
        )}     
      </div>
     
    );
    }
  return postMarkup;

}


export default UserInfo;