import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../../context/auth';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { Button, Confirm, Icon } from 'semantic-ui-react';

//import { GET_USER_POSTS } from '../util/graphql';
import MyPopup from '../../../../util/MyPopup';
import CloseIcon from '@material-ui/icons/Close';
function DeleteButtonCommentM2({ commentId, callback }) {
  const matchId = "603a73b94971fc0560a4db5b";
  const { user } = useContext(AuthContext);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const mutation = commentId ? DELETE_COMMENT_MUTATION : DELETE_POST_MUTATION;

  
  

  const [deletePostOrMutation] = useMutation(mutation, {
    update(proxy) {
      setConfirmOpen(false);
      if (!commentId) {
        const data = proxy.readQuery({
          query: FETCH_MATCH_QUERY,variables: {
            matchId:matchId,
             
            }
        });
        data.getMatch = data.getMatch.filter((p) => p._id !== matchId);
        proxy.writeQuery({ query: FETCH_MATCH_QUERY, variables: {
          matchId:matchId,
          }, data });
      }
      if (callback) callback();
    },
    variables: {
      matchId:matchId,
      commentId,
    }
  });
  return (
    <>
      <MyPopup content={commentId ? 'Borrar comentario' : 'Borrar Seccion'}>
       
       <Button aria-label="close"color="red"size="mini" floated="right" onClick={() => setConfirmOpen(true)}>
         <CloseIcon fontSize="inherit" />
       </Button>


     </MyPopup>
       
      
      <Confirm
        cancelButton= 'Cancelar'
        confirmButton= 'Confirmar'
        content= 'Estas seguro/a de borrar el comentario...?'
        size= 'small'
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={deletePostOrMutation}
      /> 
    </>
  );
}

const FETCH_MATCH_QUERY = gql`
  query($matchId: ID!) {
    getMatch(matchId: $matchId) {
      _id
      username
      postIdLike
      userPost
      postId
      createdAt
      comments {
        id
        username
        createdAt
        body
        body2
        emocion
        trainNeura
        psico
      }
    }
  }
`;



const DELETE_POST_MUTATION = gql`
  mutation deletePost($postId: ID!) {
    deletePost(postId: $postId)
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation deleteCommentM2($matchId: ID!, $commentId: ID!) {
    deleteCommentM2(matchId: $matchId, commentId: $commentId) {
      _id
      comments {
        id
        username
        createdAt
        body
        body2
        emocion
        trainNeura
        psico
      }
    }
  }
`;

export default DeleteButtonCommentM2;