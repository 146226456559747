import React, { useContext, useEffect } from 'react';
import gql from 'graphql-tag';
//import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import {Card,Image} from 'semantic-ui-react';
import { AuthContext } from '../../../context/auth';

import DeleteButton from '../../DeleteButton';
import { CURRENT_POST } from '../../../util/graphql'
//import { FETCH_POSTS_CONTRA } from '../../../util/graphql'

//import Carrousel from '../../tesauri/Carrousel';
import InfoAlert from './infoAlert';
import SimpleAccordion from './AcordionImagenes';
import AcordionInformacion from './AcordionInformacion';

//import Container from '@material-ui/core/Container';

//import Cardintencion from '../../CardIntencion';
import ListaContrapartida from '../Builder/Contrapartida/ListaContrapartida';
import ListaMatch2 from '../Builder/Contrapartida/ListaMatch2';
import { getPortPromise } from 'portfinder';


const FETCH_POSTS_CONTRA = gql`
  query searchPost2($searchTerm: String!, $searchTerm1: String!, $body2: String!) {
    searchPost2(searchTerm: $searchTerm, searchTerm1: $searchTerm1, body2: $body2 ) {
      _id
      contrapartida
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes{
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada{
        _id
        filename
        path
        mimetype
      }
      imagenes{
        _id
        filename
        path
        mimetype
      }
      especifica{
        id
        tipo
        titulo
        contenido
      }
      componentes{
        id
        titulo
        contenido
        tipo
        username
      }
    }
  }
`;


const styles={
    grid:{
      height: "auto",
      width: "370px",
      margin: "0 auto",
      padding: "1em 0em 0em 0em"
    },
  
    grid2:{
        padding: "1em 1em 0em 0em"
    },
    
  }


function SinglePost(props) {
  const _id = props.match.params.postId2;
  const { user } = useContext(AuthContext);
  //const  user = props.location.user;
  //const searchTerm1 = props.location.body ;
  //const searchTerm = props.location.contrapartida ;
  
  const queryPostInfo = useQuery(CURRENT_POST,{notifyOnNetworkStatusChange: true,variables: {
    _id }});
  const {
         data: { currentPost: post}
        } = queryPostInfo;

  

        useEffect(() => {
          queryPostInfo.startPolling(7000);
          return () => queryPostInfo.stopPolling();
        }, [queryPostInfo]);
 

 const queryContra = useQuery(FETCH_POSTS_CONTRA,{ notifyOnNetworkStatusChange: true,variables: {
    searchTerm:props.location.contrapartida,
    searchTerm1:props.location.body,
    body2:props.location.body2
  } });
const {
  
  data: { searchPost2:postsContra }
} = queryContra;

useEffect(() => {
  queryContra.startPolling(13000);
  return () => queryContra.stopPolling();
}, [queryContra]);

  
  function deletePostCallback() {
    props.history.push('/');
  }


  let postMarkup;
  if (!post) {
    postMarkup = <p>Loading post..</p>;
  } else {
    const {_id,body,body2,createdAt,username,portada, imagenes, componentes,matchs} = post;

   



   
    postMarkup = (
      <>
      <div style={styles.grid} fluid = "true">
        
           {user &&  (
            <Card fluid >
            
              <Card.Content >
                <Card.Header >{ body}
                <Image centered
                  src={post.portada.path}
                  size="mini"
                  floated= " right "
                 />
                </Card.Header>
                <Card.Meta  >{moment.utc(createdAt).fromNow(true)}</Card.Meta>
                <Card.Description >{ body2}</Card.Description>
                <Card.Meta  >{username}</Card.Meta>
              </Card.Content>
              
              {post && <SimpleAccordion post={post}/>}
              {componentes && <AcordionInformacion componentes={componentes}/>}
              <hr />
              <Card.Content extra >
              
                {user && user.username === username && (
                  <DeleteButton postId={_id} callback={deletePostCallback} />
                )}
              </Card.Content>
              </Card>
               )}
               
               {post && postsContra && <ListaMatch2 matchData={matchs} post={post} postIdLike={_id} />}
               {post && postsContra && <ListaContrapartida tileData={postsContra} postIdLike={_id}/>}
               
     </div>
            
            
      
     </>
     
    );
  }
  return postMarkup;
}



const FETCH_POST_QUERY = gql`
  query($postId: ID!) {
    getPost(postId: $postId) {
      _id
      user
      body
      body2
      createdAt
      username
      likeCount
      commentCount
      comments {
        id
        username
        createdAt
        body
      }
    }
  }
`;



const FETCH_POSTS_QUERY = gql`
  {
    getPosts {
      _id
      user
      body
      body2
      createdAt
      username
      likeCount
      likes {
        id
        username
        postIdLike
        userPost
      }
      commentCount
      comments {
        id
        username
        createdAt
        body
      }
    }
  }
`;


const FETCH_MATCH_QUERY = gql`
  {
    getMatchs {
      id
      username
      postIdLike
      userPost
      postId
      createdAt
      comments {
        id
        username
        createdAt
        body
      }
    }
  }
`;

const FETCH_PSICO_QUERY = gql`
  {
    getPsicoPosts {
      id
      user
      descripcion
      in_descripcion
      explicacion
      in_explicacion
      createdAt
      username
    }
  }
`;

export default SinglePost;