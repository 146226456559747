import React, { useContext, useEffect, useState } from 'react';
import gql from 'graphql-tag';
//import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { AuthContext } from '../../context/auth';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import PiechartCompleto from './componentes/PiechartCompleto';
const styles={
    grid:{
      height: "auto",
      width: "900px",
      margin: "0 auto",
      padding: "2em 0em 0em 5em"
    },
  
    grid2:{
        padding: "1em 1em 0em 0em"
    },
    
  }

 

function CharComments(props) {
  const { user } = useContext(AuthContext);
  
  
  const queryMatch2 = useQuery(QUERY_PARTIDO,{ notifyOnNetworkStatusChange: true});

  /*
  const {
    data: { getCommentsM2:partido }
  } = queryComments;
  useEffect(() => {
    queryComments.startPolling(4000);
    return () => queryComments.stopPolling();
  }, [queryComments]);

  const queryMatch = useQuery(FETCH_MATCH_QUERY,{ notifyOnNetworkStatusChange: true,
    variables: {
      matchId 
    } 
  });
*/
  const {
    data: { getCommentsM2:partido }
  } = queryMatch2;
  useEffect(() => {
    queryMatch2.startPolling(2000);
    return () => queryMatch2.stopPolling();
  }, [queryMatch2]);
 
  let postMarkup;
  if (!partido) {
    postMarkup = <p>Loading post..</p>;
  } else {
   // const {_id,body,body2,createdAt,username,commentCount} = getPost;

   

    const ene = [ 
                 parseInt([partido[0]/partido[10]*100]).toFixed(0) +"%", 
                 parseInt([partido[1]/partido[10]*100]).toFixed(0) +"%",
                 
                ];
    
     const legendCantidad = [ 
       
                    "Positivo: "+partido[0], 
                    "Negativo: "+partido[1],
                   
                   ];
    const link1 = <Link to={{pathname: `/home`}}>{partido[0]}</Link> 
    //const html = `<Link to={{pathname: `/home`}}>{partido[0]}</Link> `;

    postMarkup = (
        <>
      <Grid columns={1} style={styles.grid}>
    
    <Grid container alignItems="center" justify="center">
    <PiechartCompleto 
             partido={partido[0]} 
             partido1={partido[1]} 
             partido10={partido[10]} 
             eneatipo={ene[0]}
             eneatipo1={ene[1]}
            
             legend={legendCantidad[0]}
             legend1={legendCantidad[1]}
             
             
             />
    </Grid>
    
  </Grid> 
     
     
     
     </>
    );
  }
  return postMarkup;
}



const QUERY_PARTIDO = gql`
{
  getCommentsM2(matchId:"603a73b94971fc0560a4db5b")
}
`;




export default CharComments;