import React, { useContext, useState, useRef, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
//import moment from 'moment';
import { Form } from "semantic-ui-react";
//import CurrentPostprev from "./CurrentPostPrev";
//import { AuthContext } from "../../context/auth";
import { CURRENT_POST2 } from "../../util/graphql";
//import "../../App.css";
//import Bayesv from "../../util/gov";
//import Bayes from "../../util/go";
//import Loading from './Loading'
import { Grid, Segment, Loader } from "semantic-ui-react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Alert from "@material-ui/lab/Alert";
import DescartarButtonAlert from "./Intencion/DescartarButtonAlert";
//import storage from "./storaje";

const styles = {
  grid: {
    height: "auto",
    width: "360px",
    margin: "0 auto",
    float: "left "
  },
  grid2: {
    // width: 360,
    padding: "3em 0em 10em 17em"
  },
  gridRoadmap: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    padding: "1.5em 0em 2em 0em"
  },
  gridMiniSegment: {
    width: "60%",
    backgroundColor: "#f2f2fc"
  },
  gridRoadmapDiv: {
    height: "100%",
    width: "100%",
    margin: "0 auto",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    padding: "2em 2em 2em 2em"
  },
  gridRoadmap2: {
    height: "100%",
    width: "100%",
    margin: "0 auto"
  }
};

const INTENCION_TRAIN_QUERY = gql`
  {
    getTrainPosts {
      id
      user
      in_Intencion
      out_Accion
      in_Contrapartida
      out_Contrapartida
      createdAt
      username
    }
  }
`;

function AddComponente({ handleNext, post, handleUser, presupuesto }) {
  //const { user } = useContext(AuthContext);
  const { tituloInputRef } = useRef(null);
  const [body, setTitulo] = useState(" ");
  localStorage.setItem("userVacio", " ");
  const userSecundario = localStorage.getItem("userLocal");
  const primerUsuario = localStorage.getItem("userVacio");
  let usuario = () => {
    if (!userSecundario) {
      usuario = primerUsuario;
    } else {
      usuario = userSecundario;
    }
    return usuario;
  };

  const user = usuario();

  const queryPostInfo = useQuery(CURRENT_POST2, {
    notifyOnNetworkStatusChange: true,
    variables: {
      username: user
    }
  });
  const {
    data: { userPostPrev: postc }
  } = queryPostInfo;

  useEffect(() => {
    queryPostInfo.startPolling(500);
    return () => queryPostInfo.stopPolling();
  }, [queryPostInfo]);

  var contra = "nada";
  var body2 = "nada";

  //////////////Fin bayes////////////////////////////////

  const [submitComponente, { error }] = useMutation(CREATE_POST_MUTATION, {
    update() {
      setTitulo("");
      localStorage.setItem("userLocal", body);
      handleNext();
      tituloInputRef.current.blur();
    },
    variables: {
      body: body,
      body2,
      contra
    }
  });

  let postMarkup;
  if (!postc) {
    postMarkup = (
      <div>
        {user && (
          <Form>
            <div>
              <Form.Field
                style={{ width: "290px" }}
                size="small"
                control="input"
                autoFocus={false}
                type="text"
                maxlength="45"
                minlength="10"
                placeholder="Ingrese email"
                name="body"
                value={body}
                error={error ? true : false}
                onChange={event => setTitulo(event.target.value)}
                ref={tituloInputRef}
              />

              <button
                type="submit"
                className="ui button teal"
                disabled={body.trim() === "" || body.length < 10}
                onClick={submitComponente}
              >
                Siguiente
              </button>
            </div>
          </Form>
        )}
        {error && (
          <div className="ui error message" style={{ marginBottom: 20 }}>
            <ul className="list">
              <li>{error.graphQLErrors[0].message}</li>
            </ul>
          </div>
        )}
      </div>
    );
  } else {
    postMarkup = (
      <>
        {user && postc && (
          <Grid columns={1} style={styles.grid}>
            <Grid.Row>
              <Alert severity="warning">
                La cotización para --> {postc.username}... esta pendiente!
              </Alert>
              <ButtonGroup variant="contained" color="primary">
                <DescartarButtonAlert
                  postId={postc._id}
                  username={postc.username}
                />
                <Button onClick={handleNext} color="inherit" size="small">
                  Revisar
                </Button>
              </ButtonGroup>
            </Grid.Row>
          </Grid>
        )}
      </>
    );
  }
  return postMarkup;
}

const CREATE_POST_MUTATION = gql`
  mutation createPost($body: String, $body2: String!, $contra: String) {
    createPost(body: $body, body2: $body2, contra: $contra) {
      _id
      contrapartida
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes {
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada {
        _id
        filename
        path
        mimetype
      }
      imagenes {
        _id
        filename
        path
        mimetype
      }
      especifica {
        id
        tipo
        titulo
        contenido
      }
      componentes {
        id
        tipo
        titulo
        contenido
      }
    }
  }
`;

export default AddComponente;
