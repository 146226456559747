import React, { useContext, useState, useRef, useEffect } from "react";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/react-hooks";
import moment from "moment";
//import { Button, Grid, Loader, Segment } from "semantic-ui-react";
import { Form, Loader, Button, Icon, Image, Item, Label, Grid, Segment, Statistic, Header, Divider  } from "semantic-ui-react";

//import { AuthContext } from "../../../context/auth";
//import LikeButton from '../components/LikeButton';
//import DeleteButton from "../Builder/DeleteButtonComponente";
//import MyPopup from '../util/MyPopup';
import "../../../App.css";

import { makeStyles } from "@material-ui/core/styles";
//import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { CURRENT_POST2 } from "../../../util/graphql";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Alert from "@material-ui/lab/Alert";
import Info from "../../../pages/login/steps/Variables/DialogInfoGenWallet.js";
import Terminos from "../../../pages/login/steps/Variables/DialogTerminos.js";
import Info60k from "./Cantidad60kW";
import Info90k from "./Cantidad90kW";
import Collapse from "./ColapseItem";
import ImagenCustodia from "../../../util/GenYcustodia.svg";



const useStyles = makeStyles(theme => ({
    root: {
        // minWidth: 350,
        // maxWidth: 355,

        width: 340,
        margin: theme.spacing(0.3)
    },
    bullet: {
        display: "inline-block",
        margin: "1 2px",
        transform: "scale(0.8)"
    },
    title: {
        fontSize: 14
    },
    pos: {
        marginBottom: 12
    }
}));

const styles = {
    grid: {
        height: "auto",
        //width: 400,
        //maxWidth: 360,
        margin: "0 auto",
        padding: "3em 0em 0em 0em"
    },
    grid2: {
        // width: 360,
        padding: "3em 0em 10em 17em"
    },
    img: {
        height: 370,
        maxWidth: 500,
        //minWidth: 300,
        // overflow: 'hidden',
        // display: 'block',
        width: "100%"
    }
};

const useStyles2 = makeStyles({
    root2: {
        height: 50,
        flexGrow: 1,
        maxWidth: 700,
    },
});


function AddComponente() {
    //const postId = props.match.params.postId;
    //const { user } = useContext(AuthContext);
    localStorage.setItem("userVacio", " ");
    const userSecundario = localStorage.getItem("userLocal");
    const primerUsuario = localStorage.getItem("userVacio");
    let usuario = () => {
        if (!userSecundario) {
            usuario = primerUsuario;
        } else {
            usuario = userSecundario;
        }
        return usuario;
    };
    const classes2 = useStyles2();
    const user = usuario();

    const queryPostInfo = useQuery(CURRENT_POST2, {
        notifyOnNetworkStatusChange: true,
        variables: {
            username: user
        }
    });
    const {
        data: { userPostPrev: postc }
    } = queryPostInfo;

    useEffect(() => {
        queryPostInfo.startPolling(1500);
        return () => queryPostInfo.stopPolling();
    }, [queryPostInfo]);

    const {
        tituloInputRef,
        contenidoInputRef,
        tipoInputRef,
        tiempoInputRef
    } = useRef(null);

    const [titulo, setTitulo] = useState("");
    const [contenido, setContenido] = useState("");
    const [wallets, setWallets] = useState("");
    const [tipo, setTipo] = useState("");
    const [tiempo, setTiempo] = useState("");
    const [age, setAge] = React.useState("0");
    const [age2, setAge2] = React.useState("0");
    const [read, setRead] = React.useState(true);
    const [read2, setRead2] = React.useState(true);
    const [checked, setChecked] = React.useState(false);
    const [checkeds, setCheckeds] = React.useState(false);

    let post = () => {
        if (!postc) {
            post = " ";
        } else {
            post = postc._id;
        }
        return post;
    };

const precioWallet= "3";
let multi = (a,b)=>{
  var  result = a*b;
 return result;
}

    let price = (cantidad) => {
        if (cantidad === "50000") {
           return price = multi(cantidad,precioWallet).toString();
        } else if (cantidad === "100000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "150000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "200000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "250000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "300000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "350000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "400000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "450000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "500000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "550000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "600000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "650000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "700000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "750000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "800000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "850000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "900000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "950000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1000000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1050000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1100000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1150000"){
           return price = multi(cantidad,precioWallet).toString();
        }else if (cantidad === "1200000"){
           return price = multi(cantidad,precioWallet).toString();
        }else
        return price === "0";
    };

///////////////////////////HORAS //////////////////////////////////

const ponderacionHora= "0.00024";
let multihora = (a,b)=>{
  var  result = a*b;
 return result;
}

    let horas = (cantidad) => {
        if (cantidad === "50000") {
           return horas = multihora(cantidad,ponderacionHora *3).toString();
        } else if (cantidad === "100000"){
           return horas = multihora(cantidad,ponderacionHora *2).toString();
        }else if (cantidad === "150000"){
           return horas = multihora(cantidad,ponderacionHora *1.5).toString();
        }else if (cantidad === "200000"){
           return horas = multihora(cantidad,ponderacionHora *1.2).toString();
        }else if (cantidad === "250000"){
           return horas = multihora(cantidad,ponderacionHora *1.2).toString();
        }else if (cantidad === "300000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "350000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "400000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "450000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "500000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "550000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "600000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "650000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "700000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "750000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "800000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "850000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "900000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "950000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1000000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1050000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1100000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1150000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else if (cantidad === "1200000"){
           return horas = multihora(cantidad,ponderacionHora).toString();
        }else
        return horas === "0";
    };







    const idpost = post();


    const handleSelect = (event) => {
    setAge(event.target.value);
    setTipo(price(event.target.value));
    setWallets(event.target.value);
    setTiempo(horas(event.target.value));
    setContenido(Number(event.target.value).toLocaleString('de-DE') +" Wallets");
     };

    const handleSelect2 = (event) => {
    setAge2(event.target.value);
    setTipo(price(event.target.value));
    setWallets(event.target.value);
    setTiempo(horas(event.target.value));
    setContenido(Number(event.target.value).toLocaleString('de-DE') +" Wallets");
     };

    const handleCheck = event => {
        if (checkeds === true || checkeds === false && checked === false){
            setCheckeds(false);
            setChecked(event.target.checked);
           // setTiempo(event.target.value);
            setTiempo(horas(event.target.value));
            setTipo("150000");
            setTitulo("Generación y custodia de wallets ERC20");
            setContenido("50.000 Wallets");
            setAge("50000");
            setAge2("0");
            setRead(false);
            setRead2(true);
            setWallets("50000");
            
        } else {
            setChecked(event.target.checked);
            setTiempo("");
            setTipo("");
            setTitulo("");
            setContenido("");
            setAge("0");
            setAge2("0");
            setRead(true);
            setRead2(true);
            setWallets("");
            
        }
    };

    const handleCheck2 = event => {
        if (checked === true || checked === false && checkeds === false ) {
            setChecked(false);
            setCheckeds(event.target.checked);
            //setTiempo(event.target.value);
            setTiempo(horas(event.target.value));
            setTipo("250000");
            setTitulo("Generación y custodia de wallets ERC20");
            setContenido("100.000 Wallets");
            setAge2("100000");
            setAge("0");
            setRead2(false);
            setRead(true);
            setWallets("100000");
            
        } else {
            setCheckeds(event.target.checked);
            setTiempo("");
            setTipo("");
            setTitulo("");
            setContenido("");
            setAge("0");
            setAge2("0");
            setRead(true);
            setRead2(true);
            setWallets("");
            
            
        }
    };

    const [submitComponente, { error }] = useMutation(
        SUBMIT_COMPONENTE_MUTATION,
        {
            update() {
                setTitulo("");
                setContenido("");
                setTipo("");
                setTiempo("");
                setCheckeds(false);
                setChecked(false);
                setAge("0");
                setAge2("0");
                setRead(true);
                setRead2(true);
                setWallets("");
                tituloInputRef.current.blur();
                contenidoInputRef.current.blur();
                tipoInputRef.current.blur();
                tiempoInputRef.current.blur();
            },
            variables: {
                postId: idpost,
                titulo: titulo,
                contenido: contenido,
                tipo: tipo,
                tiempo: tiempo,
                username: user
            }
        }
    );

    const classes = useStyles();
    
    
    let postMarkup;
    if (postc) {
        const {
            _id,
            body,
            body2,
            createdAt,
            username,
            especifica,
            componentes
        } = postc;
       
        const subtotal = especifica[0];
        const total = subtotal.contenido;
        const totalHs = subtotal.tipo;
    
        postMarkup = (
            <>
                <Grid stackable columns={2}>
                    <Grid.Column>
                        <Segment>
                            {user && (
                                <div>
                                    <Item.Group divided>
                                    
                                        <Item>
                                            <Item.Image src={ImagenCustodia} />
                                            <Item.Content>
                                                <Item.Header as='a'> Generacion y custodia de Wallets ERC20 </Item.Header><Info />
                                                <Item.Meta>
                                                    <span className='cinema'>Terminos y condiciones</span><Terminos />
                                                </Item.Meta>
                                                <Item.Description>
                                                    
                                                    <CardContent>
                                                        <p>Cantidad de wallets a custodiar</p><span style={{
                                                        padding: "0em 1em 0em 0em",
                                                        color: "blue" 
                                                    }}>{Number(wallets).toLocaleString('de-DE')}</span>
                                                        <Form>
                                                            <div>
                                                                <Typography
                                                                    className={classes.title}
                                                                    color="textSecondary"
                                                                    gutterBottom
                                                                >
                                                                    <Checkbox
                                                                        name="tiempo"
                                                                        checked={checked}
                                                                        value="50000"
                                                                        onChange={handleCheck}
                                                                        //inputProps={{ "aria-label": "primary checkbox" }}
                                                                        color="primary"
                                                                        ref={tiempoInputRef}
                                                                    />
                                                                    50.000 <span style={{
                                                                                         padding: "0em 1em 0em 1em",
                                                                                        // color: "blue" 
                                                                               }}><FormControl className={classes.formControl}>
                                                                                  <InputLabel id="demo-simple-select-label">Unidades</InputLabel>
                                                                                  <Select
                                                                                     labelId="demo-simple-select-label"
                                                                                     id="demo-simple-select"
                                                                                     value={age}
                                                                                     onChange={handleSelect}
                                                                                     inputProps={{ readOnly: read }}
                                                                                  >
                                                                                   <MenuItem value={"50000"}>1</MenuItem>
                                                                                   <MenuItem value={"150000"}>3</MenuItem>
                                                                                   <MenuItem value={"250000"}>5</MenuItem>
                                                                                   <MenuItem value={"350000"}>7</MenuItem>
                                                                                   <MenuItem value={"450000"}>9</MenuItem>
                                                                                   <MenuItem value={"550000"}>11</MenuItem>
                                                                                   <MenuItem value={"650000"}>13</MenuItem>
                                                                                   <MenuItem value={"750000"}>15</MenuItem>
                                                                                   <MenuItem value={"850000"}>17</MenuItem>
                                                                                   <MenuItem value={"950000"}>19</MenuItem>
                                                                                   <MenuItem value={"1050000"}>21</MenuItem>
                                                                                   <MenuItem value={"1150000"}>23</MenuItem>
                                                                                  </Select>
                                                                                 </FormControl> 
                                                                          </span>
                                                                </Typography>

                                                                <Typography
                                                                    className={classes.title}
                                                                    color="textSecondary"
                                                                    gutterBottom
                                                                >
                                                                    <Checkbox
                                                                        name="tiempo2"
                                                                        checked={checkeds}
                                                                        value="100000"
                                                                        onChange={handleCheck2}
                                                                        //inputProps={{ "aria-label": "primary checkbox" }}
                                                                        color="primary"
                                                                        ref={tipoInputRef}
                                                                    />
                                                                    100.000 <span style={{
                                                                                         padding: "0em 1em 0em 1em",
                                                                                        // color: "blue" 
                                                                               }}><FormControl className={classes.formControl}>
                                                                                  <InputLabel id="demo-simple-select-label">Unidades</InputLabel>
                                                                                  <Select
                                                                                     labelId="demo-simple-select-label"
                                                                                     id="demo-simple-select"
                                                                                     value={age2}
                                                                                     onChange={handleSelect2}
                                                                                     inputProps={{ readOnly: read2 }}
                                                                                  >
                                                                                   <MenuItem value={"100000"}>1</MenuItem>
                                                                                   <MenuItem value={"200000"}>2</MenuItem>
                                                                                   <MenuItem value={"300000"}>3</MenuItem>
                                                                                   <MenuItem value={"400000"}>4</MenuItem>
                                                                                   <MenuItem value={"500000"}>5</MenuItem>
                                                                                   <MenuItem value={"600000"}>6</MenuItem>
                                                                                   <MenuItem value={"700000"}>7</MenuItem>
                                                                                   <MenuItem value={"800000"}>8</MenuItem>
                                                                                   <MenuItem value={"900000"}>9</MenuItem>
                                                                                   <MenuItem value={"1000000"}>10</MenuItem>
                                                                                   <MenuItem value={"1100000"}>11</MenuItem>
                                                                                   <MenuItem value={"1200000"}>12</MenuItem>
                                                                                  </Select>
                                                                                 </FormControl> 
                                                                          </span>
                                                               </Typography>

                                                            </div>
                                                        </Form>
                                                        {error && (
                                                            <div
                                                                className="ui error message"
                                                                style={{ marginBottom: 20 }}
                                                            >
                                                                <ul className="list">
                                                                    <li>{error.graphQLErrors[0].message}</li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </CardContent>
                                                
                                                </Item.Description>
                                                <Item.Extra>
                                                    <Button primary floated='right'
                                                        type="submit"
                                                        className="ui button teal"
                                                        disabled={
                                                            titulo.trim() === "" || contenido.trim() === ""
                                                        }
                                                        onClick={submitComponente}
                                                    >
                                                        Agregar
                                                        <Icon name='right chevron' />
                                                    </Button>
                                                    <Label>Costo (ars)</Label> 
                                                    <span style={{
                                                        padding: "0em 1em 0em 0em",
                                                        color: "blue" 
                                                    }}>{Number(tipo).toLocaleString('de-DE')}</span>  
                                                    <Label>Tiempo hs</Label>
                                                    <span style={{
                                                        padding: "0em 0em 0em 0em",
                                                        //color: "green"
                                                        color:'orange'
                                                    }}>{Number(tiempo).toLocaleString('de-DE')}</span> 
                                                </Item.Extra>
                                            </Item.Content>
                                        </Item>

                                        
                                    </Item.Group>
                                    
                                </div>
                            )}
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        <Segment>
                            <div>
                                <CardContent>
                                    <p>
                                        Roadmap para:
                                       <Typography
                                            className={classes.title}
                                            color="textSecondary"
                                            gutterBottom
                                        >
                                            <span style={{
                                                padding: "0em 1em 0em 0em",
                                                color: "blue"
                                            }}>{username}</span>
                                            
                                            <Statistic color='orange' floated='right' size='small'>
                                                <Statistic.Value>{totalHs}</Statistic.Value>
                                                <Statistic.Label>Horas acumuladas</Statistic.Label>
                                            </Statistic>
                                            <Statistic floated='right' color='blue' size='small'>
                                                <Statistic.Value>{Number(total).toLocaleString('de-DE')}</Statistic.Value>
                                                <Statistic.Label>Costo acumulado (ars)</Statistic.Label>
                                            </Statistic>
                                            
                                        </Typography>{" "}
                                    </p>

                                    <Typography className={classes.pos} color="textSecondary">
                                        Roadmap iniciado: {moment.utc(createdAt).fromNow(true)}
                                    </Typography>
                                </CardContent>
                                
                                <Divider horizontal>
                                    <Header as='h5' color='grey'>
                                        <Icon name='bar chart' />
                                        Especificaciones
                                    </Header>
                                </Divider>
                                
                            </div>
                            {componentes.map(info => (
                                <>
                                    <Collapse key={info.id} info={info} postId={_id} user={user}/>
                                
                                </>
                            ))}
                        </Segment>
                    </Grid.Column>
                </Grid>
            </>
        );
    } else {
        postMarkup = (
            <>
                <Grid columns={1} style={styles.grid}>
                    <Grid.Row>
                        <Alert severity="warning">
                            No se ha completado el paso anterior
            </Alert>
                    </Grid.Row>
                </Grid>
            </>
        );
    }
    return postMarkup;
}

const SUBMIT_COMPONENTE_MUTATION = gql`
  mutation(
    $postId: ID
    $titulo: String
    $contenido: String
    $tipo: String
    $tiempo: String
    $username: String
  ) {
    createComponente(
      postId: $postId
      titulo: $titulo
      contenido: $contenido
      tipo: $tipo
      tiempo: $tiempo
      username: $username
    ) {
      _id
      componentes {
        id
        titulo
        contenido
        tipo
        tiempo
        username
      }
    }
  }
`;

export default AddComponente;