import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';

import { AuthContext} from '../../context/auth';
import { Link } from 'react-router-dom';
import MenuMiniSingleCard from './MenuMiniSingleCard';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: 355,
   margin: 'auto',
  },
  details: {
    display: 'flex',
    minWidth: 204,
    maxWidth: 204,
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    minwidth: 151,
    minWidth: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));

export default function MediaControlCard({
    post: { body,body2, createdAt, _id, username, likeCount, commentCount, likes, portada },
    
  }) {
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root} >
        <MenuMiniSingleCard postId={_id} username={username} />
      <div className={classes.details}>
      <Link user={user} to={`/post/${_id}`}>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="h6">
          {body}
          </Typography>
        </CardContent>
        </Link>
        
      </div>
      <CardMedia
        className={classes.cover}
        image={portada.path}
        title="Live from space album cover"
      />
    </Card>
  );
}
