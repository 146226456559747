import React, { useContext ,useEffect }  from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
//import Button from '@material-ui/core/Button';
//import Typography from '@material-ui/core/Typography';
import CurrentPostprev from '../CurrentPostPrev';
import AddIntencion from'../AddIntencion2';
import { Grid} from 'semantic-ui-react';
import { AuthContext } from '../../../context/auth';
import { CURRENT_POST2 } from '../../../util/graphql';
import { useQuery } from '@apollo/react-hooks';
//import ActionButtons from'./ActionsButtons';
import gql from 'graphql-tag';


const styles={
    grid:{
      height: "auto",
      width: "360px",
      margin: "0 auto",
    },
    grid2:{
       // width: 360,
        padding: "3em 0em 10em 17em"
    },
    img: {
        height: 360,
        maxWidth: 500,
        //minWidth: 300,
       // overflow: 'hidden',
       // display: 'block',
        width: '100%',
      },
  }
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(5),
    marginRight: theme.spacing(11)
  },
}));

function getSteps() {
  return ['Crear Intencion', 'Agrega Informacion'];
}

function getStepContent(stepIndex, handleNext) {
  switch (stepIndex) {
    case 0:
      return <AddIntencion handleNext={handleNext} />;
    
    default:
      return 'Unknown stepIndex';
  }
}

export default function StepForm({post}) {
  
  const { user } = useContext(AuthContext);
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  


/*
  const queryPostInfo = useQuery(CURRENT_POST2,{notifyOnNetworkStatusChange: true,variables: {
    username:user.username
     
}});

const {
   data: { userPostPrev: post}
 } = queryPostInfo;

 
 useEffect(() => {
    queryPostInfo.startPolling(500)
  return () => queryPostInfo.stopPolling();
}, [queryPostInfo]);

*/

 




  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    
    
     <Grid columns={1} style={styles.grid}>
      <Stepper activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel >{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Grid.Column style={styles.grid} >
        {activeStep === steps.length -1 ? ( 
          <Grid.Row > 
               <CurrentPostprev post={post}/>
          </Grid.Row> 
        ) : (
          <>
            
            <Grid.Row>
              <AddIntencion handleNext={handleNext} post={post} /> 
            </Grid.Row>
          </>
        )}
      </Grid.Column>
      </Grid> 
    
  );
}