import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Slide from "@material-ui/core/Slide";
import { Alert, AlertTitle } from "@material-ui/lab";
import Paquete1 from "../Paquete1";
import LogoFirst from "../../../../util/LogoFirst.svg";
import { Image, Popup, Icon, Message, Grid } from "semantic-ui-react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
    //maxWidth: '36ch',
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  grid: {
    width: "96%"
    // maxWidth: "300px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid2: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  minigrid3: {
    //width: "96%"
    maxWidth: "400px",
    height: "340px"
    //alignContent:'center'
    //backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    position: "relative"
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: "#4f8bc6"
  },
  paquete1: {
    margin: "0em 0em 0em 1em",
    backgroundColor: "#f2f2fc"
    //color: "#4f8bc6"
    // float: "left ",
  },
  barra: {
    backgroundColor: "#f2f2fc"
    // border: "1px solid #f2f2fc"
  },
  boton: {
    color: "#4f8bc6"
    //backgroundColor: "#f2f2fc",
    // border: "1px solid #f2f2fc"
  },
  logo: {
    height: "80%",
    width: "17%",
    margin: "0em 0em 0em 4em",
    float: "right",
    backgroundColor: "#f2f2fc",
    border: "1px solid #f2f2fc",
    cursor: "pointer"
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Popup
        trigger={
          <Icon
            color="blue"
            link
            name="tag"
            content="Open Portal"
            disabled={open}
            positive
            //onClick={() => dispatch({ type: "OPEN_MODAL", dimmer: "inverted" })}
            onClick={handleClickOpen}
            style={{
              padding: "0em 0em 0em 1em"
            }}
          />
        }
        content="Información..."
        inverted
      />

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.barra}>
            <Popup
              trigger={
                <IconButton
                  edge="start"
                  color="#4f8bc6"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              }
              content="cerrar info"
              inverted
            />

            <Typography variant="h6" className={classes.title}>
              Diseño y Desarrollo Wallet cripto
            </Typography>
            <Image src={LogoFirst} className={classes.logo} />
          </Toolbar>
        </AppBar>

        <List className={classes.root}>
          <Alert severity="info">
            <AlertTitle>Info</AlertTitle>
            <ListItem alignItems="center">
              <Message info size="big" className={classes.grid}>
                El desarrollo de una wallet cripto esta definido en FirstCripto
                como una aplicaición con las capacidades básicas de una cuenta
                transaccional digital que permite operar transacciones en la
                blockchain de Ethereum.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message success size="big" className={classes.grid}>
                El desarrollo de Wallet cripto,{" "}
                <strong>va dirigido a empresas </strong>
                que cuentan con servicios transaccionales convencionales y
                quieren dar el paso hacia blockchain, para tokenizar sus
                operaciones, y no cuentan con una cuenta digital cliente, pero
                sí cuentan con un back office. O bien, cuentan con una cuenta
                digital básica y pretenden desarrollar una cuenta digital nueva
                desde cero con capacidades criptográficas.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <strong> Con una wallet cripto, una empresa puede: </strong>
            </ListItem>
            <ListItem alignItems="center">
              <Message info size="big" className={classes.grid}>
                Emitir tokens ERC20. Ejecutar transacciones del tipo "Vender
                Token", "Transferir Token". Estas capacidades son heredadas
                también por los usuarios que posean Wallets compatibles con el
                estandar ERC20 de blockchain.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <strong> Los tres tipos de Wallet: </strong>
            </ListItem>
            <ListItem alignItems="center">
              <Message info size="big" className={classes.minigrid}>
                <strong> App web responsive </strong>
                Es una app basada en desarrollo web, con todas las
                características de una app mobile. La ventaja de este tipo de
                apps basada en desarrollo web, es que no es necesario que el
                usuario descargue una app en su dispositivo movil.
              </Message>
              <Message info size="big" className={classes.minigrid2}>
                <strong> Android / Apple </strong>
                Es una wallet que es compatible para dispositivos basados en
                sistemas operativos Android y sistemas operativos IOs de Apple.
                El Desarrollo involucra equipos de programacion distintos,
                también, se requiere un deploy para cada tienda; GooglePlay para
                Android, y Apple Store para IOs. El deploy implica una serie de
                tramites de autorización para cada store.
              </Message>
              <Message info size="big" className={classes.minigrid3}>
                <strong> Android / Apple / Win </strong>
                Además de la compatibilidad de Android y Apple, se desarrolla
                una app para windows mobile, y al igual que las anteriores
                Stores, debe hacerse el deploy en Microsoft Store.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <strong> Consideraciones para tener en cuenta: </strong>
            </ListItem>
            <ListItem alignItems="center">
              <Message negative size="big" className={classes.grid}>
                Tener una app Mobile, requiere un mantenimiento evolutivo
                constante, dado que los sistemas operativos involucrados, y las
                políticas de uso de Stores de terceros para el alojamiento y
                descaga de la app, sufren cambios y actualizaciones
                periódicamente.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <strong>
                {" "}
                Límites del desarrollo propueto por este Roadmap:{" "}
              </strong>
            </ListItem>
            <ListItem alignItems="center">
              <Message negative size="big" className={classes.grid}>
                Con el Roadmap "Diseño y desarrollo de Wallet cripto",
                Firstcripto asume que la empresa requirente, cuenta con un
                back office transaccional de operaciones propia. El desarrollo se
                limita al Diseño, programación, integracion al back office
                existente y despliegue en producción de la wallet.
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message negative size="big" className={classes.grid}>
                Si no cuenta con un Back Office, deberá recorrer y formular el
                Roadmap (CRYPTO WALLET Y BACK OFFICE). Por debajo de ese nivel
                la wallet solo se limitará a las transacciones básicas como:
                [Emisión de token; Compra de token; transferencia de token;
                Restricciones de wallets; Consulta de saldos, Movimientos de
                cuenta]
              </Message>
            </ListItem>
            <ListItem alignItems="center">
              <Message warning size="big" className={classes.grid}>
                Además de las capacidades transaccionales mencionadas, la wallet
                cripto esta definida para: 1) Registro de usuario, 2)
                Autenticación y eventuelmente según sea lo que la empresa decida
                una tercer funcionalidad; 3) Identificación para asegurar la
                identidad del usuario. Ver detalle del paso "DISEÑO Y DESARROLLO
                AUTENTICACION"
              </Message>
            </ListItem>
            By — <strong>FirstCripto</strong>
          </Alert>
        </List>
      </Dialog>
    </span>
  );
}
