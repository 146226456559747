import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import { AuthContext } from '../context/auth';

import './Home.css';


import BarUp from '../components/edon.me/BarUp';
import ModalMatch2 from '../components/edon.me/Builder/Contrapartida/DemoComment';

const styles={
  grid:{
    height: "auto",
    width: "360px",
    margin: "0 auto",
  },
}

function Home() {
  const { user } = useContext(AuthContext);
  const demo = "demo";
  return (
   <>
    {user && user.suscripcion ==="publica" && (
    <Grid columns={1} style={styles.grid}>
    
      <Grid container alignItems="center" justify="center">
          {user && user.categoria !== demo && (<BarUp user = {user}/>)}
      </Grid>
      <Grid container alignItems="center" justify="center">
          {user && user.categoria === demo && (<ModalMatch2/>)}
      </Grid>
    
    </Grid> 
    )}
  </>
  );
}

export default Home;
 