import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Grid, Transition, Segment, Item} from 'semantic-ui-react';
import gql from 'graphql-tag';
import { AuthContext } from '../context/auth';
import PsicoCard from '../components/PsicoCard';
import Stiki from '../components/Train/Stiki2';
import Imagenes from '../components/uploadWithPreview';


const styles={
  grid:{
    height: "auto",
    width: "auto",
    margin: "0 auto",
 //   padding: "1em 1em 0em 0em"
  },

  grid2:{
      padding: "1em 1em 0em 0em"
  },
  
}

const FETCH_PSICO_QUERY = gql`
  {
    getPsicoPosts { 
      id
      user
      descripcion
      in_descripcion
      explicacion
      in_explicacion
      rasgos
      in_rasgos
      pasion
      in_pasion
      fijacion
      in_fijacion
      autoimagen
      in_autoimagen
      temor
      in_temor
      deseo
      in_deseo
      distorsionDeseo
      in_distorsionDeseo
      manipular
      in_manipular
      justificacion
      in_justificacion
      agredir
      in_agredir
      defensa
      in_defensa
      subtipoConservacion
      in_subtipoConservacion
      subtipoSocial
      in_subtipoSocial
      subtipoSexual
      in_subtipoSexual
      createdAt
      username
    }
  }
`;



function PsicoTrain() {
  const { user } = useContext(AuthContext);
  //const idUser = user.id;
  //const {loading ,data: { getPosts: posts }} = useQuery(FETCH_POSTS_QUERY);

  const {
    loading,
    data: { getPsicoPosts: psicoposts }
  } = useQuery(FETCH_PSICO_QUERY);

 

  return (
    
    
    
    <Grid >
       
      {user && (<Grid.Column width={11}>
                   
                 <Segment >
                   <Stiki/>
                 </Segment>
               </Grid.Column>
        )}
                
             {loading ? (<h1>Cargando...</h1>) : (
              <Grid.Column width={5}>
             {user && (   
    
                <Segment>
                    
                    <h4 className="registroUsuario">Eneatipos de Personalidad Aprendidas</h4>
                     {psicoposts && psicoposts.map((psico) => (
                     <Item key={psico.id}>
                        <PsicoCard psico={psico} />
                     </Item>
                     ))} 
                 </Segment>)}
                 <h4 className="registroUsuario">Manejo de imagenes de emergencia</h4>
                 <Imagenes/>
              </Grid.Column>)}
              
              
            
      </Grid>
     
  );
}

                    




export default PsicoTrain;