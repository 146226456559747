import React, { useState, useEffect, useRef } from "react";
import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";
import { Confirm, Button, Icon } from "semantic-ui-react";
//import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

import { CURRENT_POST2 } from "../../../util/graphql";

function PublicarButton({
  handleClose,
  _id,
  username,
  callback,
  handleNext,
  props
}) {
  const history = useHistory();
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [publicPost] = useMutation(PUBLIC_POST_MUTATION, {
    update(proxy, result) {
      setConfirmOpen(false);

      const data = proxy.readQuery({
        query: CURRENT_POST2,
        variables: {
          username
        }
      });

      data.userPostPrev = [
        result.data.publicPost,
        ...data.userPostPrev.filter(p => p._id !== _id)
      ];

      proxy.writeQuery({
        query: CURRENT_POST2,
        variables: {
          username: username
        },
        data
      });
      if (callback) callback();
    },

    variables: {
      _id
    }
  });

  function createPostCallback() {
    publicPost();
    //history.push("/home");
  }

  return (
    <>
      <Button
        onClick={() => createPostCallback()}
        primary
        floated="right"
        className="ui button teal"
      >
        Publicar
        <Icon name="right chevron" />
      </Button>

      <Confirm
        cancelButton="Cancelar"
        confirmButton="Confirmar"
        content="Esta seguro?...Has completado todo?"
        size="small"
        open={confirmOpen}
        onCancel={() => setConfirmOpen(false)}
        onConfirm={createPostCallback}
      />
    </>
  );
}

const PUBLIC_POST_MUTATION = gql`
  mutation publicPost($_id: ID!) {
    publicPost(_id: $_id) {
      _id
      estadoPost
      body
      body2
      createdAt
      username
      user
      likes {
        username
        userPost
        createdAt
      }
      likeCount
      createdAt
      portada {
        _id
        filename
        path
        mimetype
      }
      imagenes {
        _id
        filename
        path
        mimetype
      }
      especifica {
        id
        tipo
        titulo
        contenido
      }
      componentes {
        id
        tipo
        titulo
        contenido
        tiempo
        username
      }
    }
  }
`;

export default PublicarButton;
