import React , { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { AuthContext } from '../context/auth';
import PostIntencionTrain from '../components/Train/intenciones/PostIntencionTrain';
import PostCardTrain from '../components/Train/intenciones/PostCardTrain';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "2em 2em 5em 2em"
  },
  paper: {
    padding: theme.spacing(0.7),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paper2: {
    padding: theme.spacing(0.7),
    textAlign: 'center',
    
  },
}));



const INTENCION_TRAIN_QUERY = gql`
  {
    getTrainPosts {
      id
      user
      in_Intencion
      out_Accion
      in_Contrapartida
      out_Contrapartida
      createdAt
      username
    }
  }
`;

export default function IntencionTrain2() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);


  const {
    loading,
    data: { getTrainPosts: trainposts }
  } = useQuery(INTENCION_TRAIN_QUERY);


  return (
    <>
    {user && (<div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}><PostIntencionTrain/></Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} className={classes.paper2}>
            <h4 className="registroUsuario">
              Dinamicas de sugeridos entrenadas
            </h4>
          </Paper>
        </Grid>

        
        {loading ? (<h1>Cargando...</h1>) : (
        <>
            {trainposts && trainposts.map((trainpost) => ( 
             <Grid item xs={3} key={trainpost.id}>
               <Paper elevation={0} className={classes.paper}><PostCardTrain trainpost={trainpost} /></Paper>
             </Grid>))}
        </>
        )}
        
      </Grid>
    </div>)}
    </>
  );
}