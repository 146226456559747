import React,{Component} from 'react';
import './ButonMe2.css';
import General from './Respuestabot.svg';

class GeneralButon extends Component {
   render(){
     return (
        <div className="General">
        
        <img src={General} alt="Table"/>
          
        </div>
      
     );
    }


}

export default GeneralButon;