import React,{Component} from 'react';
import './InfoDerecha.css';

class InfoDerechaBIO extends Component {
   render(){
     return (
        <div className="InfoDerecha">
        <p>Quien eres?</p>
        </div>
      
     );
    }


}

export default InfoDerechaBIO;

