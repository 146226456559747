import React, { useContext, useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";

import { AuthContext } from "../context/auth";
import { useForm } from "../util/hooks";

import "../App.css";
const styles = {
  inicio: {
    margin: "-2.2em 0em 0em 1em",
    float: "left"
  }
};

function Login(props) {
  const context = useContext(AuthContext);
  const [errors, setErrors] = useState({});

  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    username: '',
    password: ''
  });

  const [loginUser, { loading }] = useMutation(LOGIN_USER, {
    update(
      _,
      {
        data: { login: userData } 
      }
    ) {
      context.login(userData);
      props.history.push('/home');
    },
   onError(err) {
  setErrors(err.graphQLErrors[0].extensions.exception.errors);
    },
    variables: values
  });

  function loginUserCallback() {
    loginUser();
  }

  return (
    <div className="form-container">
      <Form onSubmit={onSubmit} noValidate className={loading ? 'loading' : ''}>
      <h4 className="registroUsuario">Clientes</h4>
        <Form.Input
          label="Usuario"
          placeholder="Nombre de usuario..."
          name="username"
          type="text"
          value={values.username}
          error={errors.username ? true : false}
          onChange={onChange}
        />
        <Form.Input
          label="Contraseña"
          placeholder="Contraseña..."
          name="password"
          type="password"
          value={values.password}
          error={errors.password ? true : false}
          onChange={onChange}
        />
        <Button fluid color="white" type="submit" >
          Ingresar
        </Button>
      </Form>
    
    </div>
  );
}

const LOGIN_USER = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      id
      email
      username
      fechaNacim
      suscripcion
      categoria
      wallet
      createdAt
     
      token
    } 
  }
`;

export default Login;